export default function GetSkyttekortStatus(skyttekortArray){

	let status = false
	if(skyttekortArray.length < 0){
		return status
	} else {
		skyttekortArray.map(skyttekort => {
			if(skyttekort.status == 'ACTIVE'){
				status = true
			}
		})
		return status
	}
}