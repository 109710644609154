import React, { useState, useRef, useEffect } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBSwitch,
  MDBInput,
  MDBIcon
} from 'mdb-react-ui-kit';
import { useParams, NavLink, Link } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";

export default function EventEditSportgrenar({ params }) {

  const { id } = useParams();

  const [formValue, setFormValue] = useState({
    action: 'event_add_sportgren',
    key_id: id,
    db_sportgren_id: null,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [dataSub1, setSub1] = useState([]);
  const [dataSub2, setSub2] = useState([]);
  const [dataSub3, setSub3] = useState([]);
  const [dataSub4, setSub4] = useState([]);

  const [showSub1, setshowSub1] = useState("d-none");
  const [showSub2, setshowSub2] = useState("d-none");
  const [showSub3, setshowSub3] = useState("d-none");
  const [showSub4, setshowSub4] = useState("d-none");

  const [valueSub1, setvalueSub1] = useState(null);
  const [valueSub2, setvalueSub2] = useState(null);
  const [valueSub3, setvalueSub3] = useState(null);
  const [valueSub4, setvalueSub4] = useState(null);

  useEffect(() => {

    fetch_get('app/list/sportgrenar-tree')
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const setSub = async (val, level) => {
    if (val.children) {
      if (level === 1) {
        setSub1(val.children);
        setSub2([]);
        setSub3([]);
        setSub4([]);
        setshowSub1("");
        setshowSub2("d-none");
        setshowSub3("d-none");
        setshowSub4("d-none");

      } else if (level === 2) {
        setSub2(val.children);
        setSub3([]);
        setSub4([]);
        setshowSub2("");
        setshowSub3("d-none");
        setshowSub4("d-none");
      } else if (level === 3) {
        setSub3(val.children);
        setshowSub3("");
        setSub4([]);
        setshowSub4("d-none");
      } else if (level === 4) {
        setSub4(val.children);
        setshowSub4("");
      } else {

      }

    } else {

      formValue.db_sportgren_id = val.value;

      const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, formValue);
      setFormValue({ ...formValue, 'db_sportgren_id': val.value });

      if (level === 1) {
        setSub1([]);
        setshowSub1("d-none");
        setshowSub2("d-none");
        setshowSub3("d-none");
        setshowSub4("d-none");
        setvalueSub1(null);
        setvalueSub2(null);
        setvalueSub3(null);
        setvalueSub4(null);


      } else if (level === 2) {
        setSub2([]);
        setshowSub2("d-none");
        setshowSub3("d-none");
        setshowSub4("d-none");
      } else if (level === 3) {
        setSub3([]);
        setshowSub3("d-none");
        setshowSub4("d-none");
      } else if (level === 4) {
        setSub4([]);
        setshowSub4("");
      } else {

      }
    }

  }





  return (
    <>


      <MDBContainer className="py-4">

        <h1>Gren/Disciplin</h1>
        <NavLink
            className='btn btn-secondary btn-sm me-2' 
            as={Link}
            to={'/event/' + formValue.key_id}
          >
            <MDBIcon className='me-2' />Avbryt/Tillbaka
          </NavLink> 

        <MDBRow className='g-3 py-3'>
          <MDBCol className='col-md-8 col-sm-12'>
            <MDBRow className='g-3'>
              <MDBCard border='primary' className='p-2'>
                <MDBCardBody>
                  <MDBRow>
                    <h4>Välj tävlingens gren/disciplin</h4>
                  </MDBRow>
                  <MDBRow>

                    {loading ? (
                      <p>Laddar data...</p>
                    ) : (
                      <>
                        <MDBSelect
                          className='col-3'
                          label="Välj gren"
                          data={data}
                          preventFirstSelection
                          autoFocus
                          autoSelect
                          onValueChange={(e) => setSub(e, 1)}
                        />


                        <MDBSelect
                          label="Välj Disciplin"
                          className={"col-3 " + showSub1}
                          // ref={inputRef1}
                          data={dataSub1}
                          preventFirstSelection
                          value={valueSub1}
                          autoSelect
                          onValueChange={(e) => setSub(e, 2)}
                        />

                        <MDBSelect
                          label="Välj UnderDisciplin"
                          className={"col-3 " + showSub2}
                          data={dataSub2}
                          preventFirstSelection
                          value={valueSub2}
                          onValueChange={(e) => setSub(e, 3)}
                        />

                        <MDBSelect
                          label="Välj UnderDisciplin"
                          className={"col-3 " + showSub3}
                          data={dataSub3}
                          preventFirstSelection
                          value={valueSub3}
                          onValueChange={(e) => setSub(e, 4)}

                        />
                      </>
                    )}

                    <Sportgrenar f={formValue} event_id={id} />

                  </MDBRow>


                </MDBCardBody>
              </MDBCard>



            </MDBRow>

          </MDBCol>

        </MDBRow>




      </MDBContainer >

    </>
  );
}





function Sportgrenar(props) {
  const [datasportgrenar, setSportgrenar] = useState([]);
  const [formValue, setFormValue] = useState([]);
  const [dataload, setload] = useState(false);
  let formObjekt = {};


  useEffect(() => {
    fetch_get('app/event/form_data/' + props.event_id)
      .then(data => {
        setSportgrenar(data.data.sportgrenar);
        data.data.sportgrenar.map((item, i) => {

          item.klasser.map((k_item, k) => {
            if (k_item.startavgift) {
              formObjekt["startavgift" + k_item.klass_id] = k_item.startavgift;
            } else {
              formObjekt["startavgift" + k_item.klass_id] = "0";
            }


          });
          console.log('formobject', formObjekt);
          setFormValue(formObjekt);

        });
        setload(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setload(false);
      });
  }, [props.f, dataload]);


  async function deleteSportgren(id) {
    console.log('delete');
    console.log('this is:', id);
    const resJson = await fetch_post('app/event/do_submit/' + props.event_id, { action: "event_remove_sportgren", sportgren_id: id });
    console.log('resJson:', resJson);
    setload(true);
  }

  function checked(status) {

    if (status == 1) {
      return true;
    } else {
      return false;
    }
  }

  const onChange_checkbox = async (e) => {
    let status_txt = 'DISABLED';
    const klass_id = e.target.value;
    const sportgren_id = e.target.id;
    if (e.target.checked) {
      status_txt = 'ENABLED';
    }
    console.log('status', status_txt);
    const resJson = await fetch_post('app/event/do_submit/' + props.event_id, { action: "event_toogle_klass", sportgren_id: sportgren_id, klass_id: klass_id, db_status: status_txt });
    console.log('resJson:', resJson);
    if (resJson.status === 'ok' && resJson.data) {
      console.log('Klassen är satt till: ' + status_txt);
    }
    setload(true);
  };

  function handleKeyPress(e) {
    console.log('key', e.keyCode);
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  const onChange_startavgift = async (e) => {
    console.log('sss', "startavgift" + e.target.dataset.klass, e.target.value);
    let fieldName = "startavgift" + e.target.dataset.klass;
    console.log('f', fieldName);
    setFormValue({ ...formValue, [fieldName]: e.target.value });

  };

  const onBlur_startavgift = async (e) => {

    let text = e.target.id;
    const arr = text.split(" ");
    const klass_id = arr[1];
    const sportgren_id = arr[0];
    const startavgift = e.target.value;
    console.log(e);
    console.log(e.target.value, klass_id, sportgren_id);

    const resJson = await fetch_post('app/event/do_submit/' + props.event_id, { action: "event_klass_start_avgift", sportgren_id: sportgren_id, klass_id: klass_id, db_startavgift: startavgift });
    console.log('resJson:', resJson);
    if (resJson.status === 'ok' && resJson.data) {
      console.log('Startavgift är satt till: ' + startavgift);
    }
    setload(true);
  };


  return (
    <>
   

      <MDBTable>

        <tbody>
          <tr>
            <th><h4>Valda sportgrenar</h4></th>
            <th> </th>
            <th> </th>
          </tr>
          {/* {console.log('dd', datasportgrenar)} */}
          {datasportgrenar.map((item, i) => (<>
            {/* {console.log('inne',item.sportgren_id, datasportgrenar[i].klasser )} */}
            <tr key={item.sportgren_id}>

              <th key={i} colSpan={2}>{item.sportgren}</th>
              
              <td>
                <MDBBtn color='danger' size='sm' onClick={() => { deleteSportgren(item.sportgren_id) }} title="Ta bort sportgren">
                  <i className='fas fa-times'></i>
                </MDBBtn>
              </td>
            </tr>
            {item.klasser.map((k_item, k) => (

              <>
                {/* {console.log('inne2',k_item.klass_id, k_item.startavgift, datasportgrenar[i].klasser[k].startavgift)} */}
                <tr>
                  

                  <td key={k}><MDBSwitch
                    defaultChecked={checked(k_item.enabled)}
                    id={item.sportgren_id}
                    label={k_item.klass_name}
                    onChange={onChange_checkbox}
                    key={k}
                    value={k_item.klass_id}
                  />
                  </td>
                  <td className="w-25"><MDBInput
                    label='Startavgift'
                    id={item.sportgren_id + " " + k_item.klass_id}
                    type='number'
                    data-sportgren={item.sportgren_id}
                    data-klass={k_item.klass_id}
                    defaultValue={k_item.startavgift}
                    value={formValue['startavgift' + k_item.klass_id]}
                    onChange={onChange_startavgift}
                    onBlur={onBlur_startavgift}
                    onKeyDown={handleKeyPress}
                  // className='text-success'
                  />
                  </td>

                  <td></td>
                </tr>
              </>
            ))}

          </>


          ))}

        </tbody>
      </MDBTable>
    </>
  );
}
