import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBNavbar,
  MDBTooltip,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
MDBStack,
MDBToast,
} from 'mdb-react-ui-kit';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
  MRT_Table,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import {
  Box
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";
import { extExcelExport } from '../../documents/ExcelExport';
import { BorderCol } from '../../modules/BorderCol';
import { EventRubrik } from "./Event_common";

export function ModalAddDeltagare({ basicModal, setBasicModal, toggleOpen, setLoading }) {
  const { id } = useParams();
  const [lov_foreningar, setDataForeningar] = useState([]);
  const [medlemmar, setDataMedlemmar] = useState([]);
  const [checked, setChecked] = useState(false);
  const [current_forening, setForening] = useState(null);
  const [stackingToasts, setStackingToasts] = useState([]);

  const showToast = (text,color) => {
    setStackingToasts([
      ...stackingToasts,
      {
        color,
        colorCapitalized: color.charAt(0).toUpperCase() + color.slice(1),
        text,
      }
    ]);
  };

  useEffect(() => {
    fetch_get('app/list/lov-foreningar')
      .then(data => {
        setDataForeningar(data.data);
        // setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // setLoading(false);
      });
  }, []);

  const changeForening = (val, checked_in) => {
    console.log(val);

    setForening(val.value);

    fetch_get('app/forening/user-list-event/' + val.value + '/' + id)
      .then(data => {
        const medl_tmp = data.data;

        let tmp;
        if (!checked_in) {
          tmp = medl_tmp.filter(e => e.participant_ok.participant_ok);
        } else {
          tmp = medl_tmp;
        }

        const medl = tmp;

        setDataMedlemmar(medl);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  }  

  const changeChecked = () => {
    let curr_checked = !checked;
    setChecked(curr_checked);
    changeForening({ value: current_forening }, curr_checked);

  }  

  const onadddeltagare = async (row) => {

//.key_id, row.original.forening_id

    console.log('onadddeltagare', row);
    
    const form_params = {
      "action": "event_add_deltagare",
      "admin": true,
      "user_key_id": row.key_id,
      "db_forening_id": row.forening_id
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);
    showToast(row.name, 'info');

  };  

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Namn',
        size: 100,
        enableEditing: false,
        filterFn: 'equals',
        Cell: ({ cell, row }) => (
          <>
            <span className="d-inline-flex bd-highlight flex-column  flex-fill px-3">
              <span className="fw-bolder">{row.original.name} {!row.original.participant_ok.participant_ok && <>
                <MDBIcon title={row.original.participant_ok.text} color='warning' fas icon="exclamation-triangle" /></>}</span>
            </span>

          </>
        ),
      },

    ],
    [],
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: medlemmar,
      enablePagination: false,
      enableColumnActions: false,
      enableRowActions: true,
      enableStickyHeader: true,
      initialState: {
        density: 'compact',
        columnPinning: {
          right: ['mrt-row-actions'],
        },
      },
      enableColumnFilters: false,
      enableTableFooter: false,
      enableTableHead: false,
      enableBottomToolbar: false,
      localization: { noRecordsToDisplay: 'Välj en förening...', },
      muiTableContainerProps: { sx: { maxHeight: '500px' } },
      renderRowActions: ({ row }) => (
        <>
          <MDBTooltip

            wrapperProps={{
              tag: 'a',
              size: 'sm',
              onClick: () => onadddeltagare(row.original)
            }}
            title="Lägg till deltagare"
          >
            <MDBIcon fas icon='angle-double-right' />
          </MDBTooltip>
        </>
      ),
      renderTopToolbarCustomActions: () => (
        <div className="d-flex flex-column mb-3">

          <MDBSelect
            label="Välj förening"
            data={lov_foreningar}
            search
            preventFirstSelection
            clearBtn
            visibleOptions={8}
            searchLabel='Sök förening'
            onValueChange={(e) => changeForening(e, checked)}
          />
          <div className='mt-4'>
            <MDBCheckbox

              name='flexCheck'
              value=''
              id='flexCheckDefault'
              label='Visa alla medlemmar till föreningen'
              checked={checked}
              onChange={() => changeChecked()}
            />
          </div>
        </div>
      ),
      renderToolbarInternalActions: ({ table }) => (

        <>
          <MDBBtn
            tag='a'
            size='sm'
            // onClick={toggleOpen}
            className="rounded outline btn bg-transparent shadow-0"
          >
            <MDBIcon fas icon="info-circle" color='info' size='lg' />
          </MDBBtn>
        </>

      ),

  });

  return (
    <>
      <MDBModal
        open={basicModal}
        staticBackdrop
        // setopen={setBasicModal}
        tabIndex='-1'

      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBValidation>
              <MDBModalHeader>
                <MDBModalTitle>Lägga till deltagare</MDBModalTitle>
                <MDBBtn className='btn-close' type='reset' color='none' onClick={toggleOpen}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>

                <MaterialReactTable
                  table={table}
                />


              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color='secondary' type='reset' onClick={toggleOpen}>
                  Stäng
                </MDBBtn>
                {/* <MDBBtn type='submit'>Spara</MDBBtn> */}
              </MDBModalFooter>
            </MDBValidation>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>


      <MDBStack className='position-absolute'>
          {
            stackingToasts.map((item, id) => (
              <MDBToast
                color={item.color}
                defaultOpen
                autohide
                width={450}
                delay={2000}
                key={id}
                headerContent={
                  <>
                    <strong className='me-auto'>Tillagd som deltagare</strong>
                    {/* <small>-</small> */}
                  </>
                }
                bodyContent={`${item.text}`}
              />
            ))
          }
        </MDBStack>      

    </>
  );

}