import React, { useState, useRef, useMemo } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import { MenuItem,  } from '@mui/material';
import {
  MRT_Table,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBDatatable,
  MDBBadge
} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  Form,
  redirect,
  Navigate,
  useNavigate,
  //useSubmit,
} from "react-router-dom";
import Icon_Svsf from "../../svg/svg_icon_svsf";
import { fetch_get, fetch_post } from "../../../services/common";




export const loader = async ({ params }) => {
  const resJson = await fetch_get('app/list/forening-events');
  //const resJson = await res.json();

  return resJson;
};


export default function EventsHome() {
  const loadObject = useLoaderData();
  const [data, setData] = useState(loadObject);
  const navigate = useNavigate();

  const onLinkClick = (k, type) => {

    if (type === 'admin_event') {
       navigate("/event/" + k);
     } else {
      alert('Ej klar: ' + type);
     }

  };

  const columns = useMemo(

    () => [

      {
        accessorKey: 'start_date', //normal accessorKey
        header: 'Start datum',
        size: 60,
      },
      {
        accessorKey: 'name',
        header: 'Tävling',
        size: 120,
      },
      {
        accessorKey: 'status_name',
        header: 'Status',
        size: 90,
        //filterVariant: 'select',
        //filterVariant: 'multi-select',
        Cell: ({ cell, row }) => (
          <>

            <MDBBadge pill light color={row.original.status_color}>{cell.getValue()}</MDBBadge>

          </>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: data.data,
    localization: MRT_Localization_SV,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActionMenuItems: ({ row }) => [

    <MenuItem key="admin_event" onClick={(k, type) => onLinkClick(row.original.key_id, 'admin_event')}>
      Ändra
    </MenuItem>,
      <MenuItem key="copy_event" onClick={(k, type) => onLinkClick(row.original.key_id, 'copy_event')}>
        Kopiera
      </MenuItem>,
      <MenuItem key="status_event" onClick={(k, type) => onLinkClick(row.original.key_id, 'status_event')}>
        Ändra status
      </MenuItem>,

    ],    
    muiTableBodyRowProps: ({ row, cell }) => ({
      onClick: (event) => {
        console.info(event, row.id, row.original.key_id, cell);
        navigate("/event/" + row.original.key_id);
      },
      sx: {
        cursor: 'pointer', 
      },
    }),
  });

  return (
    <>
      <header>
        {/* <Sidenav basicOpen={basicOpen} setBasicOpen={setBasicOpen} /> */}
      </header>

      <MDBContainer className="py-4">

        <h1>Våra tävlingar</h1>
        <MRT_Table
          table={table}
        />
      </MDBContainer>

    </>
  );
}
