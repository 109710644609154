import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBNavbar,
  MDBTooltip,
  MDBDateTimepicker,
  MDBTimepicker,
  MDBTable,
  MDBBtnGroup,
} from 'mdb-react-ui-kit';
import {
  MRT_Table,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import { useParams, useNavigate, NavLink, Link } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const customWeekdaysNarrow = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];
const customMonthsFull = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December',];
const customWeekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
const customMonthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];


export default function EventEditStartlista({ params }) {
  // https://dev.svsf-ta.se/api/app/event/rounds/53a6c57f-377e-11ee-9385-00505692ffcc

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [antal_anmalda, setAntalAnmalda] = useState(null);
  const [cb_antal, setCbAntal] = useState(0);
  const [round_1_exists, setRound1Exists] = useState(false);
  const [loading, setLoading] = useState(true);

  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);

  const [basicModalSkjutlag, setBasicModalSkjutlag] = useState(false);
  const toggleOpenSkjutlag = () => setBasicModalSkjutlag(!basicModalSkjutlag);

  const [formValue, setFormValue] = useState({});
  const [formValueSkjuttid, setFormValueSkjuttid] = useState({});

  useEffect(() => {

    fetch_get('app/event/rounds/' + id)
      .then(data => {
        setData(data.data.rounds);
        setStartDate(data.data.start_date);
        setAntalAnmalda(data.data.antal_anmalda);
        setRound1Exists(data.data.round_1_exists);
        setCbAntal(data.data.cb_antal);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [loading]);

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const mainStyles = {
    rubriker: {
      height: 20,
      position: 'absolute',
      top: '-10px',

    },
  };


  const onSubmitEvent = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      setBasicModal(false);
      console.log('save', formValue);
      const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, formValue);
      setLoading(true);
    } else {
      console.log('form not valid');
    }

  };

  const onSubmitSkjutlag = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      setBasicModalSkjutlag(false);
      const resJson = await fetch_post('app/event/do_submit/' + formValueSkjuttid.key_id, formValueSkjuttid);
      setLoading(true);
    } else {
      console.log('form not valid');
    }

  };

  const ondeleteRound = async (e) => {

    console.log('delete', e);
    const delete_obj =
    {
      action: 'event_round_delete',
      key_id: id,
      round_id: e
    };

    const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, delete_obj);
    setLoading(true);

  };

  const onChangeStarttidDate = (date) => {

    setFormValueSkjuttid({ ...formValueSkjuttid, 'start_date': date });
    console.log('aa', formValueSkjuttid);
  };

  const onChangeStarttid_start_time = (e) => {
    setFormValueSkjuttid({ ...formValueSkjuttid, 'start_time': e });
    console.log('aa2', formValueSkjuttid);
  };

  const onChangeStarttid_time_between_relays = (e) => {
    setFormValueSkjuttid({ ...formValueSkjuttid, 'time_between_relays': e });
    console.log('aa3', formValueSkjuttid);
  };


  const oneditStartTid = async (relay_id, starttid, da, ti, omgang_id) => {

    setFormValueSkjuttid(
      {
        action: 'event_set_starttime',
        key_id: id,
        relay_id: relay_id,
        omgang_id: omgang_id,
        start_date: da || startDate,
        start_time: ti || '08:00',
        time_between_relays: '00:00',
      });

    setBasicModalSkjutlag(true);

  };

  const onnew = async () => {

    console.log('new');
    setFormValue(
      {
        action: 'event_create_round_rand',
        key_id: id,
        db_name: "Grund",
        db_omgang: 1,
        no_of_relays: Math.ceil(antal_anmalda / 6),
        db_tavlor_per_skjutlag: 6,
        db_tavel_nr_start: 1
      });

    setBasicModal(true);

  };

  const handleAntalSkyttar = async (e) => {
    e.preventDefault()
    setFormValue({ ...formValue, ['db_tavlor_per_skjutlag']: e.target.dataset.antalskyttar, ['no_of_relays']: Math.ceil(antal_anmalda / e.target.dataset.antalskyttar) });
  };

  return (
    <>

      <MDBContainer className="py-4">

        {!loading && (
          <Grund {...data} round_1_exists={round_1_exists} onnew={onnew} antal_anmalda={antal_anmalda} cb_antal={cb_antal} />
        )}

      </MDBContainer >

      <MDBModal open={basicModal} staticBackdrop setopen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBValidation onSubmit={onSubmitEvent}>
              <MDBModalHeader>
                <MDBModalTitle>Skapa/Lotta grundomgång

                </MDBModalTitle>
                <MDBBtn className='btn-close' type='reset' color='none' onClick={toggleOpen}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>

                <MDBRow>
                  <MDBValidationItem className='py-2 col-8' invalid feedback='Ange omgångens namn.'>
                    {basicModal && (
                      <MDBInput
                        name='db_name'
                        id='form_db_name'
                        label={<>Omgångsnamn</>}
                        type='text'
                        required
                        value={formValue.db_name}
                        onChange={onChange}
                      >

                        <MDBBadge pill className='position-absolute top-0 start-100  translate-middle bg-info' title="Hi! I'm a tooltip555555555!">
                          <MDBTooltip tag='a' title="Tester av tooltip!">
                            i
                          </MDBTooltip>
                        </MDBBadge>

                      </MDBInput>

                    )}
                  </MDBValidationItem>

                  <div className="d-inline-flex flex-column col-8">
                    <label className="px-3 d-inline-flex">Antal skyttar/lag</label>
                    <MDBBtnGroup className='me-2' aria-label='First Group'>
                      <MDBBtn onClick={handleAntalSkyttar} outline={formValue.db_tavlor_per_skjutlag == 4 ? false : true} data-antalskyttar="4">4</MDBBtn>
                      <MDBBtn onClick={handleAntalSkyttar} outline={formValue.db_tavlor_per_skjutlag == 5 ? false : true} data-antalskyttar="5">5</MDBBtn>
                      <MDBBtn onClick={handleAntalSkyttar} outline={formValue.db_tavlor_per_skjutlag == 6 ? false : true} data-antalskyttar="6">6</MDBBtn>
                    </MDBBtnGroup>
                  </div>
                  <div className="pt-3">Antal anmälda: {antal_anmalda}</div>
                  <div className="pt-2 fw-bold">Antal lag som kommer skapas: {formValue.no_of_relays}</div>
                </MDBRow>

              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color='secondary' type='reset' onClick={toggleOpen}>
                  Avbryt
                </MDBBtn>
                <MDBBtn type='submit'>Skapa/Lotta</MDBBtn>
              </MDBModalFooter>
            </MDBValidation>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>


    </>
  );
}


function Grund(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log('props', props.round_1_exists);

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'skjutlag', //access nested data with dot notation
        header: '#',
        size: 50,
        enableEditing: false,
      },
      {
        accessorFn: (row) => `${row.da || 'åååå-mm-dd'}`,
        header: ' ',
        size: 150,
        enableEditing: false,
      },
      {
        accessorFn: (row) => `${row.ti || 'hh:ss'}`,
        header: 'Starttider',
        size: 50,
        enableEditing: false,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: props.round_1_exists ? props[0].skjutlag : [],
    enableColumnActions: false,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: true,
    enableStickyHeader: true,
    initialState: {
      density: 'compact',
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    enableColumnFilters: false,
    localization: MRT_Localization_SV,
    enableSorting: false,
    renderCaption: ({ table }) =>
      `Antal anmälda: ${props.antal_anmalda}st  - Antal skapade skjutlag: ${table.getRowModel().rows.length}st ${props.round_1_exists ? '(' + props[0].name + ')' : ''} `,
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
          fontWeight: 'bold',
          mx: 0.5,
          fontSize: 14,
          p: 1,
        },
      },
    },
    renderTopToolbarCustomActions: () => (
      <div className="d-flex flex-row mb-3">
        <MDBBtn
          onClick={props.onnew}
          size='sm'
          className='me-2'
          disabled={props.round_1_exists || props.antal_anmalda === 0}
        >
          <MDBIcon fas icon="plus" className='me-2' />Skapa/Lotta grundomgång
        </MDBBtn>


        {/* <div className="pt-3">Antal anmälda: {props.antal_anmalda}</div> */}
        <div className="vr"></div>

        <MDBBtn
          // onClick={props.onnew}
          size='xs'
          className='me-2'
          disabled={props.cb_antal === 0}
        >
          <MDBIcon fas icon="check-double" className='me-2' />Count back - kontroll ( {props.cb_antal}st )
        </MDBBtn>
        <MDBBtn
          // onClick={props.onnew}
          size='xs'
          className='me-2'
          disabled
        >
          <MDBIcon fas icon="check-double" className='me-2' />Förbered för final
        </MDBBtn>

      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (

      <>
        <div>
          <div>
            <MDBBtn
              onClick={handleClick}
              size='sm'
              color='secondary'
            >
              Skriv ut / Export<MDBIcon fas icon="caret-down" className='ps-1' />
            </MDBBtn>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}

            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test1 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test2 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test3 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test4 (PDF)
            </MenuItem>
          </Menu>
        </div>
        <MRT_ToggleFullScreenButton table={table} />
        <MDBBtn
          tag='a'
          size='sm'
          // onClick={toggleOpen}
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color='info' size='lg' />
        </MDBBtn>
      </>

    ),
  });

  return (
    <>
      <MaterialReactTable
        table={table}
      />

    </>
  );
}