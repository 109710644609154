import React, { useState, useEffect } from 'react';
import {
  MDBSelect,

} from 'mdb-react-ui-kit';
import { fetch_get, fetch_post } from "../../../services/common";

const LovForeningar = (props) => {
  const [lovData, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Här kan du använda fetch() eller något HTTP-bibliotek som Axios för att hämta data från din REST API.
    // I det här exemplet antar jag att API:et returnerar data som en array av objekt.
    //const resJson = await fetch_get('app/event/all/53a6c57f-377e-11ee-9385-00505692ffcc');
    //const resJson = await res.json();
    //console.log('loader eventNew');
    //return resJson;

    fetch_get('app/list/lov-foreningar')
        .then(data => {
        //data.data.unshift({ "value": "", "text": "" });
        setData(data.data);
        console.log('data.data', data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const sendData = (val) => {
    props.parentCallback(val);
  }

  return (
    <div>

      {loading ? (
        <p>Laddar data...</p>
      ) : (
        <>
          <MDBSelect
            label="Lägg till arrangör..."
            data={lovData}
            search
            preventFirstSelection
            onValueChange={(e) => sendData(e)}
          //size='sm'
          //placeholder='Lägg till arrangör...'
          />



        </>
      )}

    </div>
  );
};

export default LovForeningar;
