import { fetch_post } from "../../../../services/common";

export async function EditSportgren(id, form){
	
	const response = await fetch_post("app/sportgren/do_submit/" + id, {
    action: "edit_sportgren",
    db_name: form.name,
		db_default_fee: form.default_fee
	})

	if (response.message !== "OK") {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	} 
		
	return  response;
}