import React, { useState, useEffect, Fragment } from "react";
import { useMDBCookies } from 'mdb-react-cookies-management';
import { fetch_get, fetch_post } from "../../services/common";
import packageJson from '../../../package.json';
import {
  MDBFooter,
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,

} from "mdb-react-ui-kit";

import {
  Outlet,
  NavLink,
  Link,
} from "react-router-dom";

import Icon_Svsf from "../svg/svg_icon_svsf";




export default function Footer() {
  const [navcolor, setNavColor] = useState("primary");
    

  return (

    <MDBFooter bgColor={navcolor} className='text-center text-lg-start text-light'>


      <section className=''>
        <MDBContainer className='text-center text-md-start p-1'>
          <MDBRow className='mt-3'>
            <MDBCol md='1' lg='2' xl='2' className='mx-auto mb-1'>

              <Icon_Svsf className="" />
              <p data-mdb-toggle="tooltip" className="fw-light" title={`Build Date: ${packageJson.build_date}`}> <br />Ver: {packageJson.version}</p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-2'>
              <h6 className='text-uppercase fw-bold mb-1'>Besöksadress</h6>
              <p>

                Skansbrogatan 7<br />118 60 Stockholm
              </p>
              <p>
                Org-nr: 802003-1707
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-2'>
              <h6 className='text-uppercase fw-bold mb-1'>Postadress</h6>
              <p>
                Svenska Skyttesportförbundet<br />
                Box 11016<br />100 61 Stockholm
              </p>

            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-2'>
              <h6 className='text-uppercase fw-bold mb-1'>Kontakt</h6>
              <p>
                Tel: 08 699 63 70<br />
                E-post: <a href="mailto:skytteta@skyttesport.se" style={{ color: '#ffffff' }}>skytteta@skyttesport.se</a><br />
              </p>
              <p>
                Länk: <a href="https://www.skyttesport.se" target="_blank" style={{ color: '#ffffff' }}>www.skyttesport.se</a><br />
                Länk: <a href="https://login.idrottonline.se/" target="_blank" style={{ color: '#ffffff' }}>IdrottOnline</a><br />
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>


    </MDBFooter>

  );
}
