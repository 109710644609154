import React,{ useMemo, useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import {    
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,
	MDBIcon,
	MDBListGroupItem,
	MDBBtn,
	MDBListGroup
} from 'mdb-react-ui-kit';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
	MRT_ToolbarAlertBanner
} from 'material-react-table';
import {
	keepPreviousData,
	useQuery,
} from '@tanstack/react-query';
import {
	Box
} from '@mui/material';
import KansliRolesModal from './KansliRolesModal';
import { fetch_get } from "../../../../services/common";

export default function KansliRoles() {

	const [columnFilters, setColumnFilters] = useState([]);
	const [selectedRows, setSelectedRows] = useState([])
	const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('');
	const [loading, setLoading] = useState(false)
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
	const [selectedUser, setSelectedUser] = useState(null);
	const [roleList,setRoleList] = useState(null);
	const [roleModal,setRoleModal] = useState(false);
	const [userRoles, setUserRoles] = useState(null)

	useEffect(() => {
		setLoading(true)
		fetch_get("app/list/lov-roles")
		.then((data) =>
			setTimeout(() => {
				setRoleList(data.data);
				setLoading(false)
			}, 3000)
		);
		
  }, []);

	const toggleRoleModal = (user) => {		
		setSelectedUser(user)
		setRoleModal(!roleModal)
	};

	
	useEffect(() => {
		setLoading(true)
		fetch_get("/api/data/all_user_roles/?_filter=id != 100")
		.then((data) =>
			setTimeout(() => {
				setUserRoles(data.data)
				setLoading(false)
			}, 3000)
		);
	}, [roleModal]);

	function listRoles(user_id){
		if(userRoles){
			return <MDBListGroup style={{ minWidth: '22rem' }} light small>
							{userRoles.map((role,i) => {
								if(role.user_id === user_id){
									if(role.forening_name){
										return <MDBListGroupItem key={user_id + i}>{role.description} - {role.forening_name}</MDBListGroupItem>	
									}
									if(role.distrikt_name){
										return <MDBListGroupItem key={user_id + i}>{role.description} - {role.distrikt_name}</MDBListGroupItem>	
									}
									if(role.gren_name){
										return <MDBListGroupItem key={user_id + i}>{role.description} - {role.gren_name}</MDBListGroupItem>	
									}
									return <MDBListGroupItem key={user_id + i}>{role.description}</MDBListGroupItem>
								}
							})}
						</MDBListGroup>
		}
		
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'first_name',
        header: 'Förnamn',
        size: 150,
      },
			{
        accessorKey: 'last_name',
        header: 'Efternamn',
        size: 150,
      },
			{
        accessorKey: 'birth_date',
        header: 'Födelsedatum',
        size: 50,
      },
			{
        accessorKey: 'iid_nr',
        header: 'IID-nr',
        size: 50,
      },
			{
        accessorKey: 'forening',
        header: 'Förening',
        size: 50,
      },
			{
        accessorKey: 'distrikt',
        header: 'Distrikt',
        size: 50,
      },
			{
				accessorFn: (originalRow) => (originalRow.roles !== null ? 'true' : 'false'),
				id: 'roles',
				header: 'Roller',
				size: 50,
				filterVariant: 'multi-select',
				filterSelectOptions: roleList,
				Cell: ({ cell,row }) => (
					<>
						{cell.getValue() === 'true' ? 
							<MDBPopover
								dismiss
								tag='a'
								style={{ cursor: 'pointer' }}
								placement='left'
								btnChildren={<MDBIcon fas icon="user-tie" />}
							>
								<MDBPopoverHeader>Roller</MDBPopoverHeader>
								<MDBPopoverBody>
									{listRoles(row.original.user_id)}
								</MDBPopoverBody>
							</MDBPopover> : null}
							<MDBBtn data-bs-toggle="tooltip" onClick={() => toggleRoleModal(row.original)} data-bs-placement="top" title="Hantera roller" tag='a' color='none' style={{ color: '#000' }} className='mx-2 my-0'>
								<MDBIcon className='py-0' fas icon='cog'/>
							</MDBBtn>
					</>
				)
			},
    ],
    [roleList,userRoles],
  );

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
			userRoles
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        '/api/data/users_roles/',
        window.location.protocol + "//" + window.location.hostname,
      );

      fetchURL.searchParams.set(
        '_skip',
        `${pagination.pageIndex * pagination.pageSize}`,
      );

			function filterFunction(){
				let filterString = ""
				if(globalFilter && globalFilter.length > 0 ){
					filterString = filterString.concat("(first_name LIKE '%" + 
						globalFilter + "%' OR last_name LIKE '%" + 
						globalFilter + "%' OR user_fullname LIKE '%" + 
						globalFilter + "%' OR birth_date LIKE '%" + 
						globalFilter + "%' OR forening LIKE '%" + 
						globalFilter + "%' OR distrikt LIKE '%" + 
						globalFilter + "%' OR iid_nr LIKE '%" +
						globalFilter + "%')")
					if(columnFilters.length > 0){
						filterString = filterString.concat(" AND ")
					}
				}
				if(columnFilters.length > 0){
					filterString = filterString.concat("(")
					columnFilters.map((col, index) => {
						let searchParam = " LIKE '%" + col.value + "%'"
						if(col.id === "roles"){
							let roleFilter = ""
							col.value.map((desc,index) => {
								if(index == 0){
									roleFilter = "(roles LIKE " + "'%" + desc + "%'"
								} else {
									roleFilter = roleFilter.concat(" OR roles LIKE '%" + desc + "%'")
								}
							})
							roleFilter = roleFilter.concat(")")
							filterString = filterString.concat(roleFilter)
						} else {
							if(index == 0){
								filterString = filterString.concat(col.id + searchParam)
							} else {
								filterString = filterString.concat(" AND " + col.id + searchParam)
							}
						}
					})
					filterString = filterString.concat(")")
				}
				return filterString
			}
			
      fetchURL.searchParams.set('_top', `${pagination.pageSize}`);
			if(globalFilter || columnFilters.length > 0){
				fetchURL.searchParams.set('_filter', filterFunction());
			}
			if(sorting.length > 0){
				fetchURL.searchParams.set('_orderby', sorting[0].id + " " + (sorting[0].desc ? "desc" : ""));
			}

      const response = await fetch(fetchURL.href);
      const json = await response.json();
      return json;
    },
    placeholderData: keepPreviousData,
  });

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    initialState: { 
			showColumnFilters: false, 
			showGlobalFilter: true,
		},
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
    muiSelectCheckboxProps: { color: 'secondary' },
		enableColumnActions: false,
		enableFullScreenToggle: false,
		getRowId: (originalRow) => originalRow.user_key_id,
		localization:MRT_Localization_SV,
    rowCount: meta?.rowcount_total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching && loading,
      sorting,
			rowSelection
    },
		renderTopToolbar: ({ table }) => {
			return (
        <Box
          sx={{
						padding: '16px',
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
						<MRT_ToolbarAlertBanner table={table} />
          </Box>
					{/* <Box className="me-4">
						<MDBBtn className='m-2' disabled onClick={() => toggleSkyttekortModal()}>Hantera roller</MDBBtn>
					</Box> */}
        </Box>

      );
		},
  });

	useEffect(() => {
		setSelectedRows(table.getSelectedRowModel().rows)
	}, [rowSelection]);

	return (
		<>
			<MDBContainer className="py-4">
				<h3>Roller</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="12" className="mb-3">
						<MaterialReactTable table={table} />
					</MDBCol>
					{(selectedUser !== null && roleModal) ? <KansliRolesModal sentUser={selectedUser} show={roleModal} close={() => setRoleModal(false)}/> : null}
					</MDBRow>
			</MDBContainer>
		</>
          );
}