import React from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBTable,
  MDBAccordionItem,
  MDBAccordion,
  MDBListGroupItem,
  MDBListGroup
} from "mdb-react-ui-kit";
import { useLoaderData, Link } from "react-router-dom";
import { fetch_json } from "../../../services/common";

export const loader = async ({ params }) => {
  const res = await fetch_json('data/rank_sportgrenar');

  return res.data;
};

const RankingPage = () => {
  const data = useLoaderData();
  // const data = loadObject.data;

  console.log(data);

  return (

    <MDBContainer className="py-4">
      <MDBRow>
        <h1>Ranking</h1>


      </MDBRow>


      <MDBRow>

        <MDBAccordion>


          {data.map(item => (<>

            <MDBAccordionItem
              collapseId={item.id}
              headerTitle={item.name_sequence}
              key={item.id}

            >
              <MDBListGroup style={{ minWidthL: '22rem' }} light small>
                {item.lists.map(item_list => (
                  <>

                    <MDBListGroupItem>
                      <Link as={Link} to={"/ranking-list/" + item_list.id}>{item_list.description}</Link>
                    </MDBListGroupItem>

                  </>


                ))}
              </MDBListGroup>
            </MDBAccordionItem>
          </>


          ))}



        </MDBAccordion>




        {/* {!data ? (
              <p>Laddar data...</p>
            ) : (
              <>
                {data.map((item, i) => {
        return (

          <MDBAccordionItem
            collapseId={item.key_id}
            headerTitle={item.name_sequence}
            key={i}

          >
            <h2>fadsfafas</h2>
          </MDBAccordionItem>




        )
      })}
              </>
            )} */}



      </MDBRow>







    </MDBContainer>
  )
};

export default RankingPage;
