import React, { useEffect,useState } from 'react';
import {    
  MDBSelect,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBListGroup,
	MDBListGroupItem,
	MDBSpinner
 } from 'mdb-react-ui-kit';
 import { AddRole } from '../../users/utils/AddRole';
 import { RemoveRole } from '../../users/utils/RemoveRole';
 import { fetch_get } from '../../../../services/common';

export default function DistrictRolesModal(params){

	const user = params.sentUser[0];
	const district = params.district;
	const [userRoles, setUserRoles] = useState(null)
	const [roleAddedToast, setRoleAddedToast] = useState(false)
	const [roleErrorToast, setRoleErrorToast] = useState(false)
	const [roleRemovedToast, setRoleRemovedToast] = useState(false)
	const [roleRemovedErrorToast, setRoleRemovedErrorToast] = useState(false)
	const [roleVal,setRoleVal] = useState({ text: 'Distriktsadministratör', value: 400 })
	const [roleChanged, setRoleChanged] = useState(false)
	const [loading,setLoading] = useState(false)
	const disableAddButton = userRoles ? (userRoles.find(({ id, distrikt_id }) => id === "400" && distrikt_id === district.id) ? true : false) : true

	const addRole = () => {
		
		AddRole(user.user_key_id,roleVal.value,district.id).then(obj => {
			if(obj.status == "OK"){
				setRoleChanged(!roleChanged)
				setRoleAddedToast((prev) => !prev);
			} else {
				setRoleErrorToast((prev) => !prev)
			}
		});
		
	}

	const removeRole = () => {
		
		RemoveRole(user.user_key_id,roleVal.value,district.id).then(obj => {
			if(obj.status == "OK"){
				setRoleRemovedToast((prev) => !prev);
				setRoleChanged(!roleChanged)
			} else {
				setRoleRemovedErrorToast((prev) => !prev)
			}
		});
		
	}

	useEffect(() => {
		setLoading(true)
		fetch_get("/api/app/user/roller/" + user.user_key_id)
		.then((data) =>
			setTimeout(() => {
				if(data.data.length > 0){
					setUserRoles(data.data)
				} else {
					setUserRoles(null)
				}
				setLoading(false)
			}, 3000)
		);
	}, [roleChanged]);

	function listRoles(){
		return <MDBListGroup style={{ minWidth: '22rem' }} light small>
						{userRoles.map((role,i) => {
							if(role.id !== "100"){
								return <MDBListGroupItem className='d-flex justify-content-between align-items-center' key={user.user_key_id + i}>
													{role.description}{role.id === "200" ? "" : " - "}
													{role.gren_name ? role.gren_name : null}
													{role.forening_name ? role.forening_name : null}
													{role.distrikt_name ? role.distrikt_name : null} 
													{role.id == 400 ? 
													<MDBPopconfirm modal btnChildren='Ta bort roll' onConfirm={() => removeRole()}>
														<MDBPopconfirmMessage>Ta bort roll <b>{roleVal.text}</b> från användare  <b>{user.first_name} {user.last_name}</b></MDBPopconfirmMessage>
													</MDBPopconfirm> : null}
													
												</MDBListGroupItem>
							}
								
						})}
					</MDBListGroup>
	}

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleAddedToast}
						onClose={() => setRoleAddedToast(false)}
					>
						Roll har lagts till för {user.first_name} {user.last_name}.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleErrorToast}
						onClose={() => setRoleErrorToast(false)}
					>
						Roll kunde inte läggas till.
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleRemovedToast}
						onClose={() => setRoleRemovedToast(false)}
					>
						Roll har tagits bort för {user.first_name} {user.last_name}.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleRemovedErrorToast}
						onClose={() => setRoleRemovedErrorToast(false)}
					>
						Roll kunde inte tas bort.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>Hantera roller - {user ? user.first_name + " " + user.last_name : null}</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
								<MDBModalBody>
									{!loading ?
									<>
										<MDBRow between>
											<MDBCol md={4}>
												<MDBSelect
													label='Gren/diciplin'
													data={[
														{ text: 'Distriktsadministratör', value: 400 },
													]}
													name="role"			
													onChange={(val) => setRoleVal(val)}
												/>
											</MDBCol>
											<MDBCol md={3} className='d-flex justify-content-end'>
												<MDBPopconfirm modal disabled={disableAddButton} btnChildren='Lägg till roll' onConfirm={() => addRole()}>
													<MDBPopconfirmMessage>Tilldela roll <b>{roleVal.text}</b> till användare  <b>{user.first_name} {user.last_name}</b></MDBPopconfirmMessage>
												</MDBPopconfirm>
											</MDBCol>
										</MDBRow>
										{userRoles && userRoles.length > 0 ? 
											<>
												<br/>
												<hr/>
												<br/>
												<h5>Nuvarande roller</h5>
												{listRoles()}
											</> : null}
										</>
									: 
									<MDBRow className="d-flex justify-content-center py-5">
									<MDBSpinner color='primary'>
										<span className='visually-hidden'>Loading...</span>
									</MDBSpinner>
								</MDBRow>}
							</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}