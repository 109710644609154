import React, { useState, useRef, useEffect } from 'react';
import {
    Outlet,
    NavLink,
    Link,
    useNavigate,
    useParams
} from "react-router-dom";
import {
    MDBStepper,
    MDBStepperStep,
    MDBValidationItem,
    MDBInput,
    MDBStepperForm,
    MDBBtn,
    MDBContainer,
    MDBBtnGroup,
    MDBIcon,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow
} from 'mdb-react-ui-kit';
import EventEditLag from "./EventLagConfig";
import EventEditBetalning from "./EventEditBetalning";
import EventNew from "./EventNew";


export default function EventEditStepper2() {
    const formRef = useRef();
    const prevRef = useRef(null);
    const { id } = useParams();
    console.log('pp', id);
    const [basicActive, setBasicActive] = useState(1);

    const onChangeStep = (e) => {
        
        console.log(basicActive, e, prevRef);
        if (basicActive==1) {
            console.log(basicActive, e, formRef.current.checkValidity());
            setBasicActive(1);
            // submitRef.current.click();
            //formRef.current.requestSubmit();
        } else {
            setBasicActive(e);
        }
        
      };

    return (
        <>
            <MDBContainer className="p-2">
                <MDBRow>
                <MDBStepper
                    onChange={(e) => onChangeStep(e)}
                    activeStep={basicActive}
                >
                    <MDBStepperStep headIcon={1} headText='Start' itemId={1} externalPrev={prevRef}>
                    {basicActive == 1 && (
                        <>
                            <button onClick={() => submitRef.current.click()}>Submit</button>
                            <EventNew x={basicActive} formRef={formRef}/>
                        </>
                        
                        )}
                    </MDBStepperStep>

                    <MDBStepperStep headIcon={2} headText='Gren / Diciplin / Klasser' itemId={2}>

                    </MDBStepperStep>

                    <MDBStepperStep headIcon={3} headText='Betalning' itemId={3}>
                        {basicActive == 3 && (
                            <EventEditBetalning x={basicActive} />
                        )}
                    </MDBStepperStep>

                    <MDBStepperStep headIcon={4} headText='Dokument' itemId={4}>

                    </MDBStepperStep>

                    <MDBStepperStep headIcon={5} headText='Lagtävling' itemId={5}>
                        {basicActive == 5 && (
                            <EventEditLag x={basicActive} />
                        )}
                    </MDBStepperStep>

                    <MDBStepperStep headIcon={6} headText='Extra' itemId={6}>


                    </MDBStepperStep>
                </MDBStepper>
                </MDBRow>
            </MDBContainer>
        </>
    );
}