import React, { useState, useRef } from 'react';
import {
  Outlet
} from "react-router-dom";
import Sidenav from "../../../modules/Sidenav_EventSettings";

export const loader = async ({ params }) => {
  //const res = await fetch('https://dev.svsf-ta.se/api/app/event/all/53a6c57f-377e-11ee-9385-00505692ffcc');
  const resJson = [];

  return resJson;
};


export default function EventSettings() {

  const [basicOpen, setBasicOpen] = useState(true);
  
  return (
    <>
      <header>
        <Sidenav basicOpen={basicOpen} setBasicOpen={setBasicOpen} />
      </header>
      <Outlet />
    </>
  );
}
