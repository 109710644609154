import { fetch_post } from "../../../../services/common";

export async function RemoveRole(key_id, role_id, obj_id){

	const response = await fetch_post("app/user/do_submit/" + key_id, {
			action: "remove_admin",
			role_id:role_id,
			object_id:obj_id,
		});
	
		if (response.message !== "OK") {
			//throw new Error(
			 // `This is an HTTP error: The status is ${response.status}`
			//);
		} 
		return  response;

}