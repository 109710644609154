import React,{ useRef, useState, useMemo, useEffect } from 'react';
import {
  MDBContainer,
	MDBBtn,
	MDBInput
} from 'mdb-react-ui-kit';
import * as xlsx from 'xlsx';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Divider, MenuItem } from '@mui/material';

const colNames = {
	id: 'ID',
	user_id: 'User ID',
	date: 'Datum'
}

export default function ImportData() {

	const inputRef = useRef(null);
	const [fileName, setFileName] = useState(null)
	const [fileData, setFileData] = useState(null)
	const [tableColumns, setTableColumns] = useState([])
	const [tableData, setTableData] = useState([])
	const [rowSelection, setRowSelection] = useState({})
	const [addColumnName, setAddColumnName] = useState(null)

	const readUploadFile = (e) => {
		e.preventDefault();
		if (e.target.files) {
				setFileName(e.target.files[0].name)
				const reader = new FileReader();
				reader.onload = (e) => {
						const data = e.target.result;
						const workbook = xlsx.read(data, { type: "array" });
						const sheetName = workbook.SheetNames[0];
						const worksheet = workbook.Sheets[sheetName];
						const json = xlsx.utils.sheet_to_json(worksheet, {
							raw: false,
							header: 0,
							dateNF: "yyyy-dd-mm"
						});
						setFileData(json)
				};
				reader.readAsArrayBuffer(e.target.files[0]);
		}
	}

	function getColumnName(column){
		if(colNames[column]){
			return colNames[column]
		}
		if(column.startsWith("__EMPTY")){
			if(!isNaN(column.charAt(column.length - 1))){
				return "Kolumn " + column.slice(8);
			}
			else{
				return "Kolumn";
			}
		} else {
			return column;
		}

		
	}

	useEffect(() => {
		if(fileData){
			setTableColumns(Object.entries(fileData[0]).map(col => ({
				header: getColumnName(col[0])/* colNames[col[0]] ?? col[0] */,
				accessorKey: col[0],
				id: col[0]
			})))
			setTableData(fileData)
		}
	}, [fileData]);

	const showUpload = () => {
    inputRef.current?.click();
  };

	const addColumn = () => {
		setTableColumns([...tableColumns, {
			header: addColumnName,
			accessorKey: addColumnName,
			id: addColumnName
		}])
	}

	const deleteColumn = (column) => {
		setTableColumns(tableColumns.filter(col => 
			col.id !== column.id
		))
	}

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: tableData,
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		state: { rowSelection },
		renderColumnActionsMenuItems: ({ internalColumnMenuItems, closeMenu,column }) => {
			return [
				...internalColumnMenuItems,
				<Divider key="divider_1"/>,
				<MenuItem onClick={() => {deleteColumn(column);closeMenu();}} key="delete-column">Radera kolumn</MenuItem>,
			];
		},
		localization:MRT_Localization_SV
  });

	useEffect(() => {
		console.log(rowSelection)
	}, [rowSelection]);

  return (
    <>

      <MDBContainer className="py-4 bg-light bg-gradient rounded-5">   
				<input ref={inputRef} onChange={readUploadFile} className="d-none" type="file" />
				<MDBBtn onClick={showUpload}>Ladda upp lista</MDBBtn>
				<p>{fileName}</p>
				<MDBInput onChange={(e) => setAddColumnName(e.target.value)}></MDBInput>
				<MDBBtn onClick={addColumn}>Lägg till kolumn</MDBBtn>
				<MaterialReactTable 
					table={table}
					/>
      </MDBContainer>

    </>
  );
}
