import React, { useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
	MDBModalFooter
} from 'mdb-react-ui-kit';

export default function PaymentTermsModal(params) {

  return (
    <MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
			<MDBModalDialog size="lg">
				<MDBModalContent>
					<MDBModalHeader>
						<MDBModalTitle>Allmänna köpvillkor</MDBModalTitle>
						<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
						</MDBModalHeader>
							<MDBModalBody>
								<h5>Personuppgifter</h5>
								<p>Personuppgifter hämtas från IdrottOnline till SkytteTA för att kunna 
									fullfölja leveransen av ordern. Personuppgifterna delas med Swedbank Pay 
									för att kunna utföra betalningen.</p>
								<h5>Integritetspolicy</h5>
								<p>Uppgifterna hanteras i enlighet med Riksidrottsförbundets avtal enligt GDPR förordningen.</p>
								<h5>Företagsinformation</h5>
								<p>Svenska Skyttesportförbundet Orgnr. 802003-1707 har möjligheten att använda betaltjänsten i SkytteTA.</p>
								<h5>Betalning</h5>
								<p>Betalning erbjuds direkt online med kortbetalning eller med någon annan betalmetod som tillhandahålls av Swedbank Pay. Ingen kortavgift debiteras.</p>
									<p>Efter att ni i kassan valt att betala ert köp förflyttas ni vidare till ett betalfönster som guidar er genom köpet. Vi accepterar köp med Visa och Mastercard samt andra betalmetoder som swish och direktbetalning.</p>
									<p>För att du som konsument ska känna dig trygg när du handlar på skytteta.se samarbetar vi med Swedbank Pay för inlösen av konto-/kreditkortsbetalningar samt övriga betalningar.</p>
								<h5>Varor och tjänster</h5>
								<p>Varor och tjänster köps via SkytteTA.</p>
									<p>Exempel på varor och tjänster är tävlingslicenser, anmälningsavgifter och eventuella tillval kopplade till dessa.</p>
								<h5>Leveransrutiner</h5>
								<p>Observera att normalt sett skickas inga fysiska varor till medlemmen, vid undantag beskrivs detta för respektive köp.</p>
								<h5>Rutiner vid återbetalning, returer och avbeställning</h5>
								<p>För återbetalningsmöjligheter av anmälningsavgift hänvisas i första hand till 
									tävlingsregler samt vad som föreskrivs i tävlingsinbjudan för aktuell tävling. 
									Vid felaktig beställning av anmälningsavgift hänvisas till tävlingsarrangören. 
									Kontaktuppgifter syns i kalendern för respektive tävling. 
									Om tävlingslicensen inte är använd går det att ångra sitt köp inom 14 dagar. 
									För att ångra köpet kontaktas office@skyttesport.se</p>
								<h5>Åldersgräns</h5>
								<p>Åldersgränsen för att kunna genomföra köp är 15 år. För ungdomar måste eventuella köp göras av målsman.</p>
								<h5>Valuta</h5>
								<p>Alla köp som gör i systemet görs i svensk valuta, SEK.</p>
								<h5>VAT</h5>
								<p>Moms är inkluderat i presenterad avgift.</p>								
							</MDBModalBody>
						<MDBModalFooter>
						<MDBBtn color='secondary' onClick={params.close}>
							Stäng
						</MDBBtn>
					</MDBModalFooter>
				</MDBModalContent>
			</MDBModalDialog>
		</MDBModal>
  );
}