import React, { useState,useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import { fetch_json } from "../../../../services/common";
import { 
  MDBContainer,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
	MDBSelect} from "mdb-react-ui-kit";
import AssociationUsers from './AssociationUsers';
import AssociationLicenses from './AssociationLicenses';
import { extExcelExport } from '../../../documents/ExcelExport';
import { fetch_get } from '../../../../services/common';

export async function loader({ params }) {
    const obj = {
      association: await fetch_json("app/forening/all/" + params.id, 'GET'),
      //users: await fetch_json("app/forening/user-list-all/" + params.id, 'GET'),
			admins: await fetch_json("app/forening/admin-list/" + params.id, 'GET')
    };
		
    return  obj;
  }

export default function AssociationDetails() {

  const loadObject = useLoaderData();
  //const users = loadObject.users.data;
	const admins = loadObject.admins.data;
	const [asyncData, setAsyncData] = useState(null);
	const [loading, setLoading] = useState(true);

  const association = loadObject.association.data;
	const [selectedAssociaion, setSelectedAssociation] = useState(loadObject.data.foreningar[0].forening_id);

  const [basicActive, setBasicActive] = useState('usersTab');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

	useEffect(() => {
	
		setLoading(true)
		fetch_get("app/forening/user-list-all/" + selectedAssociaion.id)
		.then((data) =>
			setTimeout(() => {
				setAsyncData({data},
				setLoading(false));
			}, 3000)
		);

}, [selectedAssociaion]);

  return (
    <>
    <MDBContainer className="py-4">{/* 
			<MDBBtn floating onClick={() => extExcelExport(users)}><MDBIcon fas icon='table'/></MDBBtn> */}
			<h4>{association.name}</h4>
      <MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('usersTab')} active={basicActive === 'usersTab'}>
            Medlemmar
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('licenseTab')} active={basicActive === 'licenseTab'}>
            Licenshantering
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('events')} active={basicActive === 'events'}>
            Tävlingar
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={basicActive === 'usersTab'}><AssociationUsers admins={admins} association={association} asyncData={asyncData} loading={loading}/></MDBTabsPane>
        <MDBTabsPane open={basicActive === 'licenseTab'}><AssociationLicenses asyncData={asyncData} loading={loading}/></MDBTabsPane>
        <MDBTabsPane open={basicActive === 'events'}>Tävlingar - under arbete</MDBTabsPane>
      </MDBTabsContent> 
    </MDBContainer>
    </>
  );
}