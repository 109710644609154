import { useLoaderData, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
	MDBBtn,
	MDBCard,
	MDBCardBody,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCardTitle,
	MDBCardText,
	MDBCardSubTitle,
	MDBCardHeader,
	MDBCardLink,
	MDBListGroup,
	MDBListGroupItem,
	MDBCollapse
} from "mdb-react-ui-kit";

import { fetch_get } from "../../services/common";
import jwt_decode from "jwt-decode";
import packageJson from '../../../package.json';
import parse from 'html-react-parser';
import Icon_Svsf from "../svg/svg_icon_svsf";

export const loader = async ({ params }) => {
	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	const obj = {
		user: await fetch_get("app/user/all/" + decoded.kid),
		changelog: await fetch_get("data/changelog")
	}
	return obj;
};

export default function HomePage() {

	const loadObject = useLoaderData();
	const changelog = loadObject.changelog.data;
	const [showChangelog, setShowChangelog] = useState(false)
	const toggleChangelog = () => setShowChangelog(!showChangelog)
	let loopCount = 0;

	return (
		<>

			<MDBContainer >
				<MDBRow className="pt-4" style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
					<h1>Välkommen till SkytteTa</h1>
					<p>Tävlingsadministrationsportalen för Svenska Skyttesportförbundet</p>
					<Icon_Svsf style={{ width: '150px', height: '150px', margin: '20px 0' }} />
					<p>Här kan du hantera och administrera tävlingar på ett enkelt och smidigt sätt.</p>
					{/* <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: '20px' }}>
            <div>
              <h4>Pågående tävlingar</h4>
            </div>
            <div>
              <h4>Kommande tävlingar</h4>
            </div>
            <div>
              <h4>Köpa licens</h4>
            </div>
          </div> */}
				</MDBRow>
				<MDBRow className="pt-4">
					<MDBCard className="col-md-6">
					<MDBCardHeader>Logga in för första gången</MDBCardHeader>
						<MDBCardBody>
							{/* <MDBCardTitle>Loggar in för första gången</MDBCardTitle> */}
							{/* <MDBCardSubTitle>Card subtitle</MDBCardSubTitle> */}
							<MDBCardText>
								Innan man loggar in första gången behövs en återställning av lösenordet. <br />
								Detta görs genom att ange personnummer, IdrottID (IdrottOnline ID) eller E-post.
							</MDBCardText>
							{/* <MDBCardLink as={Link} to="/forgot-password">Card link</MDBCardLink> */}
							<Link className="link" as={Link} to="/forgot-password">Återställ lösenord</Link>

							<footer className='blockquote-footer pt-4'>
            OBS! Användare måste vara upplagd i IdrottOnline, samt föreningen aktiverad mot SkytteTa
          </footer>



						</MDBCardBody>
					</MDBCard>
				
				</MDBRow>
			</MDBContainer>
			{/* <div
				className="bg-image"
				style={{
					backgroundImage: "url('https://mdbcdn.b-cdn.net/img/Photos/Others/images/76.webp')",
					minHeight: "100vh"
				}}
			>
			<MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">
				<MDBRow>
					<MDBCol md='8'>
						<MDBCard>
							<MDBCardBody>
								<MDBCardTitle>Nyheter</MDBCardTitle>
								<MDBCardText>
									Nu kan vi börja testa!
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
					<MDBCol md='4'>
						<MDBCard>
							<MDBCardBody>
								<MDBCardTitle>Changelog</MDBCardTitle>
								<MDBListGroup style={{ minWidth: '22rem' }} light className='mb-3'>
									<MDBListGroupItem>
										<p className='fw-normal'>Aktuell version: <span className='fw-bold'>v{packageJson.version}</span> <span className='fw-light'>(build: {packageJson.build_date})</span></p>
									</MDBListGroupItem>
									{changelog ? 
										<>
											{changelog.length > 1 ?
													<MDBListGroupItem>													
														{changelog.map((log) => {
															if(log.version === packageJson.version){
																return <>
																				<h5 className='fw-bold'>Version {log.version}</h5>
																				<p className='text-muted mb-2 fw-bold'>{log.date}</p>
																				{log.description ? parse(log.description) : null}
																			</>
															}										
														})}
													</MDBListGroupItem> : null}
											{changelog.length > 1 ?
												<MDBCollapse open={showChangelog}>													
													{changelog.map((log) => {
														if(log.version < packageJson.version && loopCount >= 0 && loopCount < 3){
															loopCount = loopCount + 1;
															return <>
																			<hr></hr>
																			<h5 className='fw-bold'>Version {log.version}</h5>
																			<p className='text-muted mb-2 fw-bold'>{log.date}</p>
																			{log.description ? parse(log.description) : null}
																		</>
														}										
													})}
												</MDBCollapse> : null}
										</>
									: null}
									
								</MDBListGroup>
								<MDBBtn onClick={toggleChangelog} outline rounded className='w-100'>
									{showChangelog ? "Dölj" : "Visa äldre"}
								</MDBBtn>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>




			</MDBContainer>
		</div> */}

		</>
	);
}
