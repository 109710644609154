import { fetch_post } from "../../../../services/common";

export async function AddSkyttekort(userKey,sportsVal,skyttekortNumber){

	const date = new Date();

	let currentDate = new Date().toJSON().slice(0, 10);

	const response = await fetch_post("app/user/do_submit/" + userKey, {
		action: "add_skyttekort",
		db_sportgren_id: sportsVal,
		db_skyttekort_nummer: skyttekortNumber,
		db_skyttekort_datum: currentDate,
		db_def_skyttekort_id: sportsVal

	});
	
	if (response.message !== "OK") {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	} 
	return  response;
}