import React, { useState, useRef, useEffect } from 'react';
import {
    Outlet,
    NavLink,
    Link,
    useNavigate,
    useParams
} from "react-router-dom";
import {
    MDBStepper,
    MDBStepperStep,
    MDBValidationItem,
    MDBInput,
    MDBStepperForm,
    MDBBtn,
    MDBContainer,
    MDBBtnGroup,
    MDBIcon,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane
} from 'mdb-react-ui-kit';
import EventNew, { action as EventNewAction, loader as EventNewLoader } from "./EventNew";


function ButtonLink({ to, children }) {
    return <Link to={to}><MDBBtn>{children}</MDBBtn></Link>;
}

export default function EventEditStepper() {

    const { id } = useParams();
    console.log('pp', id);

    const navigate = useNavigate();

    const [basicActive, setBasicActive] = useState('start');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }
        navigate(value);
        setBasicActive(value);
    };


    return (
        <>
            <MDBContainer className="p-2">
                {/*               <MDBBtnGroup aria-label='Wisard tävling'>
                  <ButtonLink to="step1">Steg1</ButtonLink>
                  <ButtonLink to="step2">Steg2</ButtonLink>
                  <ButtonLink to="step3">Steg3</ButtonLink>
                </MDBBtnGroup> */}

                <MDBTabs pills className='mb-3'>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('start')} active={basicActive === 'start'}>
                            <MDBIcon fas icon='cogs' className='me-2' /> Start
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('gren-klass')} active={basicActive === 'gren-klass'}>
                            Gren / Diciplin / Klasser
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('betalning')} active={basicActive === 'betalning'}>
                            Betalning
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('dokument')} active={basicActive === 'dokument'}>
                            Dokument
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('lag')} active={basicActive === 'lag'}>
                            Lagtävling
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('extra')} active={basicActive === 'extra'}>
                        Extra
                        </MDBTabsLink>
                    </MDBTabsItem>                    
                </MDBTabs>

                <Outlet />
            </MDBContainer>
        </>
    );
}