import { MDBContainer, MDBSpinner } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { fetch_get, fetch_post } from "../../../services/common";
import { useNavigate } from "react-router-dom";
import { useLoaderData } from "react-router-dom";

export async function loader({ params }) {

	return params.key_id;
}

export default function PaymentCancel() {

	const navigate = useNavigate();
	const order_key = useLoaderData();

	useEffect(() => {
		fetch_get("app/pay/data/" + order_key)
			.then((data) =>
				setTimeout(() => {
					navigate(data.data.return_link);
					fetch_post("app/pay/do_submit/" + data.data.key_id, {action: "add_edit", db_status: "Aborted"});
				}, 3000)
			);
	}, []);
      //
   return (
    <MDBContainer className="py-4">
        <div className='d-flex justify-content-center'>
          Köpet avbröts, du skickas strax tillbaka!
        </div>
				<div className='d-flex justify-content-center'>
          <MDBSpinner role='status'>
            <span className='visually-hidden'>Loading...</span>
          </MDBSpinner>
        </div>
    </MDBContainer>
   );
}