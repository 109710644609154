export const GetLatestStartDate = (userLicenses) => {
	const currentDate = newDate(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
	let latestDate = currentDate
	if(userLicenses !== null && userLicenses.length > 0){
		userLicenses.map(license => {
			if(license.status == "ACTIVE" || license.status == "PENDING"){
				if(formatDate(latestDate).getTime() < formatDate(license.end_date).getTime()){
					latestDate = license.end_date;
				}
			}
		});
	}
	let date = new Date(latestDate);
	if(latestDate !== currentDate){
		date.setDate(date.getDate() + 1);
	}
	return newDate(date)
}

export const GetValidThru = (userLicenses) => {
	const currentDate = newDate(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
	let latestDate = currentDate
	if(userLicenses !== null && userLicenses.length > 0){
		userLicenses.map(license => {
			if(license.status == "ACTIVE" || license.status == "PENDING"){
				if(formatDate(latestDate).getTime() < formatDate(license.end_date).getTime()){
					latestDate = license.end_date;
				}
			}
		});
	}
	let date = new Date(latestDate);
	return newDate(date)
}

export const GetEndDate = (userLicenses) => {
	let date = new Date(GetLatestStartDate(userLicenses))
	date.setFullYear(date.getFullYear() + 1);
	date.setTime(date.getTime() - 86400000)
	return newDate(date)
}

function padDigits(num) {
	return num.toString().padStart(2, '0');
}

export function newDate(date) {
	return [
		date.getFullYear(),
		padDigits(date.getMonth() + 1),
		padDigits(date.getDate()),
	].join('-');
}

export function formatDate(date){
	if(date != null){return new Date(date.split("/").reverse().join("-"));}
}

export function AdjustEndDate(licenseID ,fromDate, licenseList){
	var date = new Date(fromDate);
	licenseList.map(license => {
		if((license.id == licenseID) && (license.rolling_periods != null)){
			date.setMonth(date.getMonth()+Number(license.rolling_periods));
		}
		if((license.id == licenseID) && license.fixed_end_date != null){
			date = new Date(license.fixed_end_date)
		}
	})
	return newDate(date);
}

export function GetLicensePeriod(licenseID, licenseList){
	return licenseList.find(obj => obj.id == licenseID ).id;
}

export function GetLicenseAmount(licenseID, licenseList){
	return licenseList.find(obj => obj.id == licenseID ).amount;
}

export function CalculateDays(startDate,endDate){
	const dayInMS = 86400000;  
	if(startDate && endDate){
		if(startDate !== "" && startDate !== ""){
			return ((formatDate(endDate).getTime()+dayInMS)-(formatDate(startDate).getTime()))/dayInMS;
		}
		else return 0
	}
	else return 0
}

export function GetAllowedToBuy(userLicenses){
	const earliestBuyDate = new Date(new Date().getFullYear(),new Date().getMonth()+1,new Date().getDate()).getTime();
	const currentLatestDate = new Date(GetValidThru(userLicenses)).getTime();
	if(earliestBuyDate > currentLatestDate){
		return true
	} else {
		return false
	}
}