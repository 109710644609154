import React, { useState, useRef, useEffect } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBNavbar,
  MDBTooltip,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBTable,
  MDBListGroup,
  MDBListGroupItem,
  MDBTableHead,
  MDBTableBody

} from 'mdb-react-ui-kit';
import { useParams, useNavigate, NavLink, json } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";
import AuthService from "../../../services/auth.service";
import EventOverviewPageModal from "./EventOverviewPageModal";

export async function loader() {

  //throw new Response("Not Found", { status: 401, statusText: "testvvvvv"});
  //if(AuthService.is_loggedin) {
  /*
      throw json(
        {
          msg: "Du saknar behörighet",
          //hrEmail: "hr@bigco.com",
        },
        { status: 404 }
      );      
      throw new Response("Not Found", { status: 401, statusText: "test"});
    } */
  /*
    throw json(
      {
        msg: "Du saknar behörighet",
      },
      { status: 401 }
    );
    */
  return [];
}
import { BorderHeadline } from '../../modules/BorderHeadline';

export default function EventAnmalan({ props }) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [event_data, setEventData] = useState([]);
  const [data_all, setDataAll] = useState([]);
  const [data2, setData2] = useState([]);
  const [current_forening, setForening] = useState(null);
  const [forening_name, setForeningName] = useState(null);
  const [test, setTest] = useState("3");
  const [teams, setTeams] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [klass_list, setKlassList] = useState(null);
  //const [lov_foreningar, setDataForeningar] = useState([]);
  const [lov_team, setLovTeam] = useState([]);
  const [lov_klasser, setDataLovKlasser] = useState([]);
  const [medlemmar, setDataMedlemmar] = useState({
    columns: [],
    rows: []
  });
  const [loading, setLoading] = useState(true);
  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);

  const [eventModal, setEventModal] = useState(false);
  const toggleEventOpen = () => setEventModal(!eventModal);



  let formObjekt = {};

  useEffect(() => {



    fetch_get('app/event/deltagare_registrera/' + id)
      .then(data => {

        let data_out = data.data.deltagare;

        const data_lov_klasser = data.data.lov_klasser;
        data_lov_klasser.unshift({ "value": " ", "text": "-" });


        data_out.map((k_item, k) => {
          formObjekt["comment" + k_item.id] = k_item.comment;
        });

        setFormValue(formObjekt);
        setDataAll(data.data);

        const medl2 = data.data.user_list.map((row) => {
          return {
            ...row,
            add_button: (
              <>
                <span className='fw-bold mb-1'>{row.name}</span> <span className='text-muted mb-0'>{row.forening_name}</span>
                <MDBBtn outline size='sm' floating className='call-btn me-4  float-end' onClick={() => onadddeltagare(row.key_id, row.forening_id)}>
                  <MDBIcon fas icon="angle-right" />
                </MDBBtn>
              </>
            ),
            test: (
              <>
                <MDBInput label='Example label' id='form1' type='text' size='sm' />
              </>
            ),
          };
        });
        console.log('medl2', medl2);
        setDataMedlemmar({
          columns: [
            { label: '', field: 'add_button', sort: false },
          ],
          rows: medl2,
        });

        setData(data_out);
        setTeams(data.data.teams);
        setLovTeam(data.data.lov_teams_config);
        setDataLovKlasser(data_lov_klasser);
        setForeningName(data.data.forening_name);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });

    fetch_get('app/event/view/' + id)
      .then(data => {
        setEventData(data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });

    /*
        fetch_get('app/event/lov-team-config/' + id)
          .then(data => {
            data.data.unshift({ "value": "", "text": "-" });
            //console.log('team-lov', data.data);
            setLovTeam(data.data);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
          */

  }, [loading]);

  const lov_team_nr = [{ text: '1' }, { text: '2' }, { text: '3' }, { text: '4' }, { text: '5' }, { text: '6' }, { text: '7' }, { text: '8' }, { text: '9' }];

  const advancedData = {
    columns: [
      // { label: ' ', field: 'delete_button', sort: false, width: 55 },
      { label: ' ', field: 'delete_button', sort: false, width: 60 },
      //{ label: 'Förening', field: 'forening_name', sort: true, width: 160 },
      { label: 'Status', field: 'status', sort: true, width: 120 },
      { label: 'Namn', field: 'full_name', sort: true, width: 160 },
      { label: 'Förening', field: 'forening_name', sort: true, width: 160 },
      { label: 'Klass', field: 'select_klass', sort: true, width: 120 },
      //{ label: 'Lag', field: 'select_team', sort: true, width: 120 },
      { label: 'Lag', field: 'teams_string', sort: true, width: 120 },
      //{ label: '#', field: 'select_team_nr', sort: true, width: 100 },
      { label: 'Kommentar/Önskemål', field: 'comment', sort: true, width: 200 },
      // { label: 'xx', field: 'aa', sort: true, width: 80 },
    ],
    rows: data.map((row) => {

      row.lov_k = lov_klasser.map(o => (o.value === row.event_klass_id ? { ...o, defaultSelected: true } : o));
      row.lov_team_id = lov_team.map(o => (o.value === row.event_team_id ? { ...o, defaultSelected: true } : o));
      row.lov_team_nr = lov_team_nr.map(o => (o.text === row.event_team_id_nr ? { ...o, defaultSelected: true } : o));

      row.teams_string = row.teams.map(o => o.name_short).join();

      console.log('row', row.full_name, row.teams_string);

      return {
        ...row,
        delete_button: (
          <MDBPopconfirm
            //outline
            //size='sm'
            //floating
            style={{ padding: '5px' }}
            color="none"
            modal
            btnChildren={<MDBIcon far icon="trash-alt" color="danger" size='sm' />}
            //<MDBIcon far icon="trash-alt" color="danger" size='sm' />
            cancelBtnText='Avbryt'
            onConfirm={() => ondeleteDeltagare(row.id)}
            cancelBtnClasses='btn-secondary'
            btnClassName='btn btn-link'
            title={"Ta bort deltagare"}
          >
            <MDBPopconfirmMessage
              icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
              size='lg'
            >
              Ta bort deltagare: <br /><br />{row.full_name}<br />{row.forening_name}?
            </MDBPopconfirmMessage>
          </MDBPopconfirm>

        ),
        select_klass: (
          <>
            {row.event_klass_id === " " && (
              <MDBSelect
                size='sm'
                style={{ backgroundColor: '#ffb3b3', fontWeight: 400 }}
                data={row.lov_k}
                preventFirstSelection
                onValueChange={(e) => onKlassChanged(row.id, e.value)}
              />
            )}
            {row.event_klass_id !== " " && (
              <MDBSelect
                size='sm'
                data={row.lov_k}
                preventFirstSelection
                onValueChange={(e) => onKlassChanged(row.id, e.value)}
              />
            )}

          </>
        ),
        select_team: (
          <>
            <MDBSelect
              size='sm'
              data={row.lov_team_id}
              // preventFirstSelection
              onValueChange={(e) => onTeamChanged(row.id, e.value)}
            />
          </>
        ),
        select_team_nr: (
          <>
            <MDBSelect
              size='sm'
              data={row.lov_team_nr}
              preventFirstSelection
              onValueChange={(e) => onTeamNrChanged(row.id, e.text)}
            />
          </>
        ),
        comment: (
          <>
            <MDBInput
              id={'id_comment' + row.id}
              name={'comment' + row.id}
              label='Kommentar/Önskemål'
              // placeholder="T.ex. önskad starttid"
              type='text'
              size='sm'
              value={formValue['comment' + row.id]}
              onChange={(e) => onChange(e)}
              onBlur={(e) => onBlur_comment(row.id, e)}
            />
          </>
        ),
        status: (
          <MDBBadge color={row.event_order_status_id == 10 ? 'warning' : row.event_order_status_id == 20 ? 'success' : ''} pill>
            {row.event_order_status_name}
          </MDBBadge>
        )
      };
    })
  };

  const onadddeltagare = async (user_key_id, forening_id) => {

    console.log('onadddeltagare', user_key_id, forening_id);
    const form_params = {
      "action": "event_add_deltagare",
      "admin": false,
      "user_key_id": user_key_id,
      "db_forening_id": forening_id
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onBlur_comment = async (deltagar_id, e) => {

    console.log('onBlur_comment', e.target.value, e);
    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_comment": e.target.value
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onChange = async (e) => {

    console.log('onchange', e.target.name, e.target.value);
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const onTeamChanged = async (deltagar_id, lov_id) => {

    // setTest(klass_id);

    console.log('onTeamChanged', deltagar_id, lov_id, data);

    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_event_team_id": lov_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onTeamNrChanged = async (deltagar_id, lov_id) => {

    // setTest(klass_id);

    console.log('onTeamNrChanged', deltagar_id, lov_id);

    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_event_team_id_nr": lov_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onKlassChanged = async (deltagar_id, klass_id) => {

    // setTest(klass_id);

    console.log('onKlassChanged', deltagar_id, klass_id, data);

    const form_params = {
      "action": "event_set_deltagar_klass",
      "deltagar_id": deltagar_id,
      "db_event_klass_id": klass_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };


  const ondeleteDeltagare = async (deltagar_id) => {

    console.log('ondeleteDeltagare', id);
    const form_params = {
      "action": "event_delete_deltagare",
      "deltagar_id": deltagar_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };




  return (
    <>
      <MDBContainer className="py-4">

        <h1 className="fs-1">Tävling - {event_data.name} <span className="fs-4">({event_data.start_date})</span><span><MDBBtn color='secondary' onClick={toggleEventOpen} size='sm' className='ms-3'><MDBIcon fas icon="ellipsis-h" /></MDBBtn></span></h1>


        <MDBRow className='g-3 py-3'>
          <MDBCol className='col-md-12 col-sm-12'>
            <MDBRow className='g-3'>

              <MDBCol className='col-md-3 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>

                <BorderHeadline headline="Föreningsmedlemmar" />
                <MDBDatatable
                  //className='pb-4'
                  //search={false}
                  bordered
                  sm
                  striped
                  hover
                  maxHeight='560px'
                  sortField='full_name'
                  pagination={false}
                  entries={1000}
                  noFoundMessage='Inga medlemmar...'
                  loaderClass='Laddar...'
                  ofText='av'
                  rowsText='Rader per sida:'
                  data={medlemmar}
                />
              </MDBCol>
              <MDBCol className='col-md-8 square border border-primary rounded-6 p-4 mx-1 position-relative'>

                <BorderHeadline headline="Deltagare" />

                <MDBNavbar light bgColor='light'>
                  <MDBContainer tag="form" fluid className='justify-content-start'>
                    <NavLink
                      className='btn btn-primary btn-sm me-2'
                      onClick={toggleOpen}
                    >
                      <MDBIcon fas icon="paper-plane" className='me-2' />Visa / Skicka in
                    </NavLink>





                  </MDBContainer>
                </MDBNavbar>

                <MDBDatatable
                  //className='pb-4'
                  //search
                  bordered
                  sm
                  striped
                  hover
                  sortField='full_name'
                  pagination={false}
                  entries={1000}
                  noFoundMessage='Inga deltagare inlagda...'
                  loaderClass='Laddar...'
                  ofText='av'
                  rowsText='Rader per sida:'
                  data={advancedData}
                  format={(field, value) => {
                    if (field === 'event_order_status_name') {
                      if (value === 'Ej inskickad') {
                        return { backgroundColor: '#e4a11b', fontWeight: 400 };
                      } else if (value === 'Inskickad') {
                        return { backgroundColor: '#14a44d', fontWeight: 400 };
                      } else {
                        // return { backgroundColor: '#14A44D', fontWeight: 400 };
                      }
                    }
                  }}
                /*                   format={(field, value) => {
                                    if (field === 'select_klass') {
                                      console.log('value', value);
                                      return { backgroundColor: '#E3F2FD', fontWeight: 400 };
                                    }
                                  }} */
                />
              </MDBCol>


            </MDBRow>
          </MDBCol>
        </MDBRow>
        {/* <EventLagAnmalan teams={teams} set_loading={() => set_loading_true}/> */}
        {data_all.forening_id && (
          <EventLagAnmalan data_all={data_all} teams={teams} set_loading={setLoading} />
        )}
      </MDBContainer >

      <EventAnmalanSendModal basicModal={basicModal} setBasicModal={setBasicModal} toggleOpen={toggleOpen} data_all={data_all} teams={teams} set_loading={setLoading} />

      <EventInfo eventModal={eventModal} setEventModal={setEventModal} toggleEventOpen={toggleEventOpen} event_data={event_data} />
      {/* 
      <MDBModal open={basicModal} staticBackdrop setopen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBValidation onSubmit={console.log('submitt')}>
              <MDBModalHeader>
                <MDBModalTitle>Visa / Skicka in</MDBModalTitle>
                <MDBBtn className='btn-close' type='reset' color='none' onClick={toggleOpen}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>

                <MDBRow>
                  <h6>Visa / Skicka in</h6>
                </MDBRow>



              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color='secondary' type='reset' onClick={toggleOpen}>
                  Avbryt
                </MDBBtn>
                <MDBBtn type='submit'>Skicka in</MDBBtn>
              </MDBModalFooter>
            </MDBValidation>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
 */}


    </>
  );
}

// ****************************** Laganmäalan
function EventLagAnmalan(params) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [data_all, setDataAll] = useState([]);
  //const [deltagare, setDeltagare] = useState([]);
  const [deltagare_lov, setDeltagare_lov] = useState([]);
  //const [loading, setLoading] = useState(true);
  const [members_arr, setMembersArr] = useState([]);
  //const [lov_teams, setLovTeams] = useState([]);
  ///const [forening_name, setForeningName] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);
  const [formValue, setFormValue] = useState({
    action: 'event_team_members',
    key_id: id,
    team_id: 0,
    db_event_team_config_id: "",
    db_name: "",
    number_of_team_members: "",
    db_team_id: "16",
    members: []
  });

  console.log('params', params);
  useEffect(() => {
    setDataAll(params.data_all);
    setData(params.teams);
    //setLovTeams(params.lov_team);
    //setForeningName(params.forening_name);
    //setDeltagare(params.deltagare);

  }, [params.teams, params.data_all]);


  /*
     useEffect(() => {
      setData(params.teams);
      //https://dev.svsf-ta.se/api/app/event/forening-teams/53a6c57f-377e-11ee-9385-00505692ffcc/62
      fetch_get('app/event/forening-teams/' + id + '/62')
        .then(data => {
          setData2(data.data.teams);
          console.log('sssss', data.data.lov_teams_config);
          setLovTeams(data.data.lov_teams_config);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
  
  
    }, [loading]); 
    */

  const onSubmitEvent = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      setBasicModal(false);
      console.log('formValue', formValue);
      const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, formValue);
      params.set_loading(true)
    } else {
      console.log('form not valid');
    }

  };

  const ondelete = async (team_id) => {

    console.log('ondelete', id);
    const form_params = {
      "action": "event_team_members_delete",
      "team_id": team_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    params.set_loading(true);

  };

  const onnew = async () => {

    console.log('new');
    setFormValue(
      {
        action: 'event_team_members',
        key_id: id,
        team_id: "",
        db_team_suffix: "",
        number_of_team_members: "",
        db_event_team_config_id: "",
        db_forening_id: data_all.forening_id,
        members: []
      });

    setDeltagare_lov([]);
    setMembersArr([]);

    setBasicModal(true);

  };

  const onEdit = async (e) => {

    console.log('Edit', e);

    const members_selected = [];
    e.members.map((k_item, k) => {
      //if (klass_arr.includes(k_item.event_klass_id)) {
      members_selected.push(k_item.event_deltagare_id);
      //}
    });
    setFormValue(
      {
        action: 'event_team_members',
        key_id: id,
        team_id: e.id,
        db_team_suffix: e.team_suffix,
        number_of_team_members: e.number_of_team_members,
        db_event_team_config_id: e.event_team_config_id,
        db_forening_id: e.forening_id,
        members: members_selected
      });

    //  const members_tmp = [];
    //  data_all.deltagare.map((k_item, k) => {
    //    //if (klass_arr.includes(k_item.event_klass_id)) {
    //      members_tmp.push({ text: k_item.full_name + ' - ' + k_item.klass_name_display + '', value: k_item.id });
    //    //}
    //  });
    //  setDeltagare_lov(members_tmp);       

    console.log('members_selected', members_selected);
    setMembersArr(members_selected);

    setBasicModal(true);

  };



  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const onMembersChange = (e) => {
    console.log('e', e)
    setFormValue({ ...formValue, 'members': e.map(a => a.value) })
    setMembersArr(e);
    //setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const onTeamChange = (e) => {
    console.log('e2', e, e.value);


    setFormValue({ ...formValue, 'number_of_team_members': e.number_of_team_members, 'db_event_team_config_id': e.value });
    // setFormValue({ ...formValue, 'team_id': 3 });
    const klass_arr = e.klasser_id.split(',');
    console.log('klass_arr', klass_arr);
    const members_tmp = [];
    data_all.deltagare.map((k_item, k) => {
      if (klass_arr.includes(k_item.event_klass_id)) {
        members_tmp.push({ text: k_item.full_name + ' - ' + k_item.klass_name_display + '', value: k_item.id });
      }
    });
    setDeltagare_lov(members_tmp);
  };

  const mainStyles = {
    rubriker: {
      height: 20,
      // position: 'absolute',
      top: '-10px',

    },
    button: {
      padding: '5px'
    }
  };

  return (
    <>
      <MDBRow>

        <MDBCol className='col-md-11 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
          <MDBBadge light className="position-absolute" style={mainStyles.rubriker}>Laganmälan</MDBBadge>
          <MDBNavbar light bgColor='light'>
            <MDBContainer tag="form" fluid className='justify-content-start'>
              <NavLink
                className='btn btn-primary btn-sm me-2'
                onClick={onnew}
              >
                <MDBIcon fas icon="plus" className='me-2' />Lägg till föreningslag
              </NavLink>





            </MDBContainer>
          </MDBNavbar>
          <MDBTable bordered className='mt-4'>
            <tr>
              <th></th>
              <th>Status</th>
              <th>Lagtävling</th>
              <th>Namnet på laget</th>
              <th>Ingående deltagare</th>
            </tr>

            {data.map((item, i) => (
              <>
                <tr key={i}>
                  <td>
                    <MDBBtn style={{ padding: '5px' }} className="btn btn-link ms-2" color="none" onClick={() => onEdit(item)}><MDBIcon far icon="edit" color="primary" size='sm' /></MDBBtn>
                    <MDBPopconfirm
                      style={{ padding: '5px' }}
                      color="none"
                      modal
                      btnChildren={<MDBIcon far icon="trash-alt" color="danger" size='sm' />}
                      //<MDBIcon far icon="trash-alt" color="danger" size='sm' />
                      cancelBtnText='Avbryt'
                      onConfirm={() => ondelete(item.id)} //params.set_loading(true)} //console.log(item.id)} // params.set_loading(true)}
                      cancelBtnClasses='btn-secondary'
                      btnClassName='btn btn-link'
                      title={"Ta bort laget"}
                    >
                      <MDBPopconfirmMessage
                        icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
                        size='lg'
                      >
                        Ta bort laget: <br /><br />{item.team_config_name}<br />{item.name} {item.team_suffix}?
                      </MDBPopconfirmMessage>
                    </MDBPopconfirm>
                  </td>
                  <td><span>
                    <MDBBadge color={item.event_order_status_id == 10 ? 'warning' : item.event_order_status_id == 20 ? 'success' : ''} pill>
                      {item.event_order_status_name}
                    </MDBBadge>
                  </span>
                  </td>
                  {/* <span className={item.event_order_status_id == 10 ? 'text-warning' : item.event_order_status_id == 20 ? 'text-success' : ''}>{item.event_order_status_name}</span></td> */}
                  <td>{item.team_config_name}</td>
                  <td>{item.name} {item.team_suffix}</td>
                  <td>
                    <MDBTable bordered className='mt-2'>
                      <tr>
                        <th>Namn</th>
                        <th>Klass</th>
                        <th>Förening</th>
                      </tr>
                      {item.members.map((members, k) => (
                        <>
                          <tr key={k}>
                            <td>{members.full_name}</td>
                            <td>{members.klass_name_display}</td>
                            <td>{members.forening_name}</td>
                          </tr>
                        </>
                      ))}
                    </MDBTable>
                  </td>
                </tr>
              </>
            ))}
          </MDBTable>
        </MDBCol>
      </MDBRow>



      <MDBModal open={basicModal} staticBackdrop setopen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBValidation onSubmit={onSubmitEvent}>
              <MDBModalHeader>
                <MDBModalTitle>Ny/Ändra Lag</MDBModalTitle>
                <MDBBtn className='btn-close' type='reset' color='none' onClick={toggleOpen}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>

                <MDBRow>
                  <MDBValidationItem className='py-2' invalid feedback='Ange lagtävling.'>
                    {basicModal && (

                      <MDBSelect
                        name='db_event_team_config_id'
                        id='form_event_team_config_id'
                        label='Lagtävling'
                        preventFirstSelection
                        data={data_all.lov_teams_config}
                        required
                        validation
                        value={formValue.db_event_team_config_id}
                        validFeedback={false}
                        invalidFeedback
                        optionHeight={50}
                        onValueChange={(e) => { onTeamChange(e); }} //setFormValue({ ...formValue, 'db_def_event_team_type_id': e.value })}
                      />
                    )}
                  </MDBValidationItem>
                </MDBRow>
                <MDBRow>
                  <MDBValidationItem className='py-2 col-8' invalid feedback='-'>
                    {basicModal && (
                      <>
                        <MDBInput
                          name='db_forening_name'
                          id='form_db_forening_name'
                          label={<>Föreningsnamn</>}
                          type='text'
                          readonly
                          value={data_all.forening_name}
                        />

                      </>
                    )}
                  </MDBValidationItem>
                  <MDBValidationItem className='py-2 col-4' invalid feedback='Ange Lagnr/bokstav.'>
                    {basicModal && (
                      <>

                        <MDBInput
                          name='db_team_suffix'
                          id='form_db_team_suffix'
                          size={1}
                          label={<>Lagnr/bokstav</>}
                          type='text'
                          maxlength="1"
                          required
                          value={formValue.db_team_suffix}
                          onChange={onChange}
                        />

                      </>
                    )}
                  </MDBValidationItem>

                  <MDBValidationItem className='py-2 col-10' invalid feedback='Ange ingående klasser.'>
                    {basicModal && (
                      <MDBSelect
                        className="text-wrap"
                        name='members'
                        id='form_members'
                        displayedLabels={0}
                        label='Ingående lagmedlemmar'
                        optionsSelectedLabel='valda medlemmar'
                        data={deltagare_lov}
                        multiple
                        validation
                        validFeedback
                        value={formValue.members}
                        invalidFeedback
                        //optionHeight={40}
                        //selectAllLabel="Välj alla"
                        selectAll={false}
                        visibleOptions={6}
                        //onValueChange={(e) => setFormValue({ ...formValue, 'klasser': e.map(a => a.value) })}
                        onValueChange={(e) => onMembersChange(e)}
                      />
                    )}
                  </MDBValidationItem>

                  <MDBListGroup className='p-3' light small>
                    {console.log('formValue.members', formValue.members)}
                    {members_arr.map((members, k) => (
                      <>
                        <MDBListGroupItem>{members.text}</MDBListGroupItem>
                      </>


                    ))}
                  </MDBListGroup>
                  {/*                     <MDBValidationItem className='py-2 col-6' invalid feedback='Ange antal skyttar i laget.'>
                      {basicModal && (
                        <MDBInput
                          name='db_number_of_team_members'
                          id='form_db_number_of_team_members'
                          label={<>Antal skyttar i laget</>}
                          type='number'
                          required
                          value={formValue.db_number_of_team_members}
                          onChange={onChange}
                        />
                      )}
                    </MDBValidationItem> */}
                  {/*                     <MDBValidationItem className='py-2 col-6' invalid feedback='Ange startavgift.'>
                      {basicModal && (
                        <MDBInput
                          name='db_startavgift'
                          id='form_db_startavgift'
                          label={<>Startavgift (kr)</>}
                          type='number'
                          required
                          value={formValue.db_startavgift}
                          onChange={onChange}
                        />
                      )}
                    </MDBValidationItem> */}
                  {/*                     <MDBValidationItem className='py-2 col-10' invalid feedback='Ange ingående klasser.'>
                      {basicModal && (
                        <MDBSelect
                          name='klasser'
                          id='form_klasser'
                          displayedLabels={8}
                          label='Ingående klasser'
                          optionsSelectedLabel='klasser valda'
                          //data={lov_klasser}
                          multiple
                          validation
                          validFeedback
                          value={formValue.klasser}
                          invalidFeedback
                          optionHeight={40}
                          selectAllLabel="Välj alla"
                          visibleOptions={6}
                          onValueChange={(e) => setFormValue({ ...formValue, 'klasser': e.map(a => a.value) })}
                        />
                      )}
                    </MDBValidationItem> */}
                </MDBRow>


              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color='secondary' type='reset' onClick={toggleOpen}>
                  Avbryt
                </MDBBtn>
                <MDBBtn type='submit'>Spara</MDBBtn>
              </MDBModalFooter>
            </MDBValidation>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>


    </>
  );
}


// ****************************** Laganmäalan
function EventAnmalanSendModal(params) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [order_id, setOrderId] = useState(null);
  const [teams, setTeams] = useState([]);
  const [event, setEvent] = useState({});
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sumdeltagare, setSumDeltagare] = useState(0);
  const [sumlag, setSumLag] = useState(0);
  const [sumtot, setSumTot] = useState(0);

  console.log('params', params);

  useEffect(() => {



    fetch_get('app/event/deltagare_registrera_draft/' + id)
      .then(data => {
        let d = data.data.deltagare;
        console.log('data.data.deltagare', d);
        // Summa start avgifter deltagare
        //let d_sum = get_sum(data.data.deltagare);
        let d_sum = 0;
        if (data.data.deltagare) {
          d_sum = data.data.deltagare.reduce((n, { startavgift }) => n + Number(startavgift), 0);
        }
        //let d_sum = data.data.deltagare.reduce((n, { startavgift }) => n + Number(startavgift), 0);
        setSumDeltagare(d_sum);
        let l_sum = 0;
        if (data.data.teams) {
          l_sum = data.data.teams.reduce((n, { startavgift }) => n + Number(startavgift), 0);
        }
        //let l_sum = data.data.teams.reduce((n, { startavgift }) => n + Number(startavgift), 0);

        setSumLag(l_sum);
        setSumTot(d_sum + l_sum);

        console.log('max', data.data.deltagare.reduce((n, { id }) => Math.max(n, Number(id)), 0));

        setData(d);
        setTeams(data.data.teams);
        setOrderId(data.data.order_id);
        setEvent(data.data.event);
        setEmail(data.data.return_email);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });



  }, [params.basicModal]);

  const get_sum = async (arr) => {
    return arr.reduce((n, { startavgift }) => n + Number(startavgift), 0);
  };



  const onSubmitEvent = async (e) => {
    e.preventDefault();

    console.log('ssuubbmmiitt', id);

    params.setBasicModal(false);
    //   console.log('formValue', formValue);
    const resJson = await fetch_post('app/event/do_submit/' + id, { action: 'event_order_send' });
    params.set_loading(true);

  };

  return (
    <>
      <MDBModal open={params.basicModal} staticBackdrop setopen={params.setBasicModal} tabIndex='-1'>
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBValidation onSubmit={onSubmitEvent}>
              <MDBModalHeader>
                <MDBModalTitle>Visa / Skicka in</MDBModalTitle>
                <MDBBtn className='btn-close' type='reset' color='none' onClick={params.toggleOpen}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <h6>Order: #{order_id}</h6>
                <MDBRow className="px-4">
                  <MDBTable small className='caption-top'>
                    <caption>Deltagare</caption>
                    <MDBTableHead light>
                      <tr>
                        <th scope='col'>Namn</th>
                        <th scope='col'>Förening</th>
                        <th scope='col'>Klass</th>
                        <th scope='col'>Startavgift</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {console.log('data', data)}
                      {data.map(item => (
                        <>
                          {/* {setSumDeltagare(sumdeltagare + 1)} */}
                          <tr key={item.id}>
                            <th scope='row'>{item.full_name}</th>
                            <td>{item.forening_name}</td>
                            <td>{item.klass_name_display}</td>
                            <td>{item.startavgift} kr</td>
                          </tr>
                        </>
                        // <li key={item.id}>{item.first_name} {item.last_name}</li>
                        // Du måste ersätta "id" och "name" med de faktiska datanamnen från din API-respons.
                      ))}

                    </MDBTableBody>
                    <tfoot>
                      <tr>
                        <th>Totalt startavgifter:</th>
                        <td></td>
                        <td></td>
                        <th>{sumdeltagare} kr</th>
                      </tr>
                    </tfoot>
                  </MDBTable>
                </MDBRow>
                {teams.length > 0 && (
                  <MDBRow className="px-4">
                    <MDBTable small className='caption-top'>
                      <caption>Lag</caption>
                      <MDBTableHead light>
                        <tr>
                          <th scope='col'>Lagnamn</th>
                          <th scope='col'>Lagtävling</th>
                          <th scope='col'>Startavgift</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {teams.map(item => (
                          <>
                            {/* {setSumDeltagare(sumdeltagare + 1)} */}
                            <tr key={item.id}>
                              <th scope='row'>{item.name} {item.team_suffix}</th>
                              <th scope='row'>{item.team_config_name}</th>
                              <th scope='row'>{item.startavgift} kr</th>

                            </tr>
                          </>
                          // <li key={item.id}>{item.first_name} {item.last_name}</li>
                          // Du måste ersätta "id" och "name" med de faktiska datanamnen från din API-respons.
                        ))}

                      </MDBTableBody>
                      <tfoot>
                        <tr>
                          <th>Totalt lagavgifter:</th>
                          <td></td>
                          <th>{sumlag} kr</th>
                        </tr>
                      </tfoot>
                    </MDBTable>
                  </MDBRow>
                )}

                <p className="px-4">Totalt att betala in: <strong>{sumtot}kr</strong><br />
                  Inbetalning konto: <strong>{event.betalning_kontonr}</strong> eller swish: <strong>{event.betalning_swish_nr}</strong><br />
                  Ange föreningsnamn och ordernummer. Ordernummer: <strong>{order_id}</strong><br />
                  Senast: <strong>{event.betalning_end_date}</strong><br />
                  Bekräftelse mail skickas till: <strong>{email}</strong></p>
              </MDBModalBody>

              <MDBModalFooter className="position-relative position-relative-example">

                <MDBBtn color='secondary' type='reset' onClick={params.toggleOpen}>
                  Avbryt
                </MDBBtn>
                <MDBBtn type='submit'>Skicka in</MDBBtn>
              </MDBModalFooter>
            </MDBValidation>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

    </>
  );
}


function EventInfo(props) {
  //const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  //const toggleOpen = () => setFullscreenXlModal(!fullscreenXlModal);

  return (
    <>

      <MDBModal tabIndex='-1' open={props.eventModal} setOpen={props.setEventModal} nonInvasive={false}>
        <MDBModalDialog size="lg">
          <MDBModalContent >
            <MDBModalHeader>
              <MDBModalTitle>Tävlingsinformation</MDBModalTitle>
              <MDBBtn
                type='button'
                className='btn-close'
                color='none'
                onClick={props.toggleEventOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {/* {props.id && (<EventOverviewPageModal {...props}></EventOverviewPageModal>)} */}
              <div className="d-flex flex-column flex-md-row justify-content-around">
                <div className="d-flex flex-column flex-fill mb-3 order-2 order-sm-1">
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Tävling</div>
                    {props.event_data.name || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Arrangör</div>
                    {props.event_data.arrangorer || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Typ Av tävling</div>
                    {props.event_data.type_name || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Kategori</div>
                    {props.event_data.kategori_name || '-'}
                  </div>
                  <div className="p-2 position-relative overflow-wrap">
                    <div className="overview-label text-nowrap">Sportgren</div>
                    {props.event_data.d_sportgren
                      ? (props.event_data.d_sportgren.split(',').map(sg => <div>{sg}</div>))
                      : <div>-</div>
                    }
                  </div>
                  <hr className="hr" />
                  <div className="p-2 position-relative css-fix">
                    <div className="overview-label text-nowrap">Plats</div>
                    {props.event_data.plats || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Hemsida</div>
                    <a href={props.event_data.hemsida} target='_new'>{props.event_data.hemsida || '-'}</a>
                  </div>
                  <hr className="hr" />
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Kontakt</div>
                    {props.event_data.kontakt_first_name || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">E-mail</div>
                    {props.event_data.kontakt_email || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Telefon</div>
                    {props.event_data.kontakt_phone || '-'}
                  </div>
                </div>
                <div className="d-flex flex-column flex-fill mb-3 order-3 order-sm-2">
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Start datum</div>
                    {props.event_data.start_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Slut datum</div>
                    {props.event_data.end_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Öppnar för anmälan</div>
                    {props.event_data.anmalan_start_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Sista anmälningsdatum</div>
                    {props.event_data.anmalan_end_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Sista efteranmälningsdatum</div>
                    {props.event_data.efteranmalan_end_date || '-'}
                  </div>
                </div>
                <div className="d-flex flex-column flex-fill mb-3 order-1 order-sm-3">
                  <div className="p-2 position-relative" color={props.event_data.status_color}>
                    <div className="overview-label text-nowrap">Status</div>
                    {props.event_data.status_name || '-'}
                  </div>

                </div>
              </div>


            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type='button' color='secondary' onClick={props.toggleEventOpen}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}