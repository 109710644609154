import React,{useState, useEffect} from 'react';
import { useLoaderData, Link } from "react-router-dom";
import { fetch_get, fetch_post } from "../../../services/common";
import {
  MDBContainer,
  MDBCard,
  MDBBreadcrumb,
	MDBBreadcrumbItem,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBBadge,
  MDBIcon,
  MDBPopconfirm,
  MDBPopconfirmMessage
} from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";

export async function loader({ params }) {
  
  const data = localStorage.getItem("token");
  var decoded = "";
  if (data) {
    decoded = jwt_decode(data);
  }
    //const obj = await fetch_json("app/user/all/" + decoded.kid);
    return [];
}




export default function GrenHome() {

  const loadObject = useLoaderData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);  

  useEffect(() => {
    fetch_get('app/list/events-gren-approval/')
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);  


  const HeadObject = (it) => {
    return (
      <>
  
        <span className="d-inline-flex bd-highlight align-self-start text-nowrap flex-column">
          <strong>{it.item.start_date}</strong>
          {/* <span className="d-inline-flex pt-4 align-self-end"> */}
  
          <MDBBadge className="d-block d-sm-none mt-2" pill light color={it.item.status_color}>{it.item.status_name}</MDBBadge>
          <span className="ps-2 d-block d-sm-none align-self-center" data-bs-toggle="tooltip" data-bs-placement="top" title="Sätt i min lista"><MDBIcon className="float-end" far icon="heart" /></span>
          {/* </span> */}
  
        </span>
        <span className="d-inline-flex bd-highlight flex-column align-self-start flex-md-fill px-3 text-truncate" style={{ maxWidth: 250, minWidth: 250, }}>
          <strong title={it.item.name}>{it.item.name}</strong>
          <span className="font-monospace text-muted pt-2 fst-italic d-sm-block d-md-block d-lg-none">{it.item.arrangorer}</span>
          <span className="font-monospace text-muted pt-2 fst-italic ">{it.item.d2_name}</span>
        </span>
        <span className="d-lg-inline-flex bd-highlight flex-md-column d-none d-sm-none d-md-none">
          <strong>{it.item.arrangorer}</strong>
          <span className="font-monospace text-muted pt-2 fst-italic">Anmälan från: {it.item.anmalan_start_date} till {it.item.anmalan_end_date}</span>
  
        </span>
        {/*       <span className="d-inline-flex bd-highlight flex-column flex-fill align-self-start px-3 d-none d-sm-none d-md-none d-lg-block">
          <strong>{it.item.arrangorer}</strong>
          <span className="font-monospace text-muted pt-2 fst-italic">Anmälan från: {it.item.anmalan_start_date} till {it.item.anmalan_end_date}</span>
  
        </span>  */}
        <span className="d-inline-flex flex-column px-2 flex-fill align-self-start d-none d-sm-block">
          <span>
          {/* <MDBBtn onClick={(e) => onapprove('click', e)} className="float-end">Godkänn</MDBBtn> */}
          <MDBPopconfirm
                  // outline
                  size='sm'
                  // floating
                  btnClassName='float-end'
                  //color="info"
                  onClick={(e) => e.stopPropagation()}
                  modal
                  btnChildren='Godkänn'
             
                  cancelBtnText='Avbryt'
                  onConfirm={(e) => onapprove(it.item.key_id, e)}
                  onCancel={(e) => e.stopPropagation()}
                >
                  <MDBPopconfirmMessage
                    icon={<MDBIcon className='me-2' far icon="share-square" />}
  
                  >
                    Vill du godkänna tävlingen?
                  </MDBPopconfirmMessage>
                </MDBPopconfirm>        
          </span>
        </span>
  
      </>
    )
  }
  
  const onapprove = async (id, e) => {
    //e.preventDefault(); 
    e.stopPropagation();
      console.log(id);
      const form_params = {
        "action": "event_change_status",
        "db_status_id": 200
      };
  
  
  
      const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
      setLoading(true);    
  
  };
  
  const list = (data) => {
    return (
      data.map(item => {
        return (
          <>
            <MDBAccordionItem collapseId={item.key_id} headerTitle={<HeadObject item={item} />}>
              <div className="d-flex flex-column flex-md-row justify-content-around">
                <div className="d-flex flex-column flex-fill mb-3 order-2 order-sm-1">
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Tävling</div>
                    {item.name || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Arrangör</div>
                    {item.arrangorer || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Typ Av tävling</div>
                    {item.type_name || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Kategori</div>
                    {item.kategori_name || '-'}
                  </div>
                  <div className="p-2 position-relative overflow-wrap">
                    <div className="overview-label text-nowrap">Sportgren</div>
                    {item.d_sportgren
                      ? (item.d_sportgren.split(',').map(sg => <div>{sg}</div>))
                      : <div>-</div>
                    }
                  </div>
                  <hr className="hr" />
                  <div className="p-2 position-relative css-fix">
                    <div className="overview-label text-nowrap">Plats</div>
                    {item.plats || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Hemsida</div>
                    <a href={item.hemsida} target='_new'>{item.hemsida || '-'}</a>
                  </div>
                  <hr className="hr" />
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Kontakt</div>
                    {item.kontakt_first_name || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">E-mail</div>
                    {item.kontakt_email || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Telefon</div>
                    {item.kontakt_phone || '-'}
                  </div>
                </div>
                <div className="d-flex flex-column flex-fill mb-3 order-3 order-sm-2">
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Start datum</div>
                    {item.start_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Slut datum</div>
                    {item.end_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Öppnar för anmälan</div>
                    {item.anmalan_start_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Sista anmälningsdatum</div>
                    {item.anmalan_end_date || '-'}
                  </div>
                  <div className="p-2 position-relative">
                    <div className="overview-label text-nowrap">Sista efteranmälningsdatum</div>
                    {item.efteranmalan_end_date || '-'}
                  </div>
                </div>
                <div className="d-flex flex-column flex-fill mb-3 order-1 order-sm-3">
                  <div className="p-2 position-relative" color={item.status_color}>
                    <div className="overview-label text-nowrap">Status</div>
                    {item.status_name || '-'}
                  </div>
  
                </div>
              </div>
  
  
            </MDBAccordionItem>
  
  
  
          </>
        )
      })
    )
  }


  return (
    <>
      <MDBContainer className="py-4">    
        <MDBRow> 
          <h2>Tävlingar att godkänna</h2>
          <MDBAccordion alwaysOpen>
            {loading ? (
              <p>Laddar data...</p>
            ) : (
              <>
                {list(data)}
                {data.length==0 && (
                  <>
                    <p>Ingen tävling att godkänna!</p>
                  </>
                )}
              </>
            )}

          </MDBAccordion>
        </MDBRow>
      </MDBContainer>
    </>
  );
}