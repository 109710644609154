import { MDBContainer, MDBSpinner } from "mdb-react-ui-kit";
import { useEffect,useState } from "react";
import { fetch_get, fetch_post } from "../../../services/common";
import { useNavigate } from "react-router-dom";
import { useLoaderData } from "react-router-dom";

export async function loader({ params }) {

	return params.key_id;
}

export default function PaymentError() {

	const navigate = useNavigate();
	const order_key = useLoaderData();
	const [errorMsg, setErrorMsg] = useState("Hämtar meddelande...");

	useEffect(() => {
		if(order_key){
			fetch_get("app/pay/data/" + order_key)
				.then((data) =>
					setTimeout(() => {
						navigate(data.data.return_link);
						fetch_post("app/pay/do_submit/" + data.data.key_id, {action: "add_edit", db_status: "Aborted"});
						setErrorMsg(data.data.error_msg);
					}, 3000)
				);
		} /* else {
			setErrorMsg("Ordern har redan behandlats eller så saknas order.")
			setTimeout(() => {
				navigate("/");
			}, 3000)
		} */
	}, []);
      //
   return (
    <MDBContainer className="py-4">
        <div className='d-flex justify-content-center'>
          {errorMsg} Du skickas nu tillbaka.
        </div>
				<div className='d-flex justify-content-center'>
          <MDBSpinner role='status'>
            <span className='visually-hidden'>Loading...</span>
          </MDBSpinner>
        </div>
    </MDBContainer>
   );
}