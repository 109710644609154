import React, { useState, useRef, useEffect } from "react";
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBRipple,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

import Icon_Svsf from "../svg/svg_icon_svsf";

export default function Sidenav({ basicOpen, setBasicOpen }) {
  const [mode, setMode] = useState("side");
  const [backdrop, setBackdrop] = useState(false);
  const [basicCollapse1, setBasicCollapse1] = useState(false);
  const [basicCollapse2, setBasicCollapse2] = useState(false);
  const [basicCollapse3, setBasicCollapse3] = useState(false);

  const innerWidth = useRef(null);

  console.log('ddddddddddddddddddddddddd');

  const checkResize = () => {
    if (window.innerWidth === innerWidth.current) {
      return;
    }

    innerWidth.current = window.innerWidth;

    if (window.innerWidth < 1400) {
      setMode("over");
      setBasicOpen(false);
      setBackdrop(true);
    } else {
      setMode("side");
      setBasicOpen(true);
      setBackdrop(false);
    }
  };

  const handleBasicCollapse1 = () => {
    if (basicCollapse2 && !basicCollapse1) setBasicCollapse2(false);

    setBasicCollapse1(!basicCollapse1);
  };

  const handleBasicCollapse2 = () => {
    if (basicCollapse1 && !basicCollapse2) setBasicCollapse1(false);

    setBasicCollapse2(!basicCollapse2);
  };

  const handleBasicCollapse3 = () => {
    if (basicCollapse1 && !basicCollapse3) setBasicCollapse1(false);

    setBasicCollapse3(!basicCollapse3);
  };

  useEffect(() => {
    checkResize();

    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  return (
    <>
      <MDBSideNav
        isOpen={basicOpen}
        backdrop={backdrop}
        getOpenState={(e) => setBasicOpen(e)}
      >
        <MDBSideNavMenu>
        <div className='mt-4'>
          <div id='header-content' className='ps-3'>
              <div  className="text-center pb-4">
                <Icon_Svsf className="site-header__logo-img big-logo" />
              </div>
            <p>Administration - System</p>
          </div>
          <hr className='mb-0' />
       
        </div>          

          <MDBSideNavItem>
            <Link to="/settings" className="sidenav-link">

              <MDBIcon fas icon="home" className="fa-fw me-3" />
              xxxxx
            </Link>
            <Link to="/event/new" className="sidenav-link">

              <MDBIcon fas icon="home" className="fa-fw me-3" />
              yyyyyy
            </Link>            
          </MDBSideNavItem>
         </MDBSideNavMenu>
      </MDBSideNav>
    </>
  );
}
