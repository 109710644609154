import React, { useEffect,useState } from 'react';
import {    
  MDBSelect,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert
 } from 'mdb-react-ui-kit';
 import { AddSkyttekort } from './utils/AddSkyttekort';

export default function AddSkyttekortModal(params){

	const user = params.sentUser[0];
	const sportLoader = params.formSelects.sport;
	const sports = []
	const sportArray = (name, id, formName) => { return { text: name, value: id, name: formName}};
	const [sportsVal,setSportsVal] = useState(null)
	const [skyttekortAddedToast, setSkyttekortAddedToast] = useState(false)
	const [skyttekortErrorToast, setSkyttekortErrorToast] = useState(false)

	sportLoader.data.map(sport => {
    const selectSport = sportArray(sport.text, Number(sport.value), "sport");
    sports.push(selectSport);
  },);

	const addSkyttekort = () => {
		
		AddSkyttekort(user.key_id,sportsVal.value,user.licens_nr).then(obj => {
			if(obj.status == "OK"){
				setSkyttekortAddedToast((prev) => !prev);
			} else {
				setSkyttekortErrorToast((prev) => !prev)
			}
		});
		
	}

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={skyttekortAddedToast}
						onClose={() => setSkyttekortAddedToast(false)}
					>
						Skyttekort har lagt till för {user.first_name} {user.last_name}.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={skyttekortErrorToast}
						onClose={() => setSkyttekortErrorToast(false)}
					>
						Användaren har redan ett skyttekort.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>{user ? user.first_name + " " + user.last_name : null}</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
								<MDBModalBody>
							<MDBRow className="d-flex justify-content-between">
								<MDBCol md={5}>
									<MDBSelect
										label='Skyttekort'
										data={sports}
										name="skyttekort"										
										preventFirstSelection
										onChange={(val) => setSportsVal(val)}
									/>
								</MDBCol>
								<MDBCol md={3}>
									<MDBPopconfirm modal btnChildren='Lägg till skyttekort' onConfirm={() => addSkyttekort()}>
										<MDBPopconfirmMessage>Lägg till skyttekort till användare {user.first_name} {user.last_name}</MDBPopconfirmMessage>
									</MDBPopconfirm>
								</MDBCol>
							</MDBRow>
							</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}