import React from 'react';
import {    
  MDBContainer,
	MDBBtn,
	MDBIcon
 } from 'mdb-react-ui-kit';
import { MyDocument } from '../documents/MyDocument';
import {PDFDownloadLink} from '@react-pdf/renderer';

export default function PdfExport() {
  return (
    <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
			{({ blob, url, loading, error }) => (loading ? 'Loading document...' : <MDBBtn floating>
			<MDBIcon far icon='file-pdf'/>
		</MDBBtn>)}
		</PDFDownloadLink>
  );
}