import React, { useState, useRef, useEffect } from 'react';
import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Link, useRouteError, useLocation } from "react-router-dom";

function NotFound404() {
  const error = useRouteError();
  const location = useLocation();

  const handleRedirect = () => {
    window.location.replace("/");
  };

  useEffect(() => {
    // Kasta ett fel när komponenten mountas
    throw { code: 404, message: "Sidan hittades inte", error: 'Sida: ' + location.pathname };
  }, []);

  return (
  <MDBContainer>
    <MDBRow>
      <div className="col-12">
        <section className="my-5 text-center">
          <h1 className="display-1">404</h1>

          <h4 className="mb-4">Sidan hittades inte {/* ({data}) */}</h4>
          {console.log('error', error)}
          {error && <p className="text-danger">Error: {error.message}</p>}
          <p className="mb-0">
            Sidan du letar efter saknas eller så har något annat fel uppstått.
          </p>
          <p className="mb-4">
            Gå tillbaka eller klicka på knappen för att gå till startsidan.
          </p>
          <MDBBtn onClick={handleRedirect}>Tillbaka till startsidan</MDBBtn>
        </section>
      </div>
    </MDBRow>
  </MDBContainer>
);
  }
export default NotFound404;
