import React, { useState } from 'react';
import { 
  MDBRow,
  MDBCol,
  MDBAlert,
  MDBValidation,
  MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBCheckbox
  } from "mdb-react-ui-kit";
import { BorderCol } from '../../modules/BorderCol';
import { GetLatestStartDate, GetEndDate } from './utils/GetLicenseDates';
import { fetch_post } from '../../../services/common';
import CompareDates from './utils/CompareDates';
import PaymentTermsModal from '../payment/PaymentTermsModal';

export default function LicenseSelectDetails(params) {
	
  const user = params.user;
  const sport = params.sport;
	const selectedLicense = params.selectedLicense;
  const [formValue, setFormValue] = useState({	license: selectedLicense,
		sport: sport,
		startDate: GetLatestStartDate(user.licenser),
		endDate: specialEndDate(user.licenser),
		price: selectedLicense.amount,
		age: (new Date().getFullYear() - new Date(user.birth_date).getFullYear() > 20) ? "Senior" : "Junior",
		userID: user.id,
		name: user.first_name + " " + user.last_name,
		user_license_id: -1,
		user_key: user.key_id,
		licenseStatus: CompareDates(GetLatestStartDate(user.licenser), GetEndDate(user.licenser))}
		);
  const [showAlert,setShowAlert] = useState((user.licenser != null && user.licenser.length > 0) ?
    user.licenser.map(element => {
      if(element.status == "ACTIVE"){
        return true
      } else {
        return false
      }
    }) : false)
	const [licenseAddedToast, setLicenseAddedToast] = useState(false);
	const [licenseErrorToast, setLicenseErrorToast] = useState(false);
	const [checked,setChecked] = useState(false);
	const [showPaymentTerms, setShowPaymentTerms] = useState(false);

	var minStartDate = new Date(GetLatestStartDate(user.licenser));
	minStartDate.setDate(minStartDate.getDate() - 1);

	function specialEndDate(licenses){
		const startDate = new Date(GetLatestStartDate(licenses))
		if(startDate.getFullYear() < 2025){
			return "2025-12-31";
		} else {
			return GetEndDate(licenses)
		}
	}

  function handleSubmit(e){
    e.preventDefault();
		console.log("hej")
    if(e.target.checkValidity()){
      handleClickPay();
    } 
  };

	async function handleClickPay() {    
		const base_url = process.env.NODE_ENV === "development" ? window.location.protocol + "//" + window.location.hostname + ":" + window.location.port : window.location.protocol + "//" + window.location.hostname;
    const order = await fetch_post("app/pay/do_submit/new", {
			"action":"add_edit", 
			"db_user_id" : user.id,
			"db_operation" : "Purchase",
			"db_description" : "Licensköp",
			"db_type" : "License",
			"db_return_link" : "/user/" + user.key_id + "/license",
			"db_metadata" : JSON.stringify([formValue])});
		const payment_response = await fetch_post("app/swedbankpay/request-license/" + order.msg.key_id, {
			"url": base_url, 
			"user_agent" : window.navigator.userAgent,
			"order_items" : [{
				"type": "LICENSE",
				"product_id": formValue.license.id,
				"user_id": formValue.userID
			}]});
		const url = payment_response.data.operations.find(({rel}) => rel === "redirect-checkout").href;
		window.open(url, "_self");
  }

  return (
    <>
			<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        <MDBModalDialog>
					<MDBValidation onSubmit={handleSubmit}>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Licens - {selectedLicense.text}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
							{showAlert ? <MDBAlert
								className='d-flex justify-content-between'
								defaultOpen
								autohide 
								delay={10000}
								color='warning'
								dismissBtn
								position='bottom-center'>
								<p className='mb-0'>
								Aktiv licens finns redan för {user.first_name + " " + user.last_name}.<br/>
								Startdatum justeras efter när licenserna går ut.
								</p>
							</MDBAlert> : null}
							<MDBAlert
								color='primary'
								autohide
								position='top-right'
								delay={2000}
								appendToBody
								open={licenseAddedToast}
								onClosed={params.close}
								onClose={() => setLicenseAddedToast(false)}
							>
								Licens har lagts till för {user.first_name} {user.last_name}
							</MDBAlert>
							<MDBAlert
								color='danger'
								autohide
								position='top-right'
								delay={2000}
								appendToBody
								open={licenseErrorToast}
								onClose={() => setLicenseErrorToast(false)}
							>
								Licens kunde inte läggas till för {user.first_name} {user.last_name}
							</MDBAlert>
							<BorderCol headline="Sammanfattning" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 my-4 position-relative'>
								<h5>{selectedLicense.text}</h5>
								<MDBRow>
									<MDBCol>Giltig fr.o.m:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{formValue.startDate}</span></MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol>Giltig t.o.m:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{formValue.endDate}</span></MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol>Pris:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{formValue.price}kr</span></MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol>Åldersklass:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{formValue.age}</span></MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol>Tävlingskommité:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{sport.text}</span></MDBCol>
								</MDBRow>
							</BorderCol>
							<MDBRow>
								<MDBCol className='text-center'>
									<MDBCheckbox
										checked={checked}
										onChange={() => setChecked(!checked)}
									/>
									<p>Genom att markera kryssrutan godkänner jag Svenska Skyttesportsförbundets 
										<MDBBtn type='button' onClick={() => setShowPaymentTerms(!showPaymentTerms)} className='px-1 pt-0 pb-1' color='link'>
										<u>köpvillkor</u>
										</MDBBtn>
									</p>
								</MDBCol>
							</MDBRow>
						</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type='button' color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
							<MDBBtn disabled={!checked} type='submit'>
                Betala
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
					</MDBValidation>
        </MDBModalDialog>
      </MDBModal>
			<PaymentTermsModal show={showPaymentTerms} close={() => setShowPaymentTerms(false)}/>
    </>
  );
}