import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
  MRT_Table,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBNavbar,
  MDBTooltip,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBTable,
  MDBListGroup,
  MDBListGroupItem,
  MDBTableHead,
  MDBTableBody,
  MDBBtnGroup

} from 'mdb-react-ui-kit';
import { useParams, } from "react-router-dom";
import { fetch_get, fetch_post } from "../../../services/common";
import { EventRubrik } from "./Event_common";

export async function loader() {

  return [];
}

export default function EventProgram({ props }) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  //
  const [roundModal, setRoundModal] = useState(false);
  const toggleOpen = () => setRoundModal(!roundModal);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {

    fetch_get('app/event/rounds/' + id)
      .then(data => {
        setData(data.data.rounds);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Omgång',
        size: 50,
        enableEditing: false,
      },

    ],
    [],
  );


  const table = useMaterialReactTable({
    columns: tableColumns,
    data: data,
    //enableEditing: true,
    enablePagination: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    renderCaption: ({ table }) =>
      'Skjutprogram',
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
          fontWeight: 'bold',
          mx: 0.5,
          fontSize: 14,
          p: 1,
        },
      },
    },
    initialState: {
      density: 'compact',
    },
    enableColumnFilters: false,
    localization: MRT_Localization_SV,
    renderDetailPanel: ({ row }) => (
      <Skjutlag data={row} />
    ),
    renderTopToolbarCustomActions: () => (
      <div className="d-flex flex-row mb-3">
        <MDBBtn
          onClick={toggleOpen}
          size='sm'
          className='me-2'
        >
          <MDBIcon fas icon="plus" className='me-2' />Skapa tävlingsomgång
        </MDBBtn>

      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (

      <>
        {/* <div>
           <div>
            <MDBBtn
              onClick={handleClick}
              size='sm'
              color='secondary'
            >
              Skriv ut / Export<MDBIcon fas icon="caret-down" className='ps-1' />
            </MDBBtn>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}

            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test1 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test2 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test3 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test4 (PDF)
            </MenuItem>
          </Menu>
        </div> */}
        <MRT_ToggleFullScreenButton table={table} />
        <MDBBtn
          tag='a'
          size='sm'
          // onClick={toggleOpen}
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color='info' size='lg' />
        </MDBBtn>
      </>

    ),
  });

  return (
    <>
      <MDBContainer className="py-4">
        <EventRubrik id={id} InfoBtn={false} />
        <MDBRow className='g-3 pt-4'>
          <MaterialReactTable
            table={table}
          />
        </MDBRow>
      </MDBContainer >
      <ModalCreateRound id={id} roundModal={roundModal} setRoundModal={setRoundModal} toggleOpen={toggleOpen} setLoading={setLoading} />
    </>
  );
}

function Skjutlag(props) {

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'skjutlag', //access nested data with dot notation
        header: '#',
        size: 50,
        enableEditing: false,
      },
      {
        accessorFn: (row) => `${row.da || 'åååå-mm-dd'}`,
        header: ' ',
        size: 150,
        enableEditing: false,
      },
      {
        accessorFn: (row) => `${row.ti || 'hh:ss'}`,
        header: 'Starttider',
        size: 50,
        enableEditing: false,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: props.data.original.skjutlag,
    enableColumnActions: false,
    initialState: { density: 'compact' },
    enableColumnFilters: false,
    localization: MRT_Localization_SV,
    enableSorting: false,
    renderCaption: ({ table }) =>
      `Skjutlag ( ${table.getRowModel().rows.length} )`,
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
          fontWeight: 'bold',
          mx: 0.5,
          fontSize: 14,
          p: 1,
        },
      },
    },
  });

  return (
    <>
      <MRT_Table
        table={table}
      />
    </>
  );
}

function ModalCreateRound(props) {
  const [skjutprogram_lov, setSkjutprogramLov] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formValue, setFormValue] = useState({
    action: 'event_create_round',
    key_id: props.id,
    db_name: null,
    db_omgang: 1,
    no_of_relays: null,
    db_tavlor_per_skjutlag: null,
    db_tavel_nr_start: 1,
    db_def_skjutprogram_id: null
  });



  useEffect(() => {

    fetch_get('app/event/lov-skjutprogram/' + props.id)
      .then(data => {
        console.log('gggg', data.data);
        setSkjutprogramLov(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  const onChange = (e) => {
    console.log(e);
      setFormValue({ ...formValue, [e.target.name]: e.target.value });

  };

  const onChangeLov = (e, name) => {
    console.log(e, name);
      setFormValue({ ...formValue, [name]: e.value });

  };  

  const onSubmitEvent = async (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      props.setRoundModal(false);
      console.log('save', formValue);
      const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, formValue);
      props.setLoading(true);
    } else {
      console.log('form not valid');
    }
  };


  return (
    <>
      <MDBModal open={props.roundModal} staticBackdrop setopen={props.setRoundModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBValidation onSubmit={onSubmitEvent}>
              <MDBModalHeader>
                <MDBModalTitle>Skapa tävlingsomgång

                </MDBModalTitle>
                <MDBBtn className='btn-close' type='reset' color='none' onClick={props.toggleOpen}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>

                <MDBRow>
                  <MDBValidationItem className='py-2 col-8' invalid feedback='Ange omgångens namn.'>
                    {props.roundModal && (
                      <MDBInput
                        name='db_name'
                        id='form_db_name'
                        label={<>Omgångsnamn</>}
                        type='text'
                        required
                        value={formValue.db_name}
                        onChange={onChange}
                      >

                        <MDBBadge pill className='position-absolute top-0 start-100  translate-middle bg-info' title="Hi! I'm a tooltip555555555!">
                          <MDBTooltip tag='a' title="Tester av tooltip!">
                            i
                          </MDBTooltip>
                        </MDBBadge>

                      </MDBInput>

                    )}
                  </MDBValidationItem>
                  <MDBValidationItem className='py-2 col-4' invalid feedback='Ange omgångsnr.'>
                    {props.roundModal && (
                      <MDBInput
                        name='db_omgang'
                        id='form_db_omgang'
                        label={<>Omgångsnr</>}
                        type='number'
                        required
                        value={formValue.db_omgang}
                        onChange={onChange}
                      >

                        <MDBBadge pill className='position-absolute top-0 start-100  translate-middle bg-info'>
                          <MDBTooltip tag='a' title="Tester av tooltip!">
                            i
                          </MDBTooltip>
                        </MDBBadge>

                      </MDBInput>
                    )}
                  </MDBValidationItem>
                  <MDBValidationItem className='py-2 col-6' invalid feedback='Ange antal skjutlag.'>
                    {props.roundModal && (
                      <MDBInput
                        name='no_of_relays'
                        id='form_no_of_relays'
                        label={<>Antal skjutlag</>}
                        type='number'
                        required
                        value={formValue.no_of_relays}
                        onChange={onChange}
                      >
                        <MDBIcon color='info' className='position-absolute top-0 start-100  translate-middle' fas icon="info-circle" />

                      </MDBInput>
                    )}
                  </MDBValidationItem>
                  <MDBValidationItem className='py-2 col-6' invalid feedback='Ange antal tavlor/skjutlag.'>
                    {props.roundModal && (
                      <MDBInput
                        name='db_tavlor_per_skjutlag'
                        id='form_db_tavlor_per_skjutlag'
                        label={<>Antal tavlor/skjutlag</>}
                        type='number'
                        required
                        value={formValue.db_tavlor_per_skjutlag}
                        onChange={onChange}
                      />
                    )}
                  </MDBValidationItem>
                  <MDBValidationItem className='py-2 col-6' invalid feedback='Ange antal tavlor/skjutlag.'>
                    {props.roundModal && (
                      <MDBInput
                        name='db_tavel_nr_start'
                        id='form_db_tavel_nr_start'
                        label={<>Tavelnummer start</>}
                        type='number'
                        required
                        value={formValue.db_tavel_nr_start}
                        onChange={onChange}
                      />
                    )}
                  </MDBValidationItem>
                  <MDBValidationItem className='py-2 col-6' invalid feedback='Ange antal tavlor/skjutlag.'>
                  <MDBSelect
                    // size='sm'
                    data={skjutprogram_lov}
                    preventFirstSelection
                    name='db_def_skjutprogram_id'
                    id='form_def_skjutprogram_id'                    
                    onValueChange={(e) => onChangeLov(e, 'db_def_skjutprogram_id')}
                    //onChange={onChange}
                    // onValueChange={(e) => onKlassChanged(row.id, e.value)}
                  />
                  </MDBValidationItem>


                </MDBRow>


              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color='secondary' type='reset' onClick={props.toggleOpen}>
                  Avbryt
                </MDBBtn>
                <MDBBtn type='submit'>Skapa</MDBBtn>
              </MDBModalFooter>
            </MDBValidation>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}