import React, { useState, useEffect, useRef } from 'react';
import {    
  MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBDatatable,
	MDBRow,
	MDBCol,
	MDBInputGroup
 } from 'mdb-react-ui-kit';
 import * as XLSX from 'xlsx';

export default function EventResultImportModal(params){

	const inputFile = useRef(null);
	const [data, setData] = useState(null);

	const onFileChange = (e) => {

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { header: 1 });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      setData(sheetData);
    };

    reader.readAsArrayBuffer(file);

  };

	useEffect(() => {
    if (data && data[0]) {
			const columns = []
			Object.keys(data[0]).forEach(key => {
				columns.push(key)
			})
			console.log(columns)
    }
		
  }, [data]);

  return <>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
						<MDBModalDialog size="xl">
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>Importera resultat</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
									<MDBModalBody>
										<MDBRow>
											<MDBCol size="6">
											<MDBInputGroup className='mb-3' onChange={onFileChange} col="3" label='Default file input example'>
												<input className="form-control form-control-undefined" type="file" id='customFile' ref={inputFile} />
											</MDBInputGroup>
											</MDBCol>
										</MDBRow>
									</MDBModalBody>
									<MDBModalFooter>
									<MDBBtn color='secondary' onClick={params.close}>
										Stäng
									</MDBBtn>
								</MDBModalFooter>
							</MDBModalContent>
						</MDBModalDialog>
					</MDBModal>
					</>
  
}