import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import { MDBTreeTable, MDBTreeTableItem, MDBTreeTableHead, MDBTreeTableBody } from 'mdb-react-treetable';
import { useMDBCookies } from 'mdb-react-cookies-management';
import {
  MDBContainer,
  MDBTableBody,
  MDBTable,
  MDBDatatable,
  MDBTabsLink,
  MDBTabs,
  MDBTabsItem,
  MDBTabsContent,
  MDBTabsPane,
  MDBSelect,
  MDBCol,
  MDBRow,
  MDBBadge,
  MDBBtn,
  MDBIcon,
  MDBCollapse,
  MDBAccordion,
  MDBAccordionItem,
  MDBListGroup,
  MDBListGroupItem,
  MDBNavbar,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle
} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,

  Form,
  redirect,
  useParams
  //useNavigation,
  //useSubmit,
} from "react-router-dom";
import { fetch_get, fetch_post } from "../../../services/common";
import { BorderCol } from '../../modules/BorderCol';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ExpandAllButton,
  MRT_ExpandButton,
} from 'material-react-table';
import { Box, Stack } from '@mui/material';




export default function EventResultat(props) {
  const { id } = useParams();
  //const loadObject = useLoaderData();
  const [data, setData] = useState([]);
  const [basicActive, setBasicActive] = useState('tab1');
  const [loading, setLoading] = useState(true);
  const [compeditorsData, setCompeditorsData] = useState([]);
  const [groupedColumnMode, setGroupedColumnMode] = useState('reorder'); //default is 'reorder
  const [next_version] = useMDBCookies('show_next_version', '');

  console.log('next_version', next_version);
  // const [data, setProps] = useState(data_arr);

  useEffect(() => {
    fetch_get('app/event/resultat/' + id)
      .then(data => {
        setCompeditorsData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, []);

  const tableColumns = useMemo(
    () => [
       {
        accessorKey: 'omgang_name', //access nested data with dot notation
        header: 'Omgång',
        size: 150,
      },
      // {
      //   accessorKey: 'skjutlag', //access nested data with dot notation
      //   header: 'Skjutlag',
      //   size: 150,
      // },
      // {
      //   accessorKey: 'tavla', //access nested data with dot notation
      //   header: 'Tavla',
      //   size: 150,
      // },
      {
        accessorKey: 'full_name', //access nested data with dot notation
        header: 'Namn',
        size: 150,
      },
      {
        accessorKey: 'forening_name', //access nested data with dot notation
        header: 'Förening',
        size: 150,
      },
      {
        accessorKey: 'klass_name_display', //access nested data with dot notation
        header: 'Klass',
        size: 150,
      },
      {
        accessorKey: 'serie_dec_json', //access nested data with dot notation
        header: 'Serier',
        size: 150,
      }, 
      {
        accessorKey: 'total_dec', //access nested data with dot notation
        header: 'Total',
        size: 150,
      },      


    ],
    [compeditorsData],
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: compeditorsData,
    displayColumnDefOptions: {
      'mrt-row-expand': {
        // Cell: ({ row, table, cell }) => {
        //   const { grouping } = table.getState();
        //   return (
        //     <>
        //       <MRT_ExpandButton row={row} table={table} />
        //       {console.log('cell', cell)}
        //       {console.log('row', row)}
        //       {row.getValue('klass_name_display') + ' - ' + row.getValue(grouping[grouping.length - 1])}
        //     </>
        //   );
        // },        
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        GroupedCell: ({ row, table }) => {
          const { grouping } = table.getState();
          return row.getValue(grouping[grouping.length - 1]);
        },
        enableResizing: true,
        muiTableBodyCellProps: ({ row }) => ({
          sx: (theme) => ({
            color:
              row.depth === 0
                ? theme.palette.primary.main
                : row.depth === 1
                  ? theme.palette.secondary.main
                  : undefined,
          }),
        }),
        size: 100,
      },
    },    
    //enableEditing: false,
    //editDisplayMode: 'cell',
    // enableGrouping: true,
    enablePagination: false,
    enableGrouping: true,
    groupedColumnMode: 'remove',
    localization: MRT_Localization_SV,
    initialState: {
      density: 'compact',
       //expanded: true, //expand all groups by default
       grouping: ['omgang_name', 'klass_name_display'], //an array of columns to group by by default (can be multiple)
       sorting: [{ id: 'klass_name_display', desc: false, },{ id: 'tavla', desc: false, },{ id: 'total_dec', desc: true, },],
      //columnFilters: [{ id: 'omgang_name', value: 'Grund', }],
       
       //sorting: [{ id: 'omgang_name', desc: true }],

    },

  });


  return (
    <>

      {loading ? (
        <p>Laddar data...</p>
      ) : (

        <MDBContainer className="py-4">
          <MaterialReactTable
            table={table}
          />

        </MDBContainer >
      )}
    </>
  );
}



