import React, { useState} from 'react';
import { useLocation } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBBtn, MDBNavbar } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import Footer from "../modules/Footer";
import Icon_Svsf from "../svg/svg_icon_svsf";

function ErrorPage() {
  const location = useLocation();
  const error = location.state?.error;
  const logId = location.state?.logId;
  const [navcolor, setNavColor] = useState("primary");
  
  // Exempel på throw från en komponent:
  // throw { code: 333, message: "Error fetching data", error: error };


  return (
    <>

<header>
      <MDBNavbar expand='lg' className={"bg-" + navcolor }>
        <MDBContainer>
          {/* <MDBNavbarBrand href='#'><Icon_Svsf className="site-header__logo-img big-logo" />Skytte-Ta</MDBNavbarBrand> */}
          <Link className="navbar-brand text-light" as={Link} to="/"><Icon_Svsf className="site-header__logo-img big-logo " /><h2>Skytte TA</h2></Link>

        </MDBContainer>
      </MDBNavbar>

    </header>

    <MDBContainer>
      <MDBRow>
        <div className="col-12">
          <section className="my-5 text-center">
            <h1 className="display-1">Något gick fel</h1>
            {error && (
              <div>
                {typeof error === 'string' ? (
                  <p className="text-danger">Error message: {error}</p>
                ) : (
                  <>
                    <p className="text-danger">Felkod: {error.code}</p>
                    <p className="text-danger">Felmeddelande: {error.message}</p>
                    {error.error && <p className="text-danger">Detaljer: {error.error}</p>}
                  </>
                )}
              </div>
            )}
            {logId && <p className="text-info">Log ID: {logId}</p>}
            <p className="mb-4">
              Gå tillbaka eller klicka på knappen för att gå till startsidan.
            </p>
            <Link to="/">
              <MDBBtn>Tillbaka till startsidan</MDBBtn>
            </Link>
          </section>
        </div>
      </MDBRow>
    </MDBContainer>
    <Footer />
    </>
  );
}

export default ErrorPage;
