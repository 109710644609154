import { useLoaderData } from "react-router-dom";
import React,{ useState, useMemo, useEffect } from 'react';
import {    
	MDBCol,
	MDBRow,
	MDBContainer
 } from 'mdb-react-ui-kit';
 import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";

export async function loader({ params }) {
	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	const obj = {
		user: await fetch_get("app/user/all/" + params.key_id),
		guest: decoded.kid == params.key_id ? false : true
	}
		return obj;
}

export default function UserRoles(){

	const loadObject = useLoaderData();
  const [roles, setRoles] = useState([])

	
	useEffect(() => {

		setRoles(loadObject.user.data.roles.filter(role => {
			if(role.id !== "100"){
				return role
			}
		}))

  }, []);

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'description',
        header: 'Namn',
        size: 150,
				
      },
      {
        accessorKey: 'name',
        header: 'Avser',
        size: 150,
				Cell: ({ row }) => {
					if(row.original.forening_name){
						return row.original.forening_name
					}
					if(row.original.distrikt_name){
						return row.original.distrikt_name
					}
					if(row.original.gren_name){
						return row.original.gren_name
					} else return null
				},
      },
			{
        accessorKey: 'allow_edit',
        header: 'System',
        size: 50,
				Cell: ({ cell }) => (
					<>{cell.getValue() === "TRUE" ? "SkytteTA" : "IdrottOnline"}</>
						
				)
      },
    ],
    [roles],
  );

	const table = useMaterialReactTable({
    columns: tableColumns,
    data: roles,
    enableColumnFilters: false,
		enableColumnActions: false,
		enableTopToolbar: false,
		localization:MRT_Localization_SV
  });

	return (
		<>
			<MDBContainer className="py-4">
				<h3>Roller</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="12" className="mb-3">
						<BorderCol headline="Mina roller" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
						<MaterialReactTable table={table} />
						</BorderCol>
					</MDBCol>
					
				</MDBRow>
			</MDBContainer>
		</>
          );

}