import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBNavbar,
  MDBTooltip,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle

} from 'mdb-react-ui-kit';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
  MRT_Table,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import {
  Box
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";
import { extExcelExport } from '../../documents/ExcelExport';
import { BorderCol } from '../../modules/BorderCol';
import { EventRubrik } from "./Event_common";
import { ModalAddDeltagare } from "./EventEditDeltagare_ModalAdd";

export default function EventEditDeltagare({ params }) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [data_all, setDataAll] = useState([]);
  const [data2, setData2] = useState([]);
  const [current_forening, setForening] = useState(null);
  const [test, setTest] = useState("3");
  const [templov, setTempLov] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [klass_list, setKlassList] = useState(null);
  const [lov_foreningar, setDataForeningar] = useState([]);

  const [lov_klasser, setDataLovKlasser] = useState([]);
  const [lov_betalningstyper, setDataLovBetalningsTyper] = useState([]);
  const [medlemmar, setDataMedlemmar] = useState({
    columns: [],
    rows: []
  });
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [label, setLabel] = useState({ rubrik: "Tävling" });

  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  let formObjekt = {};

  useEffect(() => {

    fetch_get('app/event/all/' + id)
      .then(data => {
        const data_start = data.data.deltagare;
        const data_tmp = [];
        let data_out = [];

        if (1 === 2 && current_forening) {
          console.log('current_forening', current_forening);
          data_start.map(o => (o.forening_id === current_forening ? data_tmp.push(o) : o));
          //data_out = data_tmp;
          console.log('data_tmp', data_tmp);
          data_out = data_tmp;
        } else {
          data_out = data_start;
        }


        const data_lov_klasser = data.data.lov_klasser;
        data_lov_klasser.unshift({ "value": " ", "text": "-" });


        data_out.map((k_item, k) => {
          //formObjekt["klass" + k_item.id] = data_lov_klasser.map(o => (o.value == k_item.event_klass_id ? { ...o, defaultSelected: true } : o));
          formObjekt["prispengar" + k_item.id] = k_item.prispengar || 0;
        });

        setFormValue(formObjekt);


        /*
      const deltagare = data_out.map((row) => {

        row.lov_k = data_lov_klasser.map(o => (o.value === row.event_klass_id ? { ...o, defaultSelected: true } : o));
        console.log('row', row);

        return {
          ...row,
          delete_button: (
            <MDBPopconfirm
              outline
              size='sm'
              floating
              
              color="danger"
              modal
              btnChildren={<MDBIcon far icon="trash-alt" color="danger" size='sm' />}
              cancelBtnText='Avbryt'
              onConfirm={() => ondeleteDeltagare(row.id)}
              cancelBtnClasses='btn-secondary'
              //btnClassName='btn-link btn-sm'
              title={"Ta bort deltagare"}
            >
              <MDBPopconfirmMessage
                icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
                size='lg'
              >
                Ta bort deltagare: <br /><br />{row.full_name}<br />{row.forening_name}?
              </MDBPopconfirmMessage>
            </MDBPopconfirm>

          ),
          select_klass: (
            <>
              <MDBSelect
                size='sm'
                data={row.lov_k}
                preventFirstSelection
                onValueChange={(e) => onKlassChanged(row.id, e.value)}
              />




            </>
          ),
          pris_pengar: (
            <>
              <MDBInput
                id={'id_prispengar' + row.id}
                name={'prispengar' + row.id}
                type='text'
                size='sm'
                value={formValue['prispengar' + row.id]}
                onChange={onChange}
              />
            </>
          ),
        };
      });
*/
        setData2(data_start);
        setData(data_out);
        setDataLovKlasser(data_lov_klasser);
        setDataLovBetalningsTyper(data.data.lov_betalningstyper);
        setDataAll(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });



  }, [loading, current_forening]);

  const lov_team_nr = [{ text: '1' }, { text: '2' }, { text: '3' }, { text: '4' }, { text: '5' }, { text: '6' }, { text: '7' }, { text: '8' }, { text: '9' }];

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'licens_nr', //access nested data with dot notation
        header: 'Licensnr',
        size: 100,
        enableEditing: false,
        enableColumnFilter: true,
        // filterFn: 'equals',
        Cell: ({ cell, row }) => (
          <>
            <span className="d-inline-flex bd-highlight flex-column  flex-fill px-3">
              <span >{row.original.licens_nr} {!row.original.participant_ok.participant_ok && <>
                <MDBIcon title={row.original.participant_ok.text} color='warning' fas icon="exclamation-triangle" /></>}</span>
            </span>
          </>
        ),




      },
      {
        accessorKey: 'forening_name', //access nested data with dot notation
        header: 'Förening',
        size: 150,
        enableEditing: false,
        filterVariant: 'select',
      },

      {
        accessorKey: 'full_name', //access nested data with dot notation
        header: 'Namn',
        size: 150,
        enableEditing: false,
      },
      {
        accessorKey: 'event_klass_id', //access nested data with dot notation
        header: 'Klass',
        Cell: ({ cell, row }) => (
          <>
            {row.original.klass_name}
          </>
        ),
        size: 50,
        enableEditing: true,
        filterVariant: 'select',
        editVariant: 'select',
        filterSelectOptions: lov_klasser,
        editSelectOptions: lov_klasser,
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          // error: !!validationErrors?.state,
          // helperText: validationErrors?.state,
          // onChange: (event) => console.log('event', event.target.value, row.original.id)
          onChange: (event) => onKlassChanged(row.original.id, event.target.value)
          ,
        }),

      },
      {
        accessorKey: 'betald_typ', //access nested data with dot notation
        header: 'Betald',
        Cell: ({ cell, row }) => (
          <>
            {row.original.betalnings_metod}
          </>
        ),
        size: 50,
        enableEditing: true,
        filterVariant: 'select',
        editVariant: 'select',
        filterSelectOptions: lov_betalningstyper,
        editSelectOptions: lov_betalningstyper,
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          // error: !!validationErrors?.state,
          // helperText: validationErrors?.state,
          // onChange: (event) => console.log('event', event.target.value, row.original.id)
          onChange: (event) => {
            onBetaldChanged(row.original.id, event.target.value, row.original.belopp_betald_startavgift || row.original.startavgift);

          }
          ,
        }),

      },
      {
        accessorKey: 'belopp_betald_startavgift', //access nested data with dot notation
        header: 'Startavgift',
        // Cell: ({ cell, row }) => (
        //   <>
        //     <div style={{ textAlign: "center" }}>{cell}</div>
        //   </>
        // ),        
        size: 50,
        enableEditing: true,
        muiEditTextFieldProps: ({ row, column }) => ({
          // required: true,
          type: 'number',
          onBlur: (event) => {
            row._valuesCache[column.id] = 999;
            console.log('row', row);
            //row._valuesCache[column.id] = 11;
            const val = onStartavgiftChanged(row.original.id, event.target.value);

            // updateData(event.currentTarget.value, row, column)
          }
        }),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        style: {
          textAlign: 'right'
        }

      },
      // 
      // {
      //   accessorKey: 'pris_pengar', //access nested data with dot notation
      //   header: 'Prispengar',
      //   size: 50,
      //   enableEditing: false,
      // },

    ],
    [lov_klasser, data],
  );


  const table = useMaterialReactTable({
    columns: tableColumns,
    data: data,
    enableEditing: true,
    // editDisplayMode: 'cell',
    editDisplayMode: 'table',
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: true,
    enableGlobalFilter: true,
    enableStickyHeader: true,
    enableFacetedValues: true,
    enableBottomToolbar: false,
    columnFilterDisplayMode: 'popover',
    muiTableContainerProps: { sx: { maxHeight: '600px' } },
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      //columnFilters:[{ id: 'event_team_config_id', value: null }],
      //columnVisibility: { event_team_config_id: false },
      //sorting: [{ id: 'team_config_name' }, { id: 'team_config_name' }],
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    enableColumnFilters: true,
    localization: MRT_Localization_SV,
    // renderTopToolbar: ({ table }) => {
    //   return (
    //     <Box
    //       sx={{
    //         padding: '16px',
    //         display: 'flex',
    //         gap: '0.5rem',
    //         p: '8px',
    //         justifyContent: 'space-between',
    //       }}
    //     >
    //       <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
    //         <MRT_GlobalFilterTextField table={table} />
    //         <MRT_ToggleFiltersButton table={table} />
    //       </Box>
    //     </Box>

    //   );
    // },
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex flex-row mb-3">
        <MDBBtn
          onClick={() => setBasicModal(true)}
          size='sm'
          className='me-2'
        >
          <MDBIcon fas icon="plus" className='me-2' />Lägg deltagare
        </MDBBtn>

      </div>

    ),
    renderToolbarInternalActions: ({ table }) => (

      <>
        <div>
          <div>
            <MDBBtn
              onClick={handleClick}
              size='sm'
              color='secondary'
            >
              Skriv ut / Export<MDBIcon fas icon="caret-down" className='ps-1' />
            </MDBBtn>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}

            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test1 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test2 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test3 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test4 (PDF)
            </MenuItem>
          </Menu>
        </div>
        <MRT_ToggleFullScreenButton table={table} />
        <MDBBtn
          tag='a'
          size='sm'
          // onClick={toggleOpen}
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color='info' size='lg' />
        </MDBBtn>
      </>

    ),
    renderRowActions: ({ row }) => (
      <>

        <MDBPopconfirm
          // outline
          tag='a'
          // size='sm'
          floating
          className='ripple ripple-surface'
          color="none"
          modal
          btnChildren={<MDBIcon far icon="trash-alt" color="danger" />}
          cancelBtnText='Avbryt'
          onConfirm={() => ondeleteDeltagare(row.original.id)}
        >
          <MDBPopconfirmMessage
            icon={<MDBIcon className='me-2' far icon="trash-alt" />}

          >
            Ta bort deltagare: <br /><br />{row.original.full_name}<br />{row.original.forening_name}?
          </MDBPopconfirmMessage>
        </MDBPopconfirm>
      </>
    ),
    muiTableBodyCellProps: ({ cell, column, table, row }) => ({
      onClick: () => {
        setCellColor(cell, column.id, row.original)
        table.setEditingCell(cell);
        queueMicrotask(() => {
          const textField = table.refs.editInputRefs.current[column.id];
          if (textField) {
            textField.focus();
            textField.select?.();
          }
        });
      },
      onKeyUp: (event) => {
        console.log(event.key)
      },
      sx: {
        borderRadius: '0',
        border: setCellColor(cell, column.id, row.original, 'border'),
        backgroundColor: setCellColor(cell, column.id, row.original, 'backgroundColor')
      },
    }),
  });

  function setCellColor(cell, col, row, type) {
    if (type == 'border') {
      if (cell.getValue() > 0 && col.startsWith('event_klass_id')) {
        return '1px dashed #000'
      }
      if (!(cell.getValue() > 0) && col.startsWith('event_klass_id')) {
        return '1px solid #000'
      }
      if (cell.getValue() > 0 && col.startsWith('betald_typ')) {
        return '1px dashed #000'
      }
      if (!(cell.getValue() > 0) && col.startsWith('betald_typ')) {
        //console.log('row',row);
        return '1px solid #000'
      }
    }
    if (type == 'backgroundColor') {

      if (cell.getValue() > 0 && col.startsWith('event_klass_id')) {
        return '#e3f5e1'
      }
      if (!(cell.getValue() > 0) && col.startsWith('event_klass_id')) {
        return '#ffb3b3'
      }
      if (cell.getValue() > 0 && col.startsWith('betald_typ')) {
        return '#e3f5e1'
      }
      if (!(cell.getValue() > 0) && col.startsWith('betald_typ')) {
        return '#ffb3b3'
      }
      if (row.betald_typ > 0 && col.startsWith('belopp_betald_startavgift')) {
        return '#e3f5e1'
      }
      if (!(row.betald_typ > 0) && col.startsWith('belopp_betald_startavgift')) {
        return '#ffb3b3'
      }
    }
    return ''
  }


  const onadddeltagare = async (user_key_id, forening_id) => {

    console.log('onadddeltagare', user_key_id, forening_id);
    const form_params = {
      "action": "event_add_deltagare",
      "admin": true,
      "user_key_id": user_key_id,
      "db_forening_id": forening_id
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onBlur_prispengar = async (deltagar_id, e) => {

    console.log('onBlur_prispengar', e.target.value, e);
    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_prispengar": e.target.value
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onChange = async (e) => {

    console.log('onchange', e.target.name, e.target.value);
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const onTeamChanged = async (deltagar_id, lov_id) => {

    // setTest(klass_id);

    console.log('onTeamChanged', deltagar_id, lov_id, data);

    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_event_team_id": lov_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onTeamNrChanged = async (deltagar_id, lov_id) => {

    // setTest(klass_id);

    console.log('onTeamNrChanged', deltagar_id, lov_id);

    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_event_team_id_nr": lov_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onKlassChanged = async (deltagar_id, klass_id) => {

    // setTest(klass_id);

    console.log('onKlassChanged', deltagar_id, klass_id);

    const form_params = {
      "action": "event_set_deltagar_klass",
      "deltagar_id": deltagar_id,
      "db_event_klass_id": klass_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onBetaldChanged = async (deltagar_id, typ, betald_startavgift) => {


    console.log('onBetaldChanged', deltagar_id, typ, betald_startavgift);

    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_betald_typ": typ,
      "db_belopp_betald_startavgift": betald_startavgift
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onStartavgiftChanged = async (deltagar_id, betald_startavgift) => {


    console.log('onStartavgiftChanged', deltagar_id, betald_startavgift);

    const form_params = {
      "action": "event_set_deltagar_data",
      "deltagar_id": deltagar_id,
      "db_belopp_betald_startavgift": betald_startavgift
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };


  const ondeleteDeltagare = async (deltagar_id) => {

    console.log('ondeleteDeltagare', id);
    const form_params = {
      "action": "event_delete_deltagare",
      "deltagar_id": deltagar_id
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const changeChecked = () => {
    let curr_checked = !checked;
    setChecked(curr_checked);
    console.log('current_forening', current_forening);
    changeForening({ value: current_forening }, curr_checked);

  }

  const changeForening = (val, checked_in) => {
    console.log(val);

    setForening(val.value);

    fetch_get('app/forening/user-list-event/' + val.value + '/' + id)
      .then(data => {
        const medl_tmp = data.data.map((row) => {
          return {
            ...row,
            add_button: (
              <>

                {row.name} {!row.participant_ok.participant_ok && <>
                  <MDBIcon title={row.participant_ok.text} color='warning' fas icon="exclamation-triangle" /></>}
                <MDBBtn
                  outline size='sm'
                  floating
                  disabled={false} // Om vi vill låsa så att de utan lic/skyttekort inte ska kunna läggas till i startlistan, använd denna {!row.participant_ok}
                  className='call-btn me-4 float-end'
                  onClick={() => onadddeltagare(row.key_id, row.forening_id)}
                >
                  <MDBIcon fas icon="angle-right" />
                </MDBBtn>
              </>
            ),
            test: (
              <>
                <MDBInput label='Example label' id='form1' type='text' size='sm' />
              </>
            ),
          };
        });

        let tmp;
        if (!checked_in) {
          tmp = medl_tmp.filter(e => e.participant_ok.participant_ok);
        } else {
          tmp = medl_tmp;
        }

        const medl = tmp;

        setDataMedlemmar({
          columns: [
            { label: '', field: 'add_button', sort: false },
          ],
          rows: medl,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  }

  const exportToJson = e => {
    e.preventDefault()
    downloadFile({
      data: JSON.stringify(data2),
      fileName: 'Deltagare' + id + '.json',
      fileType: 'text/json',
    })
  }

  const exportToCsv = e => {
    e.preventDefault()

    // Headers for each column
    let headers = ['Id,Name']

    // Convert users data to a csv
    let usersCsv = data2.reduce((acc, user) => {
      const { id, full_name } = user
      acc.push([id, full_name].join(','))
      return acc
    }, [])

    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: 'Deltagare.csv',
      fileType: 'text/csv',
    })
  }

  const columns = ["Namn", "Efternamn"]

  return (
    <>
      <MDBContainer className="py-4">

        <EventRubrik id={id} InfoBtn={false} />
        {/* <MDBRow className='g-3 py-3'> */}
        {/* <MDBCol className='col-md-3 col-sm-12'>

            <AddDeltagare
              changeForening={changeForening}
              changeChecked={changeChecked}
              checked={checked}
              medlemmar={medlemmar}
              onadddeltagare={onadddeltagare}
            />
          </MDBCol> */}
        <MDBRow className='g-3 pt-4'>

          <MaterialReactTable
            table={table}
          />
        </MDBRow>
        {/* </MDBRow> */}
      </MDBContainer >

      <ModalAddDeltagare
        basicModal={basicModal}
        setBasicModal={setBasicModal}
        toggleOpen={toggleOpen}
        setLoading={setLoading}
      />

    </>
  );
}


const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType })
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

function AddDeltagare({ changeForening, changeChecked, checked, medlemmar, onadddeltagare }) {
  const { id } = useParams();
  const [lov_foreningar, setDataForeningar] = useState([]);

  useEffect(() => {
    fetch_get('app/list/lov-foreningar')
      .then(data => {
        setDataForeningar(data.data);
        // setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // setLoading(false);
      });
  }, [])

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Namn',
        size: 100,
        enableEditing: false,
        filterFn: 'equals',
        Cell: ({ cell, row }) => (
          <>
            <span className="d-inline-flex bd-highlight flex-column  flex-fill px-3">
              <span className="fw-bolder">{row.original.name} {!row.original.participant_ok.participant_ok && <>
                <MDBIcon title={row.original.participant_ok.text} color='warning' fas icon="exclamation-triangle" /></>}</span>
            </span>

          </>
        ),
      },

    ],
    [],
  );


  const table = useMaterialReactTable({
    columns: tableColumns,
    data: medlemmar.rows,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: true,
    enableStickyHeader: true,
    initialState: {
      density: 'compact',
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    enableColumnFilters: false,
    enableTableFooter: false,
    enableTableHead: false,
    enableBottomToolbar: false,
    localization: { noRecordsToDisplay: 'Välj en förening...', },
    muiTableContainerProps: { sx: { maxHeight: '500px' } },
    renderRowActions: ({ row }) => (
      <>
        <MDBTooltip

          wrapperProps={{
            tag: 'a',
            size: 'sm',
            // className: 'call-btn me-2 mb-2',
            onClick: () => onadddeltagare(row.original.key_id, row.original.forening_id)
          }}
          title="Lägg till deltagare"
        >
          <MDBIcon fas icon='angle-double-right' />
        </MDBTooltip>
      </>
    ),
    renderTopToolbarCustomActions: () => (
      <div className="d-flex flex-column mb-3">

        <MDBSelect
          label="Välj förening"
          data={lov_foreningar}
          search
          preventFirstSelection
          clearBtn
          visibleOptions={8}
          searchLabel='Sök förening'
          onValueChange={(e) => changeForening(e, checked)}
        />
        <div className='mt-4'>
          <MDBCheckbox

            name='flexCheck'
            value=''
            id='flexCheckDefault'
            label='Visa alla medlemmar till föreningen'
            checked={checked}
            onChange={() => changeChecked()}
          />
        </div>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (

      <>
        <MDBBtn
          tag='a'
          size='sm'
          // onClick={toggleOpen}
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color='info' size='lg' />
        </MDBBtn>
      </>

    ),

  });

  return (
    <>
      <MaterialReactTable
        table={table}
      />


    </>
  );
}

