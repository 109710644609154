import React, { useState, useRef, useEffect } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import { useMDBCookies } from 'mdb-react-cookies-management';
import {
  MDBContainer,
  MDBTableBody,
  MDBTable,
  MDBDatatable,
  MDBTabsLink,
  MDBTabs,
  MDBTabsItem,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBIcon,
  MDBBadge,
  MDBCardBody,
  MDBNavbar,
  MDBBtn,
  MDBAlert,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBFile,
  MDBInputGroup,
  MDBSelect,
  MDBInput
} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  useParams,
  Form,
  redirect,
  //useNavigation,
  //useSubmit,
} from "react-router-dom";
import { fetch_get, fetch_post, fetch_post_document } from "../../../services/common";



export const loader = async ({ params }) => {
  const resJson = await fetch_get('app/event/all/' + params.id);
  return resJson;
  //return [];
};


const mainStyles = {
  rubriker: {
    height: 20,
    position: 'absolute',
    top: '-10px',

  },
  label: {
    fontsize: '0.8rem',
    height: 30,
  }
};

export default function EventsPage() {
  const { id } = useParams();
  const loadObject = useLoaderData();
  const [data, setData] = useState(loadObject);
  //const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [basicActive, setBasicActive] = useState('tab0');
  const [label, setLabel] = useState({ rubrik: "Tävling" });

  useEffect(() => {



    fetch_get('app/event/all/' + id)
      .then(data => {
        console.log('data', data)
        setData(data);
        setLoading(false);
        console.log('data.event_type_id', data.data.event_type_id);
        if (data.data.event_type_id == '-1') {
          console.log('Evenemang');
          setLabel({ rubrik: "Evenemang" });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  // *****************************


  const onsend_for_approval = async () => {

    console.log('onsend_for_approval');
    const form_params = {
      "action": "event_change_status",
      "db_status_id": 100
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  return (
    <>


      <MDBContainer className="py-4">

        <h1 className="fs-1">{label.rubrik} - {data.data.name} <span className="fs-4">({data.data.start_date})</span></h1>

        <EventTab data_arr={data} label={label} />

      </MDBContainer>

    </>
  );
}


function EventTab(data_arr) {
  const [basicActive, setBasicActive] = useState('tab0');
  const [data, setProps] = useState(data_arr);
  const [next_version] = useMDBCookies('show_next_version', '');

  useEffect(() => {

    setProps(data_arr);
  }, [data_arr]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>

      <Dokument doc_arr={data.data_arr.data.documents} />
    </>
  );
}





function Dokument(doc_arr) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [displayname, setDisplayName] = useState(null);
  const [file_type, setFile_type] = useState("1");
  const [file_accept, setFile_accept] = useState(null);
  const [types, setLovTypes] = useState([]);
  const { id } = useParams();

  // Ref object to reference the input element
  const inputFile = useRef(null);

  // Function to reset the input element
  const handleReset = () => {
    console.log('reset', inputFile.current, inputFile);
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
    }
    setFile(null);
    setDisplayName("");
  };

  useEffect(() => {



    fetch_get('app/event/documents/' + id)
      .then(data => {
        console.log('data_documents', data.data)
        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  useEffect(() => {

    fetch_get('app/list/lov-document-types')
      .then(data => {
        setLovTypes(data.data);
        setFile_accept(data.data[0].file_types);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });

  }, []);

  const ondeleteDocument = async (doc_id) => {

    console.log('ondeleteDocument', doc_id, id);
    const form_params = {
      "action": "event_delete_document",
      "doc_id": doc_id
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onFileChange = async (event) => {
    //if (!displayname) {
    //let filename = event.target.files[0].name;
    setDisplayName(event.target.files[0].name);
    //}
    setFile(event.target.files[0]);

  };

  const onChangeDisplayName = (e) => {
    console.log('sss');
    setDisplayName(e.target.value);
    //setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };


  const onDocTypeChange = async (event) => {
    console.log(event);
    //setFile(event.target.files[0]);
    setFile_type(event.value);
    setFile_accept(event.file_types);
    handleReset();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('submit');
    const formData = new FormData();
    if (file) {
      // Update the formData object
      formData.append(
        "my_file",
        file,
        file.name
      );
      formData.append("action", "event_add_document");
      formData.append("db_document_type_id", file_type);
      formData.append("db_display_name", displayname);

      const resJson = await fetch_post_document('app/event/do_add_document/' + id, formData);

      handleReset();
      setLoading(true);
    }
  };

  return (
    <>
      <MDBRow>
        {/* <form onSubmit={handleSubmit}> */}
        <MDBCol size="3">
          <MDBSelect
            // preventFirstSelection
            label='Dokument typ'
            onChange={onDocTypeChange}
            data={types}
          />
        </MDBCol>
        <MDBCol size="6">

          <MDBInputGroup className='mb-3' onChange={onFileChange} col="3" label='Default file input example'>

            <input className="form-control form-control-undefined" type="file" id='customFile' accept={file_accept} ref={inputFile} />
            <MDBBtn outline type='submit' onClick={handleSubmit}>Ladda upp</MDBBtn>
          </MDBInputGroup>
        </MDBCol>
        <MDBCol size="3">

          <MDBInput label='Visningsnamn' onChange={onChangeDisplayName} name='displayname' type='text' value={displayname} />
        </MDBCol>
        <MDBCol size="6">
          <p>Tillåtna format för dokument typen: {file_accept}</p>
        </MDBCol>
        {/* </form> */}
      </MDBRow>

      <MDBTable>

        <tbody>
          <tr>
            <th> </th>
            <th>Typ</th>
            <th>Namn</th>
            <th>Skapad</th>
          </tr>
          {data.map(item => (<>
            <tr key={item.id}>
              <td>
                <MDBPopconfirm
                  style={{ padding: '5px' }}
                  color="none"
                  modal
                  btnChildren={<MDBIcon far icon="trash-alt" color="danger" size='sm' />}

                  cancelBtnText='Avbryt'
                  onConfirm={() => ondeleteDocument(item.id)}
                  cancelBtnClasses='btn-secondary'
                  btnClassName='btn btn-link'
                  title={"Ta bort dokument"}
                >
                  <MDBPopconfirmMessage
                    icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
                    size='lg'
                  >
                    Ta bort dokument: <br /><br />{item.file_name}?
                  </MDBPopconfirmMessage>
                </MDBPopconfirm>

              </td>

              <th>{item.document_type}</th>
              <td><a href={"https://dev.svsf-ta.se/api/app/document/get_document/" + item.key_id}>{item.display_name}</a></td>
              <td>{item.add_date}</td>

            </tr>
          </>


          ))}

        </tbody>
      </MDBTable>
    </>
  );
}

