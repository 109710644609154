import { useLoaderData, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import {
  Outlet,
  Link
} from "react-router-dom";
import { fetch_get } from "../../../services/common";
import {    
  MDBContainer,
  MDBIcon,
  MDBCollapse,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem,
	MDBBtn,
 } from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";

export async function loader({ params }) {


	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}
	
	const obj = {
		user: await fetch_get("app/user/all/" + params.key_id),
		guest: decoded.kid == params.key_id ? false : true
	}
	return obj;

	
}

export default function UserRoot() {

	const loadObject = useLoaderData();
  const user = loadObject.user.data;
	const guest = loadObject.guest;
	const [parent, setParent] = useState(false)
	const [showMenu, setShowMenu] = useState(false);
	const [showMenuToggle, setShowMenuToggle] = useState(false);
	const url = window.location.href;
	const urlIdx = url.lastIndexOf('/');
	const urlEnding = url.substring(urlIdx + 1);
	const [active,setActive] = useState(urlEnding)
	const endWithS = user.first_name.charAt(user.first_name.length - 1) == 's' ? true : false

  const toggleShow = () => setShowMenu(!showMenu);
	
	function showFamily(){
		if(user.childs.length > 0){
			return true;
		}
	}

	function showRoles(){
		return user.roles.map(role => {
			if(role.id != "100"){
				return true
			}
		})
	}

	const checkResize = () => {
		if(window.innerWidth < 992){
			setShowMenuToggle(true)
			setShowMenu(false)
		} else {
			setShowMenuToggle(false)
			setShowMenu(true)
		}
  };

	useEffect(() => {

		user.parents.map(parent => {
			const loggedInParent = localStorage.getItem("key_id")
			if(parent.key_id == loggedInParent){
				setParent(true)
			}
		})

    checkResize();
    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);
  
  return (
        <>
					{showMenuToggle ? <MDBContainer fluid>
								<MDBBtn size="lg" color='tertiary' onClick={() => toggleShow()}>
									<MDBIcon fas icon='bars' />
								</MDBBtn>
					</MDBContainer> : null}
				  
        
      <MDBCollapse open={showMenu} tag="nav" className={showMenuToggle ? 'd-lg-block bg-white sidebar' : 'd-lg-block bg-white sidebar float-start'} >
        <div className="position-sticky">
          <MDBListGroup className="mx-1 mt-1">
						<MDBRipple rippleTag='span'>
              <MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "profile"} >
                <Link to={"profile"} className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("profile")) : () => setActive("profile")}>
                <MDBIcon fas icon="user" className="fa-fw me-3" />
                {guest ? endWithS ? user.first_name : user.first_name + "s" : "Min"} profil
              </Link>
              </MDBListGroupItem>
            </MDBRipple>
						{!guest || parent ? 
						<>
						{/* <MDBRipple rippleTag='span'>
						<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "events"}>
							<Link to="events" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("events")) : () => setActive("events")}>
							<MDBIcon fas icon="bullseye" className="fa-fw me-3" />
							Tävlingar
						</Link>
						</MDBListGroupItem>
					</MDBRipple>
					<MDBRipple rippleTag='span'>
						<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "result"} >
							<Link to="result" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("result")) : () => setActive("result")}>
								<MDBIcon fas icon="clipboard-list" className="fa-fw me-3" />
								Resultat
							</Link>
						</MDBListGroupItem>
					</MDBRipple>
					<MDBRipple rippleTag='span'>
						<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "ranking"} >
							<Link to="ranking" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("ranking")) : () => setActive("ranking")}>
							<MDBIcon fas icon="trophy" className="fa-fw me-3" />
							Ranking
						</Link>
						</MDBListGroupItem>
					</MDBRipple> */}
					<MDBRipple rippleTag='span'>
						<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "license"}>
							<Link to="license" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("license")) : () => setActive("license")}>
							<MDBIcon fas icon="id-card" className="fa-fw me-3" />
							Tävlingslicenser
						</Link>
						</MDBListGroupItem>
					</MDBRipple>
					<MDBRipple rippleTag='span'>
						<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "associations"}>
							<Link to="associations" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("associations")) : () => setActive("associations")}>
							<MDBIcon fas icon="house" className="fa-fw me-3" />
							Föreningar
						</Link>
						</MDBListGroupItem>
					</MDBRipple>
					{/* {showFamily() ? 
					<MDBRipple rippleTag='span'>
						<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "family"} >
							<Link to="family" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("family")) : () => setActive("family")}>
							<MDBIcon fas icon="users" className="fa-fw me-3" />
							Familj
						</Link>
						</MDBListGroupItem>
					</MDBRipple>
					: null} */}
					{showRoles() ?
					<MDBRipple rippleTag='span'>
						<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "roles"}>
							<Link to="roles" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("roles")) : () => setActive("roles")}>
							<MDBIcon fas icon="user-tag" className="fa-fw me-3" />
							Roller
						</Link>
						</MDBListGroupItem>
					</MDBRipple> 
					: null}			
					<MDBRipple rippleTag='span'>
						<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "payments"}>
							<Link to="payments" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("payments")) : () => setActive("payments")}>
							<MDBIcon fas icon="credit-card" className="fa-fw me-3" />
							Mina köp
						</Link>
						</MDBListGroupItem>
					</MDBRipple>
					<MDBRipple rippleTag='span'>
						<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "settings"}>
							<Link to="settings" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("settings")) : () => setActive("settings")}>
							<MDBIcon fas icon="tools" className="fa-fw me-3" />
							Inställningar
						</Link>
						</MDBListGroupItem>
					</MDBRipple></> : null}
						
          </MDBListGroup>
        </div>
      </MDBCollapse>
			<div className='sidenav-content'>
				{parent ? 
				<MDBContainer>
					<div className='text-white bg-danger text-center'>
						Du är nu inne på ditt barns profil.
					</div>
				</MDBContainer>
				: null}
				
        <Outlet />
      </div>
    </>
  );
}