import React, { useState, useRef, useEffect } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import { MDBTreeTable, MDBTreeTableItem, MDBTreeTableHead, MDBTreeTableBody } from 'mdb-react-treetable';
import {
  MDBContainer,
  MDBTableBody,
  MDBTable,
  MDBDatatable,
  MDBTabsLink,
  MDBTabs,
  MDBTabsItem,
  MDBTabsContent,
  MDBTabsPane,
  MDBSelect,
  MDBCol,
  MDBRow,
  MDBBadge,
  MDBBtn,
  MDBIcon,
  MDBCollapse,
  MDBAccordion,
  MDBAccordionItem,
  MDBListGroup,
  MDBListGroupItem,
  MDBNavbar,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle
} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,

  Form,
  redirect,
  useParams
  //useNavigation,
  //useSubmit,
} from "react-router-dom";
import { fetch_get, fetch_post } from "../../../services/common";
import { BorderCol } from '../../modules/BorderCol';
import EventResultat from './EventResultatSmst';






export const loader = async ({ params }) => {
  //const resJson = await fetch_get('app/event/view/' + params.id);

  //return resJson;
  return [];
};


export default function EventsPage() {
  const { id } = useParams();
  //const loadObject = useLoaderData();
  const [data, setData] = useState([]);
  const [basicActive, setBasicActive] = useState('tab1');
  const [loading, setLoading] = useState(true);
  // const [data, setProps] = useState(data_arr);

  useEffect(() => {

    fetch_get('app/event/view/' + id)
      .then(data => {

        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>

      {loading ? (
        <p>Laddar data...</p>
      ) : (

        <MDBContainer className="py-4">
          <h1>Tävling - {data.name}</h1>
          <MDBTabs className='mb-3'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                Översikt
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                Deltagare
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                Lag
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
                Resultat
              </MDBTabsLink>
            </MDBTabsItem>            
          </MDBTabs>

          <MDBTabsContent>
            <MDBTabsPane open={basicActive === 'tab1'}><EventOverview data={data} /></MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab2'}><EventDeltagare /></MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab3'}><EventLag /></MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab4'}><EventResultat /></MDBTabsPane>
          </MDBTabsContent>


        </MDBContainer >
      )}
    </>
  );
}



function EventOverview(props) {
  const loadObject = useLoaderData();
  const { id } = useParams();
  //const [data, setData] = useState(loadObject);

  //const [data, setData] = useState(null);
  const [data_doc, setDataDoc] = useState(null);
  const [data_sportgrenar, setSportgrenar] = useState(null);
  //const [loading, setLoading] = useState(true);



  // *****************************

  useEffect(() => {

    //   fetch_get('app/event/view/' + id)
    //     .then(data => {
    //       setData(data.data);
    //       setLoading(false);
    //     })
    //     .catch(error => {
    //       console.error('Error fetching data:', error);
    //       setLoading(false);
    //     });

    fetch_get('app/event/documents/' + id)
      .then(data => {

        setDataDoc(data.data);

      })
      .catch(error => {
        console.error('Error fetching data:', error);

      });

    fetch_get('app/event/sportgrenar/' + id)
      .then(data => {
        console.log('sportgrenar', data.data)
        setSportgrenar(data.data);

      })
      .catch(error => {
        console.error('Error fetching data:', error);

      });
  }, []);




  return (
    <>

    

      {!props.data ? (
        <h6>Laddar</h6>
      ) : (
        <>
          {/* <h1>Tävling - {props.data.name}</h1> */}
<MDBRow>
          <div className="d-flex flex-column flex-md-row justify-content-around">
            <div className="d-flex flex-column flex-fill mb-3 order-2 order-sm-1">
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Tävling</div>
                {props.data.name || '-'}
              </div>
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Arrangör</div>
                {props.data.arrangorer || '-'}
              </div>
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Typ Av tävling</div>
                {props.data.type_name || '-'}
              </div>
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Kategori</div>
                {props.data.kategori_name || '-'}
              </div>
              <div className="p-2 position-relative overflow-wrap">
                <div className="overview-label text-nowrap">Sportgren</div>
                {data_sportgrenar && data_sportgrenar.map((item, index, array) => {
                  return (

                    <>
                      <div>{item.sportgren}</div>
                      <p className='fw-light fst-italic text-muted mb-0'>{
                        item.klasser.map(x => x.klass_name).join(', ')
                      }
                      </p>
                    </>

                  )
                })}
                {/* {props.data.d_sportgren
                  ? (props.data.d_sportgren.split(',').map(sg => <div>{sg}</div>))
                  : <div>-</div>
                } */}
              </div>
              <hr className="hr" />
              <div className="p-2 position-relative css-fix">
                <div className="overview-label text-nowrap">Plats</div>
                {props.data.plats || '-'}
              </div>
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Hemsida</div>
                <a href={props.data.hemsida} target='_new'>{props.data.hemsida || '-'}</a>
              </div>
              <hr className="hr" />
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Kontakt</div>
                {props.data.kontakt_first_name || '-'}
              </div>
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">E-mail</div>
                {props.data.kontakt_email || '-'}
              </div>
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Telefon</div>
                {props.data.kontakt_phone || '-'}
              </div>
            </div>
            <div className="d-flex flex-column flex-fill mb-3 order-3 order-sm-2">
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Start datum</div>
                {props.data.start_date || '-'}
              </div>
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Slut datum</div>
                {props.data.end_date || '-'}
              </div>
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Öppnar för anmälan</div>
                {props.data.anmalan_start_date || '-'}
              </div>
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Sista anmälningsdatum</div>
                {props.data.anmalan_end_date || '-'}
              </div>
              <div className="p-2 position-relative">
                <div className="overview-label text-nowrap">Sista efteranmälningsdatum</div>
                {props.data.efteranmalan_end_date || '-'}
              </div>
            </div>
            <div className="d-flex flex-column flex-fill mb-3 order-1 order-sm-3">
              <div className="p-2 position-relative" color={props.data.status_color}>
                <div className="overview-label text-nowrap">Status</div>
                {props.data.status_name || '-'}
              </div>
            </div>

            <BorderCol 
            //headline="Dokumnet" 
            //info_id="modal_4" 
            //className='d-flex flex-column flex-fill col-md-3 col-sm-3 square border border-primary rounded-6 p-4 mx-1 position-relative order-4 order-sm-2'
            className="d-flex flex-column flex-fill mb-3 order-4 order-sm-2" 
            >


              {data_doc && data_doc.map((item, index, array) => {
                let prev_type = '-';
                let show_rubrik = true;
                if (index > 0) {
                  prev_type = array[index - 1].document_type;
                }
                if (prev_type === item.document_type) {
                  show_rubrik = false;
                }
                return (

                  <>
                    {show_rubrik && (
                      <>
                        <div key={item.id} className="px-2 pt-2 position-relative">
                          <div className="overview-label text-nowrap">Dokument - {item.document_type}</div>
                          <a href={"https://dev.svsf-ta.se/api/app/document/get_document/" + item.key_id}>{item.display_name}</a>
                        </div>
                      </>
                    )
                    }
                    {!show_rubrik && (
                      <>
                        <div key={item.id} className="px-2 pb-2 position-relative">
                          <div className="overview-label text-nowrap" ></div>
                          <a href={"https://dev.svsf-ta.se/api/app/document/get_document/" + item.key_id}>{item.display_name}</a>
                        </div>
                      </>
                    )}

                  </>

                )
              })}


            </BorderCol>

            




          </div>
          </MDBRow>
        </>
      )}



    </>
  );
}


function EventDeltagare(props) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [data_temp, setDataTemp] = useState([]);
  const [group, setGroup] = useState("");
  const [a_active, setActive] = useState([0]);

  const itemClick = React.useRef()

  useEffect(() => {

    fetch_get('app/event/deltagare/' + id)
      .then(data => {
        let d = data.data;

        const d_new = d.map(o => ({ ...o, all: "Alla" }));

        d_new.sort((a, b) => a.full_name.localeCompare(b.full_name));

        const result = Object.groupBy(d_new, ({ all }) => all);
        console.log('result', result);

        setData(d_new);
        setDataTemp(result);

      })
      .catch(error => {
        console.error('Error fetching data:', error);

      });
  }, []);


  useEffect(() => {
    // action on update of movies
    console.log('Count has been updated:', data);
  }, [group]);

  const advancedData = {
    columns: [
      { label: 'Förening', field: 'forening_name', sort: true, width: 160 },
      { label: 'Namn', field: 'full_name', sort: true, width: 160 },
      { label: 'Klass', field: 'klass_name_display', sort: true, width: 120 },
      { label: 'Distrikt', field: 'distrikt_name', sort: true, width: 120 },
    ],
    rows: data.map((row) => {

      return {
        ...row,
        temp: (
          <>
            <h6>-</h6>
          </>

        ),


      };
    })
  };

  const mainStyles = {
    rubriker: {
      height: 20,
      // position: 'absolute',
      top: '-10px',

    },
    button: {
      padding: '5px'
    }
  };


  const onGroupChanged = async (group_id) => {

    console.log('onGroupChanged', group_id);

    let d = data;

    let result = null;

    switch (group_id) {
      case 1:
        result = Object.groupBy(d, ({ all }) => all);
        break;
      case 2:
        result = Object.groupBy(d, ({ klass_name_display }) => klass_name_display);
        break;
      case 3:
        result = Object.groupBy(d, ({ forening_name }) => forening_name);
        break;
      case 4:
        result = Object.groupBy(d, ({ distrikt_name }) => distrikt_name);
        break;
      case 5:
        result = Object.groupBy(d, ({ forening_name }) => forening_name);
        break;
      default:
      // code block
    }

    console.log('result', result);
    setDataTemp(result);
    if (group_id === 1) {
      setActive([0]);
    } else {
      setActive([-1]);
    }


  };

  return (
    <>

      <MDBRow>

        <BorderCol
          headline="Anmälda delatagare"
          info_id="modal_4"   //
          className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
          {/* <MDBBadge light className="position-absolute" style={mainStyles.rubriker}>Anmälda delatagare</MDBBadge> */}


          {/* <MDBDatatable
            //className='pb-4'
            search
            bordered
            sm
            striped
            hover
            sortField='forening_name, full_name'
            pagination={false}
            entries={1000}
            noFoundMessage='Inga deltagare inlagda...'
            loaderClass='Laddar...'
            ofText='av'
            rowsText='Rader per sida:'
            data={advancedData}
          /> */}


          <MDBNavbar light bgColor='light'>
            <MDBSelect
              label='Gruppera anmälda'
              onValueChange={(e) => onGroupChanged(e.value)}
              data={[
                { text: 'Alla', value: 1 },
                { text: 'Klass', value: 2 },
                { text: 'Förening', value: 3 },
                { text: 'Distrikt', value: 4 },
                // { text: 'Betalt', value: 5 },

              ]}
            />

            <MDBDropdown>
              <MDBDropdownToggle color='secondary'><MDBIcon fas icon="print" className="pe-2" />Skriv ut</MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem link color='light'><MDBIcon fas icon="file-pdf" className='me-2' />Deltagarlista (PDF)</MDBDropdownItem>
                <MDBDropdownItem link color='light'><MDBIcon fas icon="file-pdf" className='me-2' />Deltagarlista enl.gruppering (PDF)</MDBDropdownItem>
                {/* <MDBDropdownItem link onClick={() => extExcelExport(data2, null, "Deltagare_" + id)}><MDBIcon fas icon="file-excel" className='me-2' />Deltagarlista (Excel)</MDBDropdownItem> */}
                {/* <MDBDropdownItem link onClick={exportToCsv}>Sius: shooter.csv</MDBDropdownItem> */}
                {/* <MDBDropdownItem link onClick={exportToCsv}>Megalink: export.txt</MDBDropdownItem> */}
                {/* <MDBDropdownItem link onClick={exportToJson}>Deltagarlista (JSON)</MDBDropdownItem> */}
              </MDBDropdownMenu>
            </MDBDropdown>

          </MDBNavbar>

          {!data_temp ? (
            <h6>Laddar</h6>
          ) : (
            <>


              <MDBAccordion
                // alwaysOpen
                initialActive={-1}
                active={a_active}
                onChange={(id) => setActive(id)}
              >

                {data_temp && Object.keys(data_temp).map((item, index, array) => {
                  //console.log('key1', key, data_temp[key], Object.keys(data_temp));
                  console.log('key1', item, index, data_temp[item]);
                  return (


                    <>
                      <MDBAccordionItem collapseId={index} headerTitle={<>
                        <div className="d-flex flex-row">
                          <strong className="p-2">{item === ' ' ? 'Klass saknas' : item}</strong>
                          <div className="p-2 align-self-center">Antal anmälda : {data_temp[item].length}</div>

                        </div>
                        {/* {(item === ' ' ? 'Klass saknas' : item) + '    -    Antal anmälda : ' + data_temp[item].length} */}
                      </>}
                      >
                        <MDBListGroup light small>
                          {data_temp[item] && data_temp[item].map((item, index, array) => {
                            console.log(index);
                            return (

                              <>
                                <MDBListGroupItem className='d-flex justify-content-between align-items-start'>
                                  <div className='ms-2 me-auto'>
                                    <div className='fw-bold'>{item.full_name}</div>
                                    <div>{item.forening_name}</div>
                                    <div>{item.distrikt_name}</div>
                                  </div>
                                  <div>{item.klass_name_display}</div>

                                </MDBListGroupItem>
                                {/* <MDBTreeTableItem className='xxhidden' open={false} values={[item.forening_name, item.distrikt_name, item.full_name, item.klass_name_display]} depth={2} /> */}
                              </>

                            )
                          })}
                        </MDBListGroup>
                      </MDBAccordionItem>

                      {/* <MDBTreeTableItem values={[item.forening_name, item.distrikt_name, item.full_name, item.klass_name_display]} depth={2} />                   </> */}
                    </>
                  )
                })}

              </MDBAccordion>


              {/* 
          <MDBTreeTable>
            <MDBTreeTableHead heads={['Förening', 'Distrikt', 'Namn', 'Klass']} />
            <MDBTreeTableBody open={false}>
              {data_temp && Object.keys(data_temp).map((item, index, array) => {
                //console.log('key1', key, data_temp[key], Object.keys(data_temp));
                console.log('key1', item, index, data_temp[item]);
                return (


                  <>
                    <MDBTreeTableItem show={true} values={[item === ' ' ? 'Klass saknas' : item, '', '', '']} depth={1} />

                    {data_temp[item] && data_temp[item].map((item, index, array) => {
                      console.log(index);
                      return (

                        <>
                          <MDBTreeTableItem className='xxhidden' open={false} values={[item.forening_name, item.distrikt_name, item.full_name, item.klass_name_display]} depth={2} />
                        </>

                      )
                    })}



      
                  </>
                )
              })}

            </MDBTreeTableBody>
          </MDBTreeTable> */}

              {/* <MDBTreeTable>
            <MDBTreeTableHead heads={['Förening', 'Distrikt', 'Namn', 'Klass']} />
            <MDBTreeTableBody>
            <MDBTreeTableItem show={true} values={['Alla', '', '', '']} depth={1} />
              {data && data.map((item, index, array) => {
                console.log(index);
                return (

                  <>
                    <MDBTreeTableItem values={[item.forening_name, item.distrikt_name, item.full_name, item.klass_name_display]} depth={2} />
                  </>

                )
              })}
            </MDBTreeTableBody>
          </MDBTreeTable>           */}

            </>
          )}


          {/* {!data ? (
        <h6>Laddar</h6>
      ) : (
        <>
          <MDBTreeTable>
            <MDBTreeTableHead heads={['Förening', 'Distrikt', 'Namn', 'Klass']} />
            <MDBTreeTableBody>
            <MDBTreeTableItem show={true} values={['Alla', '', '', '']} depth={1} />
              {data && data.map((item, index, array) => {
                console.log(index);
                return (

                  <>
                    <MDBTreeTableItem values={[item.forening_name, item.distrikt_name, item.full_name, item.klass_name_display]} depth={2} />
                  </>

                )
              })}
            </MDBTreeTableBody>
          </MDBTreeTable>

    

        </>
      )} 
       */}

        </BorderCol>
      </MDBRow>
    </>
  );
}


function EventLag(props) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [group, setGroup] = useState("");

  useEffect(() => {

    fetch_get('app/event/teams/' + id)
      .then(data => {
        console.log('data_documents', data.data)
        setData(data.data);

      })
      .catch(error => {
        console.error('Error fetching data:', error);

      });
  }, []);


  useEffect(() => {
    // action on update of movies
    console.log('Count has been updated:', data);
  }, [group]);

  const advancedData = {
    columns: [
      { label: 'Förening', field: 'name', sort: true, width: 100 },
      { label: 'Lagtävling', field: 'team_config_name', sort: true, width: 120 },
      { label: 'Namnet på laget', field: 'lag_namn', sort: true, width: 120 },
      { label: 'Ingående deltagare', field: 'lag_medlemmar', sort: true, width: 260 },
    ],
    rows: data.map((row) => {

      return {
        ...row,
        lag_namn: (
          <>
            <span>{row.name} {row.team_suffix}</span>
          </>
        ),
        lag_medlemmar: (
          <>

            <div className='ms-3'>
              {row.members.map((members, k) => (
                <>
                  <p key={k} className='mb-1'>{members.full_name} / {members.klass_name_display} / {members.forening_name}</p>
                  {/* <tr key={k}>
                    <td>{members.full_name}</td>
                    <td>{members.klass_name_display}</td>
                     <td>{members.forening_name}</td> 
                  </tr> */}
                </>
              ))}

            </div>

            {/* <MDBTable bordered className='mt-2'>
              <tr>
                <th>Namn</th>
                <th>Klass</th>
                 <th>Förening</th> 
              </tr>
              {row.members.map((members, k) => (
                <>
                  <tr key={k}>
                    <td>{members.full_name}</td>
                    <td>{members.klass_name_display}</td>
                    <td>{members.forening_name}</td>
                  </tr>
                </>
              ))}
            </MDBTable> */}
          </>
        ),


      };
    })
  };

  const mainStyles = {
    rubriker: {
      height: 20,
      // position: 'absolute',
      top: '-10px',

    },
    button: {
      padding: '5px'
    }
  };

  return (
    <>
      <MDBRow>

        <MDBCol className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
          <MDBBadge light className="position-absolute" style={mainStyles.rubriker}>Anmälda lag</MDBBadge>
          <MDBDatatable
            //className='pb-4'
            // search
            bordered
            sm
            striped
            hover
            sortField='name, team_suffix'
            pagination={false}
            entries={1000}
            noFoundMessage='Inga lag anmälda...'
            loaderClass='Laddar...'
            ofText='av'
            rowsText='Rader per sida:'
            data={advancedData}
          />

        </MDBCol>
      </MDBRow>

    </>
  );
}


function EventTab(data_arr) {
  const [basicActive, setBasicActive] = useState('tab1');
  const [data, setProps] = useState(data_arr);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>
      <MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
            Sportgrenar
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
            Deltagare
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
            Dokument
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={basicActive === 'tab1'}><Sportgrenar arr={data.data_arr.data.sportgrenar} /></MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab2'}><Deltagare2 deltagar_arr={data.data_arr.data.deltagare} /></MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab3'}><Dokument doc_arr={data.data_arr.data.documents} /></MDBTabsPane>
      </MDBTabsContent>
    </>
  );
}


function Deltagare2(deltagar_arr) {

  const [data, setProps] = useState(deltagar_arr);

  const advancedData = {
    columns: [
      { label: 'Namn', field: 'full_name', sort: true },
      { label: 'Förening', field: 'forening_name', sort: true },
      { label: 'Klass', field: 'klass_name_display', sort: true },
    ],
    rows: data.deltagar_arr,
  };

  return (
    <>
      <MDBDatatable
        search
        noFoundMessage='Inga deltagare inlaggda...'
        loaderClass='Laddar...'
        ofText='av'
        rowsText='Rader per sida:'
        data={advancedData}
      />
    </>
  );
}

function Deltagare(deltagar_arr) {

  const [data, setProps] = useState(deltagar_arr);

  return (
    <>

      <h2>Deltagare</h2>

      <MDBTable>

        <tbody>
          <tr>
            <th>Namn</th>
            <th>Förening</th>
            <th>Klass</th>
          </tr>
          {data.deltagar_arr.map(item => (<>
            <tr key={item.id}>

              <td>{item.last_name}, {item.first_name}</td>
              <td>{item.forening_name}</td>
              <td>{item.klass_name_display}</td>
            </tr>
          </>


          ))}

        </tbody>
      </MDBTable>
    </>
  );
}


function Dokument(doc_arr) {

  const [data, setProps] = useState(doc_arr);

  return (
    <>

      <MDBTable>

        <tbody>
          <tr>
            <th>Namn</th>
            <th>Skapad</th>
          </tr>
          {data.doc_arr.map(item => (<>
            <tr key={item.id}>

              <td>{item.document_type}, {item.file_name}</td>
              <td>{item.add_date}</td>

            </tr>
          </>


          ))}

        </tbody>
      </MDBTable>
    </>
  );
}

function Sportgrenar(arr) {

  const [data, setProps] = useState(arr);

  return (
    <>

      <MDBTable>

        <tbody>
          <tr>
            <th>Gren</th>
          </tr>
          {data.arr.map(item => (<>
            <tr key={item.sportgren_id}>

              <td>{item.sportgren}</td>


            </tr>
          </>


          ))}

        </tbody>
      </MDBTable>
    </>
  );
}
