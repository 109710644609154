
import React from 'react';
import {  
  MDBContainer
 } from 'mdb-react-ui-kit';


export default function KansliEducation() {
  
  return (
		<>
			<MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">
				<h3>Under utveckling</h3>
				<hr/>
			</MDBContainer>
		</>
  );
}