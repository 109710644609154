
import { MDBBadge } from 'mdb-react-ui-kit';

export const BorderHeadline = (headline = "Rubrik") => {

	const mainStyles = {
		rubriker: {
			height: 20,
			position: 'absolute',
			top: '-10px',
	
		},
		button: {
			padding: '5px'
		}
	};

	return ( <>
		<MDBBadge light style={mainStyles.rubriker}>{headline.headline}</MDBBadge>
	</> );
}