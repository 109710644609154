import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput
} from 'mdb-react-ui-kit';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
  MRT_Table,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import { useParams } from "react-router-dom";
import { fetch_get, fetch_post } from "../../../services/common";


// sportgrenarModal, setSportgrenarModal
export default function EventModalEditSportgren(props) {
  const { id } = useParams();
  const [data, setData] = useState(props.sportgren_data.klasser);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState('');

  useEffect(() => {

    setData(props.sportgren_data.klasser);

  }, [props.sportgren_data]);

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'klass_name', //access nested data with dot notation
        header: 'Klass',
        size: 70,
        enableEditing: false,
      },
      {
        accessorKey: 'startavgift', //access nested data with dot notation
        header: 'Startavgift',
        size: 70,
        enableEditing: true,
        muiEditTextFieldProps: ({ row, column }) => ({
          required: true,
          type: 'number',
          // variant: 'outlined',
          onBlur: (event) => {
            // updateData(event.currentTarget.value, row, column)
          }
        }),
      },
    ],
    [],
  );

  function setCellColor(cell, col, row, type) {
    if (type == 'border') {
      if (cell.getValue() != null && col.startsWith('start')) {
        return '1px solid #000'
      }
      if (col.startsWith('start')) {
        return '1px dashed #000'
      }

    }
    if (type == 'backgroundColor') {
      if (cell.getValue() != null && col.startsWith('start')) {
        return '#e3f5e1'
      }
    }
    return ''
  }

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: data,
    editDisplayMode: 'cell',
    enableEditing: true,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableToolbarInternalActions: false,
    localization: MRT_Localization_SV,
    initialState: {
      density: 'compact',
    },
    muiTableBodyCellProps: ({ cell, column, table, row }) => ({
      onClick: () => {
        setCellColor(cell, column.id, row.original)
        table.setEditingCell(cell);
        queueMicrotask(() => {
          const textField = table.refs.editInputRefs.current[column.id];
          if (textField) {
            textField.focus();
            textField.select?.();
          }
        });
      },
      onKeyUp: (event) => {
        console.log(event.key)
      },
      sx: {
        borderRadius: '0',
        border: setCellColor(cell, column.id, row.original, 'border'),
        backgroundColor: setCellColor(cell, column.id, row.original, 'backgroundColor')
      },
    }),
  });

  const onBlur_startavgift = async (e) => {

    console.log('onBlur_startavgift', e.target.value, data);

    const data_tmp = data.map(row => {
        return { ...row, startavgift: e.target.value };
     });  
     
     setData(data_tmp);

  };

  const onSave = async () => {
    let rows_all = table.getCoreRowModel().flatRows;
    let sum = 0
    let arr = [];
    rows_all.map(row => {

      console.log(row.original.event_klasser_id, row._valuesCache);
      arr.push({ event_klasser_id: row.original.event_klasser_id, startavgift: row._valuesCache.startavgift | 0 });

    })

    console.log(arr);

    const jsonObj = {
      action: "event_klasser_update",
      klass_arr: arr,
    }
    await fetch_post('app/event/do_submit/' + id, jsonObj);
    props.toggleSportgrenarOpen();
  }

  return (
    <>

      <MDBModal tabIndex='-1' open={props.sportgrenarModal} setOpen={props.setSportgrenarModal} nonInvasive={false} onClose={() => {
        // props.setSportgrenData(null);
        console.log(table.getCoreRowModel().flatRows)
      }}>
        <MDBModalDialog >
          <MDBModalContent >
            <MDBModalHeader>
              <MDBModalTitle>{props.sportgren_data.sportgren}</MDBModalTitle>
              <MDBBtn
                type='button'
                className='btn-close'
                color='none'
                onClick={props.toggleSportgrenarOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className='pb-2 col-4'>
                <MDBInput

                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={(e) => onBlur_startavgift(e)}
                  label='Startavgift'
                  id='startavgift'
                  type='number'
                />
              </div>
              <MaterialReactTable
                table={table}
              />

            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type='button' color='primary' onClick={onSave}>
                spara
              </MDBBtn>
              <MDBBtn type='button' color='secondary' onClick={props.toggleSportgrenarOpen}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}