import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { 
  MDBCol,
  MDBDatatable,
  MDBDropdown,
	MDBDropdownMenu,
	MDBDropdownToggle,
	MDBDropdownItem,
  MDBBtn,
	MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
	MDBValidation,
	MDBValidationItem,
	MDBInput,
	MDBRow,
	MDBIcon} from "mdb-react-ui-kit";

export default function FacilitiesList(facilities) {


  const navigate = useNavigate();
  const fixObject = (id, name, banor) => { 
    return { 
      id: id, 
      name: name,
			banor: banor}};
			
  const facilityRows = facilities.facilities.map(facility => {
    
    Object.keys(facility).map((key) =>{
      if(facility[key] == null){
        facility[key] = "";
      }
    })
    const fixedUser = fixObject(
      facility.id,
      facility.name,
			facility.banor
		);

    return fixedUser;
    
  },);
  
  const [editModal, setEditModal] = useState(false);
  const [editBanorForm, setEditBanorForm] = useState({
    id: "", 
    name: "",
		tavlor: ""
  });

	const onSubmit = (e) => {
    e.preventDefault();/* 
    if(e.target.checkValidity()){
      const newRows = userData.rows.map(user => {
        if(user.id === editUserForm.id){
          return {
            ...user,
            first_name: editUserForm.first_name,
            last_name: editUserForm.last_name,
            email: editUserForm.email
          }
        }
        else return user;
      });
      setUserData({ ...userData, rows: newRows});
    } */
  }

	const toggleShow = (i) => {
		console.log(facilityRows[0].banor[i]);
    if(!editModal){
      setEditBanorForm(facilityRows[0].banor[i]);
    }
    setEditModal(!editModal)
    };

	const onChange = (e) => {
		setEditBanorForm({ ...editBanorForm, [e.target.name]: e.target.value });
		};

	const [facilityData,setFacilityData] = useState({
    
    columns: [
      { label: "Namn", field: "name"},
			{ label: "Banor", field: "banor"},
			{ label: "Edit", field: "edit", fixed: "right", sort: false}
    ],
    rows: facilityRows.map((row) => {
      return {
        ...row,
        name: (
          <>
            <MDBBtn onClick={() => {navigate("facility/" + row.id)}} color='link' rounded size='sm'>
							{row.name}
            </MDBBtn>
          </>
        ),
				banor: (
					<>
						<MDBDropdown group className='shadow-0'>
							<MDBDropdownToggle color='link'>Banor</MDBDropdownToggle>
							<MDBDropdownMenu appendToBody> 
								{row.banor.map((bana,index) => {
									return 	<MDBDropdownItem key={index} link onClick={() => toggleShow(index)}>
														Pistol - {bana.name}
													</MDBDropdownItem>
								})}
							</MDBDropdownMenu>
						</MDBDropdown>
					</>
				),
				edit: (
					<>
						<MDBBtn tag='a' color='none' className='m-1' style={{ color: '#0F6797' }} onClick={() => {navigate("facility/" + row.id + "/edit")}}>
							<MDBIcon fas icon='wrench' size='lg' />
						</MDBBtn>
					</>
				)
      }
    }),
  });
  return (
    <>
      <MDBCol md='12'>
				<MDBModal open={editModal} setOpen={setEditModal} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>{editBanorForm.name}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
              </MDBModalHeader>
              <MDBValidation onSubmit={onSubmit} className='row g-3'>
                <MDBModalBody>
                  <MDBRow className='g-3'>
                    <MDBValidationItem className='col-md-6'>
                      <MDBInput
                        value={editBanorForm.name}
                        name='name'
                        onChange={onChange}
                        required
                        label='Namn'
                      />
                    </MDBValidationItem>
                    <MDBValidationItem className='col-md-6'>
                      <MDBInput
                        value={editBanorForm.tavlor}
                        name='tavlor'
                        onChange={onChange}
                        required
												type='number'
                        label='Antal tavlor'
												min={1}
												max={100}
												step={1}
                      />
                    </MDBValidationItem>
                  </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn type='reset' color='secondary' onClick={toggleShow}>
                    Stäng
                  </MDBBtn>
                  <MDBBtn type='submit'>Spara</MDBBtn>
                </MDBModalFooter>
              </MDBValidation>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <MDBDatatable 
          mode='modal'
          data={facilityData}
          multi
          search/>
				<MDBBtn onClick={() => {navigate("facility/new/edit")}}>Skapa ny anläggning</MDBBtn>
      </MDBCol>
    </>
  );
}