export default function GetActiveLicense(licenseList){
	let status = false
	if(licenseList){
		for (let i = 0; i < licenseList.length; i++) {
			if(licenseList[i].status == 'ACTIVE') {
				status = true
			}
		} 
	}
	return status
	
}