import React, { useEffect,useState } from 'react';
import {    
  MDBBtn,
	MDBRow,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBTypography,
	MDBSpinner,
	MDBAlert
 } from 'mdb-react-ui-kit';
 import { fetch_get, fetch_post } from '../../../services/common';

export default function UserAssociationModal(params){
	
	const [loading, setLoading] = useState(true);
	const association = params.association
	const user = params.user;
	const [ioAssociation,setIoAssociation] = useState(null)
	const [associationChanged, setAssociationChanged] = useState(false)
	const [associationError, setAssociationError] = useState(false)
	const [associationEdited, setAssociationEdited] = useState(false)

	useEffect(() => {
		setLoading(true)
		fetch_get("app/forening/io-association-details/" + association.forening_id)
			.then((data) =>
				setTimeout(() => {
					setIoAssociation(JSON.parse(data.data[0].json))
					setLoading(false)
				}, 3000)
			);
	}, []);

	const setMainAssociation = async () => {

		const body = {
      "action": "edit_main_association",
      "db_forening_id": association.forening_id
    };

		const resJson = await fetch_post('app/user/do_submit/' + user.key_id, body);

		if(resJson){
			setAssociationChanged((prev) => !prev)
			setAssociationEdited(true)
		} else {
			setAssociationError((prev) => !prev)
		}
	}

	function getContactInfo(obj,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.ContactTypeId == typeId){
				contact = obj.ContactInfo
			}
		})
		return contact
	}

	function getParentOrgInfo(obj,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.ParentOrganisationTypeId == typeId){
				contact = obj.FullName
			}
		})
		return contact
	}

	function getAccountInfo(obj,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.AccountTypeId == typeId){
				contact = obj.AccountNumber
			}
		})
		return contact
	}

	function getPersonInfo(obj,roleId,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.Roles[0].RoleType.RoleTypeId === roleId){
				if(typeId === 99){
					contact = obj.FirstName + " " + obj.LastName
				} else {
					obj.Contacts.map(cont => {
						if(cont.ContactTypeId === typeId){
							contact = cont.ContactInfo
						}
					})
				}
			}
		})
		return contact
	}

    return <>
		<MDBAlert
			color='primary'
			autohide
			position='top-right'
			delay={4000}
			appendToBody
			open={associationChanged}
			onClose={() => setAssociationChanged(false)}
		>
			Huvudförening har ändrats till {association.name}.
		</MDBAlert>
		<MDBAlert
			color='danger'
			autohide
			position='top-right'
			delay={4000}
			appendToBody
			open={associationError}
			onClose={() => setAssociationError(false)}
		>
			Huvudförening kunde inte ändras.
		</MDBAlert>
		<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
			<MDBModalDialog size="lg">
				<MDBModalContent> 
				<MDBModalHeader>
					<MDBModalTitle>Föreningsinfo</MDBModalTitle>
					<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
				</MDBModalHeader>
				<MDBModalBody>
				<MDBRow className="justify-content-center align-items-center h-100">
					{loading || !ioAssociation ?
						<MDBSpinner color='primary'>
						<span className='visually-hidden'>Loading...</span>
					</MDBSpinner> :
					<MDBCol className="mb-4 mb-lg-0">
						<MDBCard className="mb-3" style={{ borderRadius: '.5rem' }}>
							<MDBRow className="g-0">
									<MDBCardBody className="p-4">
										<MDBRow className="pt-1">
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Namn: </MDBTypography></MDBCol>
												<MDBCol><span className="text-muted">{ioAssociation.DescribingName}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Organisationsnummer: </MDBTypography></MDBCol>
												<MDBCol><span className="text-muted">{ioAssociation.OrganisationNumber}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">E-post:</MDBTypography></MDBCol>
												<MDBCol><span className="text-muted">{getContactInfo(ioAssociation.Contacts,1)}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Faktura e-post:</MDBTypography></MDBCol>
												<MDBCol><span className="text-muted">{getContactInfo(ioAssociation.Contacts,16)}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Hemsida:</MDBTypography></MDBCol>
												<MDBCol><span className="text-muted"><a href={getContactInfo(ioAssociation.Contacts,4)} target='_blank'>{getContactInfo(ioAssociation.Contacts,4)}</a></span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Distrikt:</MDBTypography></MDBCol>
												<MDBCol><span className="text-muted">{getParentOrgInfo(ioAssociation.ParentBelongings,4)}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Anläggningar:</MDBTypography></MDBCol>
												<MDBCol><span className="text-muted"></span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Adresser:</MDBTypography></MDBCol>
												<MDBCol>
														{ioAssociation.Addresses.map(address => {
															if(address.AddressTypeId === 3){
																return <React.Fragment key={address.AddressTypeId}>
																	<div className="text-muted">
																		{address.Line1}
																	</div>
																	<div className="text-muted">
																		{address.Line2}
																	</div>
																	<div className="text-muted">
																		{address.PostalNumber}
																	</div>
																	<div className="text-muted">
																		{address.PostalCity}
																	</div>
																</React.Fragment>
															}
														})}
												</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Bankgiro:</MDBTypography></MDBCol>
												<MDBCol><span className="text-muted">{getAccountInfo(ioAssociation.Accounts,1)}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Plusgiro:</MDBTypography></MDBCol>
												<MDBCol><span className="text-muted">{getAccountInfo(ioAssociation.Accounts,5)}</span></MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md={4}><MDBTypography tag="h6">Swish:</MDBTypography></MDBCol>
												<MDBCol><span className="text-muted">{getAccountInfo(ioAssociation.Accounts,3)}</span></MDBCol>
											</MDBRow>
											<MDBCol md="6">
												<MDBTypography tag="h5">Ordförande</MDBTypography>
												<hr className="mt-0 mb-1" />
												<MDBRow className="pt-1">
													<MDBRow>
														<MDBCol md={4}><MDBTypography tag="h6">Namn:</MDBTypography></MDBCol>
														<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,10,99)}</span></MDBCol>
													</MDBRow>
													<MDBRow>
														<MDBCol md={4}><MDBTypography tag="h6">E-post:</MDBTypography></MDBCol>
														<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,10,1)}</span></MDBCol>
													</MDBRow>
													<MDBRow>
														<MDBCol md={4}><MDBTypography tag="h6">Telefon:</MDBTypography></MDBCol>
														<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,10,5)}</span></MDBCol>
													</MDBRow>
												</MDBRow>			
											</MDBCol>
											<MDBCol md="6">
												<MDBTypography tag="h5">Sekreterare</MDBTypography>
												<hr className="mt-0 mb-1" />
												<MDBRow className="pt-1">
													<MDBRow>
														<MDBCol md={4}><MDBTypography tag="h6">Namn:</MDBTypography></MDBCol>
														<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,12,99)}</span></MDBCol>
													</MDBRow>
													<MDBRow>
														<MDBCol md={4}><MDBTypography tag="h6">E-post:</MDBTypography></MDBCol>
														<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,12,1)}</span></MDBCol>
													</MDBRow>
													<MDBRow>
														<MDBCol md={4}><MDBTypography tag="h6">Telefon:</MDBTypography></MDBCol>
														<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,12,5)}</span></MDBCol>
													</MDBRow>
												</MDBRow>	
											</MDBCol>
											<MDBCol md="6">
												<MDBTypography tag="h5">Kassör</MDBTypography>
												<hr className="mt-0 mb-1" />
												<MDBRow className="pt-1">
													<MDBRow>
														<MDBCol md={4}><MDBTypography tag="h6">Namn:</MDBTypography></MDBCol>
														<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,14,99)}</span></MDBCol>
													</MDBRow>
													<MDBRow>
														<MDBCol md={4}><MDBTypography tag="h6">E-post:</MDBTypography></MDBCol>
														<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,14,1)}</span></MDBCol>
													</MDBRow>
													<MDBRow>
														<MDBCol md={4}><MDBTypography tag="h6">Telefon:</MDBTypography></MDBCol>
														<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,14,5)}</span></MDBCol>
													</MDBRow>
												</MDBRow>		
											</MDBCol>
										</MDBRow>
									</MDBCardBody>
							</MDBRow>
						</MDBCard>
						</MDBCol>
					}
					
					
				</MDBRow>
				</MDBModalBody>
				<MDBModalFooter>
					{user.forening_id !== association.forening_id ?
						<MDBBtn disabled={loading} color='primary' onClick={() => setMainAssociation()}>
							Gör till huvudförening
						</MDBBtn>
					: null}
					<MDBBtn color='secondary' onClick={!associationEdited ? params.close : params.closeChanged}>
						Stäng
					</MDBBtn>
				</MDBModalFooter>
			</MDBModalContent>
			</MDBModalDialog>

		</MDBModal>
		
	</>
  
}