import { useNavigate, useLoaderData } from "react-router-dom";
import React,{ useState, useMemo, useEffect } from 'react';
import {    
  MDBBtn,
	MDBSwitch,
	MDBCol,
	MDBRow,
	MDBContainer
 } from 'mdb-react-ui-kit';
 import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { fetch_json, fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";

export async function loader({ params }) {
	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	const obj = {
		user: await fetch_get("app/user/all/" + params.key_id),
		guest: decoded.kid == params.key_id ? false : true
	}
		return obj;
}

export default function UserEvents(){

	const loadObject = useLoaderData();
  const events = loadObject.user.data.my_events;
	const [tableData, setTableData] = useState(events); 
	const navigate = useNavigate();
	const [showAll, setShowAll] = useState(false);

	useEffect(() => {
  	
		if(!showAll){
			const todaysDate = new Date().getTime();
			const filterData = tableData.filter((row) => (new Date(row.start_date).getTime() > todaysDate))
			setTableData(filterData)
		} else {
			setTableData(events)
		}

  }, [showAll]);

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'event_name', //access nested data with dot notation
        header: 'Tävling',
        size: 150,
				Cell: ({ cell, row }) => (
					<MDBBtn onClick={() => navigate("/event/" + row.original.event_key_id)} className='mx-2' color='tertiary' rippleColor='light'>
						{cell.getValue()}
					</MDBBtn>
				),
      },
      {
        accessorKey: 'start_date', //access nested data with dot notation
        header: 'Datum',
        size: 150,
      },
			{
        accessorKey: 'omgangs_nr', //access nested data with dot notation
        header: 'Gren',
        size: 50,
      },
			{
        accessorKey: 'klass_name_display', //access nested data with dot notation
        header: 'Klass',
        size: 50,
      },
			{
        //accessorKey: 'klass_name_display', //access nested data with dot notation
        header: 'Resultat',
        size: 50,
      },
    ],
    [events],
  );



	const table = useMaterialReactTable({
    columns: tableColumns,
    data: tableData,
    enableColumnFilters: false,
		enableColumnActions: false,
		enableDensityToggle: false,
		enableFullScreenToggle: false,
		enableHiding: false,
		enableFilters: false,
		localization:MRT_Localization_SV,
		renderTopToolbarCustomActions: () => (
			<MDBSwitch id='flexSwitchCheckDefault' onChange={() => setShowAll(!showAll)} label='Visa alla tävlingar' />
		)
  });
  
    return (
		<>
			<MDBContainer className="py-4">
				<h3>Tävlingar</h3>
				<hr/>
				<MDBRow>
					<MDBCol md="12" className="mb-3">
						<BorderCol headline="Anmälda tävlingar" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
						<MaterialReactTable table={table} />
						</BorderCol>
					</MDBCol>
					{/* <MDBCol md="4" className="mb-3">
						<BorderCol headline="Sparade tävlingar" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
							Här visas sparade tävlingar
						</BorderCol>
					</MDBCol> */}
				</MDBRow>
			</MDBContainer>
		</>
          );

  
}