import { useLoaderData } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import {
  Outlet,
  Link
} from "react-router-dom";
import { fetch_get } from "../../../services/common";
import {    
  MDBContainer,
  MDBIcon,
  MDBCollapse,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem,
	MDBBtn
 } from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";
import NotFound404 from "../NotFound404";

export async function loader({ params }) {

  const data = localStorage.getItem("token");
  var decoded = "";
  if (data) {
    decoded = jwt_decode(data);
  }
	const user = await fetch_get("app/user/all/" + decoded.kid)
	
	const obj = {
		user: user,
		association: await fetch_get("app/forening/all/" + params.id),
		sportList: await fetch_get("app/list/lov-grenar"),
	}
	return obj;
}

export default function AssociationRoot() {

  const loadObject = useLoaderData();
	const user = loadObject.user.data;
	const association = loadObject.association.data;
	if(!user.roles.find(({ id, forening_id }) => id === "300" && forening_id === association.id)){
		return <NotFound404></NotFound404>
	}
	const [selectedAssociaion, setSelectedAssociation] = useState(association); 
	const [showMenu, setShowMenu] = useState(false);
	const [showMenuToggle, setShowMenuToggle] = useState(false);
  const toggleShow = () => setShowMenu(!showMenu);
	const url = window.location.href;
	const urlIdx = url.lastIndexOf('/');
	const urlEnding = url.substring(urlIdx + 1);
	const [active,setActive] = useState(urlEnding)

	const checkResize = () => {
		if(window.innerWidth < 992){
			setShowMenuToggle(true)
			setShowMenu(false)
		} else {
			setShowMenuToggle(false)
			setShowMenu(true)
		}
  };

	useEffect(() => {
		setSelectedAssociation(association)
    checkResize();
    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
		
  }, [association]);

  return (
		<>
			{showMenuToggle ? <MDBContainer fluid>
						<MDBBtn size="lg" color='tertiary' onClick={() => toggleShow()}>
							<MDBIcon fas icon='bars' />
						</MDBBtn>
			</MDBContainer> : null}
			
		
	<MDBCollapse open={showMenu} tag="nav" className={showMenuToggle ? 'd-lg-block bg-white sidebar' : 'd-lg-block bg-white sidebar float-start'} >
		<div className="position-sticky">
		<h5 className='bg-primary border-3 text-white border-white px-4 p-2 mb-1 border'>Förening - {selectedAssociaion.name}</h5>
			<MDBListGroup className="mx-1 mt-0">
				<div className='bg-light fw-bold px-4'>Medlemmar</div>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "members"} >
						<Link to={"members"} className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("members")) : () => setActive("members")}>
							<MDBIcon fas icon="users" className="fa-fw me-3" />
							Medlemmar
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "license"} >
						<Link to="license" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("license")) : () => setActive("license")}>
							<MDBIcon fas icon="id-card" className="fa-fw me-3" />
							Skyttekort & Tävlingslicens 
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				{/* <div className='bg-light fw-bold px-4'>Anläggning/Skjutbanor</div>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "result"} >
						<Link to="result" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("result")) : () => setActive("result")}>
							<MDBIcon fas icon="house" className="fa-fw me-3" />
							Anläggning
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "result"} >
						<Link to="result" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("result")) : () => setActive("result")}>
							<MDBIcon fas icon="bullseye" className="fa-fw me-3" />
							Skjutbanor 
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<div className='bg-light fw-bold px-4'>Rapport</div>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "result"} >
						<Link to="result" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("result")) : () => setActive("result")}>
							<MDBIcon fas icon="list-ol" className="fa-fw me-3" />
							Statistik 
						</Link>
					</MDBListGroupItem>
				</MDBRipple> */}
			</MDBListGroup>
		</div>
	</MDBCollapse>	
	{/* <MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">
		<MDBRow>
			<MDBCol md="4">
				{multipleAssociations ?
				<BorderCardBody headline="Välj förening" className='mb-4 col-md-12 col-sm-12 square border border-primary rounded-6 mx-1 position-relative bg-white'>
				<MDBCardBody className="p-4 h-100">
					<MDBRow className="pt-1">
						<MDBSelect onChange={(e) => {setSelectedAssociation(associations.find(({forening_id}) => forening_id === e.value))}}
							data={associationData}
						/>
					</MDBRow>
				</MDBCardBody>
			</BorderCardBody> :
			<>
			<h3>{associations[0]}</h3>
			<hr/>
			</>}
				
			</MDBCol>
		</MDBRow>
		
	</MDBContainer> */}
	<Outlet />
</>
);
}