import { fetch_post } from "../../../../services/common";

export async function EditSkyttekort(status,user_key,skyttekortID){

	const response = await fetch_post("api/app/user/do_submit/" + user_key, {
    action: "remove_skyttekort",
    id: skyttekortID,
    db_status: status
	});
		
		
	if (response.message !== "OK") {
		//throw new Error(
		// `This is an HTTP error: The status is ${response.status}`
		//);
	} 
	return  response;
}