import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
  MRT_Table,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import {
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBSelect,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBTooltip,
  MDBListGroup,
  MDBListGroupItem,
  MDBBtnGroup

} from 'mdb-react-ui-kit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useParams, NavLink } from "react-router-dom";
import { fetch_get, fetch_post } from "../../../services/common";
import { EventRubrik } from "./Event_common";

export async function loader() {

  return [];
}

export default function EventAnmalan({ props }) {
  const { id } = useParams();
  const [teams, setTeams] = useState([]);
  const [filter, setFilter] = useState([]);
  const [activefilter, setAktivFilter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [columnFilters, setcolumnFilters] = useState([]);

  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);
  const [formValue, setFormValue] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  useEffect(() => {

    fetch_get('app/event/teams/' + id)
      .then(data => {
        setTeams(data.data);
        setFilter(data.filter);

        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  const onnew = async () => {

    setFormValue(
      {
        action: 'event_team_members',
        key_id: id,
        team_id: "",
        db_team_suffix: "",
        number_of_team_members: "",
        db_event_team_config_id: "",
        db_forening_id: 0,
        db_distrikt_id: 0,
        db_name: "",
        def_event_team_type_id: "",
        members: [],
        klass_arr: []
      });

    setBasicModal(true);

  };

  const onEdit = async (e) => {

     console.log('Edit', e);

    // Hitta objektet med klasser för aktuellt lag
    const result = filter.find(item => item.value === e.original.event_team_config_id);
    const klass_arr_tmp = result.klasser_id.split(',');

    console.log(klass_arr_tmp);

    // Ta fram valda deltagare i laget
    const members_selected = [];
    e.original.members.map((k_item, k) => {
      members_selected.push(k_item.event_deltagare_id);
    });

    setFormValue(
      {
        action: 'event_team_members',
        key_id: id,
        team_id: e.original.id,
        db_team_suffix: e.original.team_suffix,
        number_of_team_members: e.original.number_of_team_members,
        db_event_team_config_id: e.original.event_team_config_id,
        db_forening_id: e.original.forening_id,
        db_distrikt_id: e.original.distrikt_id,
        db_name: e.original.name,
        def_event_team_type_id: e.original.def_event_team_type_id,
        members: members_selected,
        klass_arr: klass_arr_tmp
      });

    setBasicModal(true);

  };

  const ondelete = async (team_id) => {

    const form_params = {
      "action": "event_team_members_delete",
      "team_id": team_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  const onChangeFilter = async (f) => {

    setAktivFilter(f);
    if (f === 0) {
      setcolumnFilters([]);
    } else {
      setcolumnFilters([{ id: 'event_team_config_id', value: f }]);
    }

  };

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'event_team_config_id', //access nested data with dot notation
        header: 'event_team_config_id',
        size: 50,
        enableEditing: false,
        filterFn: 'equals',
      },
      {
        accessorKey: 'team_config_name', //access nested data with dot notation
        header: 'Lagtävling',
        size: 50,
        enableEditing: false,
        filterFn: 'equalsString',
      },
      {
        accessorFn: (row) => `${row.name} ${row.team_suffix}`, //access nested data with dot notation
        header: 'Lagnamn',
        // id: 'lag_namn',
        size: 150,
        enableEditing: false,
      },
      {
        accessorFn: (row) => `${row.members.length} (${row.number_of_team_members})`, //access nested data with dot notation
        header: 'Antal i laget',
        size: 50,
        enableEditing: false,
      },
      // {
      //   accessorKey: 'name', //access nested data with dot notation
      //   header: 'Förening/Distrikt',
      //   size: 150,
      //   enableEditing: false,
      // },

    ],
    [],
  );


  const table = useMaterialReactTable({
    columns: tableColumns,
    data: teams,
    //enableEditing: true,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: true,
    enableStickyHeader: true,
    initialState: {
      density: 'compact',
      //columnFilters:[{ id: 'event_team_config_id', value: null }],
      columnVisibility: { event_team_config_id: false },
      sorting: [{ id: 'team_config_name' }, { id: 'team_config_name' }],
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    enableColumnFilters: false,
    state: { columnFilters },

    //enableGrouping: true,
    localization: MRT_Localization_SV,

    renderDetailPanel: ({ row }) =>
      <LagDeltagare data={row} />,

    renderTopToolbarCustomActions: ({ table }) => (
      <div key_id="1" className="d-flex flex-row mb-3">
        <MDBBtn
          onClick={onnew}
          size='sm'
          className='me-2'
        >
          <MDBIcon fas icon="plus" className='me-2' />Lägg till lag
        </MDBBtn>
        <div key_id="2" className="vr"></div>
        <MDBBtnGroup aria-label='Basic example' size='sm' className="d-grid gap-2 d-md-block">
          <MDBBtn
            color='info'
            href='#'
            outline={activefilter != 0}
            active={activefilter == 0}
            onClick={(x) => onChangeFilter(0)}
          >
            Alla
          </MDBBtn>
          {filter.map((f, k) => (
            <>
              <MDBBtn
                key={k} href='#'
                outline={activefilter != f.value}
                active={activefilter == f.value}
                onClick={(x) => onChangeFilter(f.value)}
                color='info'
              >
                {f.name}
                <MDBBadge className='ms-2' color='light' light pill>{f.team_count}</MDBBadge>
              </MDBBtn>
            </>
          ))}
        </MDBBtnGroup>



      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (

      <>
        <div>
          <div>
            <MDBBtn
              onClick={handleClick}
              size='sm'
              color='secondary'
            >
              Skriv ut / Export<MDBIcon fas icon="caret-down" className='ps-1' />
            </MDBBtn>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}

            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test1 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test2 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test3 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test4 (PDF)
            </MenuItem>
          </Menu>
        </div>
        <MRT_ToggleFullScreenButton table={table} />
        <MDBBtn
          tag='a'
          size='sm'
          // onClick={toggleOpen}
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color='info' size='lg' />
        </MDBBtn>
      </>

    ),
    renderRowActions: ({ row }) => (
      <>
        <MDBTooltip tag='a' wrapperProps={{ color: 'none', className: 'ripple ripple-surface m-1', onClick: () => onEdit(row) }} title="Ändra laget">
          <MDBIcon fas icon='edit' color='primary' />
        </MDBTooltip>
        <div className="vr"></div>
        <MDBPopconfirm
          // style={{ padding: '5px' }}
          color="none"
          modal
          tag="a"
          btnChildren={<MDBIcon far icon="trash-alt" color="danger" />}
          cancelBtnText='Avbryt'
          onConfirm={() => ondelete(row.original.id)}
          cancelBtnClasses='btn-secondary'
          btnClassName='ripple ripple-surface m-1'
          title={"Ta bort laget"}
        >
          <MDBPopconfirmMessage
            icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
            size='lg'
          >
            Ta bort laget: <br /><br />{row.original.team_config_name}<br />{row.original.name} {row.original.team_suffix}?
          </MDBPopconfirmMessage>
        </MDBPopconfirm>
      </>
    ),
  });

  return (
    <>
      <MDBContainer className="py-4">

        <EventRubrik id={id} InfoBtn={false} />
        <MDBRow className='g-3 pt-4'>

          <MaterialReactTable
            table={table}
          />
        </MDBRow>
      </MDBContainer >

      <ModalLagAnmalan
        basicModal={basicModal}
        setBasicModal={setBasicModal}
        toggleOpen={toggleOpen}
        formValue={formValue}
        setFormValue={setFormValue}
        filter={filter}
        setLoadingParent={setLoading}
      />

    </>
  );
}

function LagDeltagare(props) {

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'full_name', //access nested data with dot notation
        header: 'Namn',
        size: 200,
        maxSize: 200,
        enableEditing: false,
      },
      {
        accessorKey: 'klass_name_display', //access nested data with dot notation
        header: 'Klass',
        size: 80,
        enableEditing: false,
      },
      {
        accessorKey: 'forening_name', //access nested data with dot notation
        header: 'Förening',
        size: 200,
        enableEditing: false,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: props.data.original.members,
    enableColumnActions: false,
    initialState: { density: 'compact' },
    enableColumnFilters: false,
    localization: MRT_Localization_SV,
    layoutMode: 'grid-no-grow',
    enableSorting: false
  });

  return (
    <>
      <MRT_Table
        table={table}
        className='ps-5'
      />
    </>
  );
}



function ModalLagAnmalan({ basicModal, setBasicModal, toggleOpen, formValue, setFormValue, filter, setLoadingParent }) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [distriktlov, setDistriktLov] = useState([]);
  const [foreninglov, setForeningLov] = useState([]);
  const [klass_arr, setKlasser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deltagare_lov, setDeltagare_lov] = useState([]);
  const [members_arr, setMembersArr] = useState([]);

  useEffect(() => {

    // if (loading && basicModal) {
    if (basicModal) {
      fetch_get('app/event/deltagare/' + id)
        .then(data => {

          // Gruppera distrikt och förening

          const groupedByDistrikt = {};
          const groupedByForening = {};
          data.data.forEach(item => {
            const { distrikt_id, distrikt_name, forening_id, forening_name } = item;

            // Kontrollera om distriktet redan finns, annars lägg till det
            if (!groupedByDistrikt[distrikt_id]) {
              groupedByDistrikt[distrikt_id] = {
                value: distrikt_id,
                text: distrikt_name,
                antal: 0
              };
            }

            // Kontrollera om förening redan finns, annars lägg till det
            if (!groupedByForening[forening_id]) {
              groupedByForening[forening_id] = {
                value: forening_id,
                text: forening_name,
                antal: 0
              };
            }

            // Öka antalet deltagare för distriktet
            groupedByDistrikt[distrikt_id].antal++;

            // Öka antalet deltagare för forening
            groupedByForening[forening_id].antal++;
          });

          const result_distrikt = Object.values(groupedByDistrikt);
          const result_forening = Object.values(groupedByForening);

          setDistriktLov(result_distrikt);

          setForeningLov(result_forening);

          // END gruppera distrikt och förening

          setData(data.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }

    setMembersArr(formValue.members);


  }, [basicModal]);

  useEffect(() => {
    updateDeltagareLov();
  }, [formValue, loading]);


  const onSubmitEvent = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      setBasicModal(false);

      const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, formValue);

      setLoadingParent(true);
    } else {
      console.log('form not valid');
    }

  };


  const onChange = async (e) => {

    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };


  const updateDeltagareLov = () => {
    // console.log('updateDeltagareLov', formValue.def_event_team_type_id, formValue.klass_arr);
    const members_tmp = [];
    if (formValue.def_event_team_type_id == "3") {
      console.log('updateDeltagareLov2', formValue.def_event_team_type_id);
      // Ta fram deltagare för mix tävling, alla deltagare som ingår i klasser
      data.map((k_item, k) => {
        // console.log('k', k, k_item.id);
        if (formValue.klass_arr.includes(k_item.event_klass_id)) {
          members_tmp.push({ text: k_item.full_name + ' - ' + k_item.klass_name_display + '' + k_item.forening_name, value: k_item.id, secondaryText: k_item.forening_name });
        }
      });
    } else if (formValue.def_event_team_type_id == "2" && formValue.db_distrikt_id != "0") {
      // Ta fram deltagare för aktuellt distrikt
      data.map((k_item, k) => {
        if (formValue.klass_arr.includes(k_item.event_klass_id) && k_item.distrikt_id == formValue.db_distrikt_id) {
          members_tmp.push({ text: k_item.full_name + ' - ' + k_item.klass_name_display + '' + k_item.forening_name, value: k_item.id, secondaryText: k_item.forening_name });
        }
      });
      
    } else if (formValue.def_event_team_type_id == "1" && formValue.db_forening_id != "0") {
      // Ta fram deltagare för aktuell förening
      data.map((k_item, k) => {
        if (formValue.klass_arr.includes(k_item.event_klass_id) && k_item.forening_id == formValue.db_forening_id) {
          members_tmp.push({ text: k_item.full_name + ' - ' + k_item.klass_name_display + ' - ' + k_item.forening_name, value: k_item.id, secondaryText: k_item.forening_name });
        }
      });
    } else {

    }
    

    setDeltagare_lov(members_tmp);
  }

  const onTeamChange = (e) => {
    console.log('e',e);
    const klass_arr_tmp = e.klasser_id.split(',');
    
    if (!loading) {
      setFormValue({
        ...formValue,
        'number_of_team_members': e.number_of_team_members,
        'db_event_team_config_id': e.value,
        'db_forening_id': 0,
        'db_distrikt_id': 0,
        'def_event_team_type_id': e.def_event_team_type_id,
        'klass_arr': klass_arr_tmp
      });
    } else {
      setFormValue({ ...formValue, 'db_event_team_config_id': e.value }); 
      updateDeltagareLov();     
    }
  };

  const onForeningChange = (e) => {
    setFormValue({ ...formValue, 'db_forening_id': e.value, 'db_distrikt_id': 0, 'db_name': e.text });
  };

  const onDistriktChange = (e) => {
    setFormValue({ ...formValue, 'db_distrikt_id': e.value, 'db_forening_id': 0, 'db_name': e.text });
  };

  const onMembersChange = (e) => {
    setFormValue({ ...formValue, 'members': e.map(a => a.value) })
    setMembersArr(e);
  };

  const onCloseModal = () => {

    setFormValue(
      {
        action: 'event_team_members',
        key_id: id,
        team_id: "",
        db_team_suffix: "",
        number_of_team_members: "",
        db_event_team_config_id: "",
        db_forening_id: 0,
        db_distrikt_id: 0,
        db_name: "",
        def_event_team_type_id: "",
        members: [],
        klass_arr: []
      });
    setMembersArr([]);
    setDeltagare_lov([]);
  };

  return (
    <>
      <MDBModal
        open={basicModal}
        staticBackdrop
        // setopen={setBasicModal}
        tabIndex='-1'
        onClose={() => onCloseModal()}
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBValidation onSubmit={onSubmitEvent}>
              <MDBModalHeader>
                <MDBModalTitle>Ny/Ändra Lag</MDBModalTitle>
                <MDBBtn className='btn-close' type='reset' color='none' onClick={toggleOpen}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>

                <MDBRow>
                  <MDBValidationItem className='py-2' invalid feedback='Ange lagtävling.'>
                    {basicModal && (

                      <MDBSelect
                        name='db_event_team_config_id'
                        id='form_event_team_config_id'
                        label='Lagtävling'
                        preventFirstSelection
                        data={filter}
                        required
                        validation
                        value={formValue.db_event_team_config_id}
                        validFeedback={false}
                        invalidFeedback
                        optionHeight={50}
                        onValueChange={(e) => { onTeamChange(e); }} //setFormValue({ ...formValue, 'db_def_event_team_type_id': e.value })}
                      />
                    )}
                  </MDBValidationItem>
                </MDBRow>
                <MDBRow>
                {basicModal && formValue.def_event_team_type_id && (
                  <p>Antal skyttar i laget: {formValue.number_of_team_members}</p>
                )}
                </MDBRow>
                <MDBRow>
                  <MDBValidationItem className='py-2' invalid feedback='Ange distrikt.'>
                    {basicModal && formValue.def_event_team_type_id === "2" && (

                      <MDBSelect
                        name='db_distrikt_id'
                        id='form_distrikt_id'
                        label='Distrikt'
                        preventFirstSelection
                        data={distriktlov}
                        required
                        validation
                        value={formValue.db_distrikt_id}
                        validFeedback={false}
                        invalidFeedback
                        optionHeight={50}
                        onValueChange={(e) => { onDistriktChange(e); }} //setFormValue({ ...formValue, 'db_def_event_team_type_id': e.value })}
                      />
                    )}
                  </MDBValidationItem>
                </MDBRow>
                <MDBRow>
                  <MDBRow>
                    <MDBValidationItem className='py-2' invalid feedback='Ange förening.'>
                      {basicModal && formValue.def_event_team_type_id === "1" && (

                        <MDBSelect
                          name='db_forening_id'
                          id='form_forening_id'
                          label='Förening'
                          preventFirstSelection
                          data={foreninglov}
                          required
                          validation
                          value={formValue.db_forening_id}
                          validFeedback={false}
                          invalidFeedback
                          optionHeight={50}
                          onValueChange={(e) => { onForeningChange(e); }} //setFormValue({ ...formValue, 'db_def_event_team_type_id': e.value })}
                        />
                      )}
                    </MDBValidationItem>
                  </MDBRow>

                  <MDBValidationItem className='py-2 col-8' invalid feedback='-'>
                    {basicModal && (formValue.def_event_team_type_id == '3' || (formValue.def_event_team_type_id == '1' && formValue.db_forening_id != "0") || (formValue.def_event_team_type_id == '2' && formValue.db_distrikt_id != "0")) && (
                      <>
                        <MDBInput
                          name='db_name'
                          id='form_db_name'
                          label={<>Lagnamn</>}
                          type='text'
                          readonly={formValue.def_event_team_type_id != '3'}
                          value={formValue.db_name}
                          onChange={onChange}
                        />

                      </>
                    )}
                  </MDBValidationItem>
                  <MDBValidationItem className='py-2 col-4' invalid feedback='Ange Lagnr/bokstav.'>
                  {basicModal && ((formValue.def_event_team_type_id == '1' && formValue.db_forening_id != "0") || (formValue.def_event_team_type_id == '2' && formValue.db_distrikt_id != "0")) && (
                      <>

                        <MDBInput
                          name='db_team_suffix'
                          id='form_db_team_suffix'
                          size={1}
                          label={<>Lagnr/bokstav</>}
                          type='text'
                          maxlength="1"
                          required
                          value={formValue.db_team_suffix}
                          onChange={onChange}
                        />

                      </>
                    )}
                  </MDBValidationItem> 

                  <MDBValidationItem className='py-2 col-10' invalid feedback='Ange ingående klasser.'>
                    {basicModal && (formValue.def_event_team_type_id == '3' || (formValue.def_event_team_type_id == '1' && formValue.db_forening_id != "0") || (formValue.def_event_team_type_id == '2' && formValue.db_distrikt_id != "0")) && (
                      <MDBSelect
                        className="text-wrap"
                        name='members'
                        id='form_members'
                        displayedLabels={0}
                        label='Ingående lagmedlemmar'
                        optionsSelectedLabel='valda medlemmar'
                        // data={deltagarelov}
                        data={deltagare_lov}
                        multiple
                        validation
                        validFeedback
                        value={formValue.members}
                        invalidFeedback
                        //optionHeight={40}
                        //selectAllLabel="Välj alla"
                        selectAll={false}
                        visibleOptions={6}
                        onValueChange={(e) => onMembersChange(e)}
                      />
                    )}
                  </MDBValidationItem>
                  {members_arr && (
                    <MDBListGroup className='p-3' light small>
                      {members_arr.map((members, k) => (
                        <>
                          <MDBListGroupItem key_id={k}>{members.text}</MDBListGroupItem>
                        </>


                      ))}
                    </MDBListGroup>
                  )}
                </MDBRow>


              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color='secondary' type='reset' onClick={toggleOpen}>
                  Avbryt
                </MDBBtn>
                <MDBBtn type='submit'>Spara</MDBBtn>
              </MDBModalFooter>
            </MDBValidation>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

    </>
  );



}