import React, { useState, useRef, useEffect } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import { useMDBCookies } from 'mdb-react-cookies-management';
import {
  MDBContainer,
  MDBTableBody,
  MDBTable,
  MDBDatatable,
  MDBTabsLink,
  MDBTabs,
  MDBTabsItem,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBIcon,
  MDBBadge,
  MDBCardBody,
  MDBNavbar,
  MDBBtn,
  MDBAlert,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBFile,
  MDBInputGroup,
  MDBSelect,
  MDBInput
} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  useParams,
  Form,
  redirect,
  //useNavigation,
  //useSubmit,
} from "react-router-dom";
import { fetch_get, fetch_post, fetch_post_document } from "../../../services/common";
import EventModalEditSportgren from "./EventOverviewAdminPage_ModalEditSportgren";
// import EventEditStartlista from "./EventProgram";
// import EventEditOmgangarLerduva from "./EventProgramLerduva";
// import EventEditStartlista2 from "./EventEditStartlista2";
// import EventEditDeltagare from "./EventEditDeltagare";
// import EventResult from './EventResult';


export const loader = async ({ params }) => {
  const resJson = await fetch_get('app/event/all/' + params.id);
  return resJson;
  //return [];
};


const mainStyles = {
  rubriker: {
    height: 20,
    position: 'absolute',
    top: '-10px',

  },
  label: {
    fontsize: '0.8rem',
    height: 30,
  }
};

export default function EventsPage() {
  const { id } = useParams();
  const loadObject = useLoaderData();
  const [data, setData] = useState(loadObject);
  //const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [basicActive, setBasicActive] = useState('tab0');
  const [label, setLabel] = useState({ rubrik: "Tävling" });



  useEffect(() => {



    fetch_get('app/event/all/' + id)
      .then(data => {
        console.log('data', data)
        setData(data);
        setLoading(false);
        console.log('data.event_type_id', data.data.event_type_id);
        if (data.data.event_type_id == '-1') {
          console.log('Evenemang');
          setLabel({ rubrik: "Evenemang" });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  // *****************************


  const onsend_for_approval = async () => {

    console.log('onsend_for_approval');
    const form_params = {
      "action": "event_change_status",
      "db_status_id": 100
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  return (
    <>


      <MDBContainer className="py-4">

        <h1 className="fs-1">{label.rubrik} - {data.data.name} <span className="fs-4">({data.data.start_date})</span></h1>

        {(data.data.status_id < 100 && data.data.sportgrenar.length > 0 && data.data.event_type_id != '-1') && (
          <>
            <MDBAlert open className='w-100' color='info'>
              <span className='pe-2'>Skicka in tävlingen för godkännande:</span>

              <MDBPopconfirm
                // outline
                size='sm'
                // floating
                // className='call-btn me-1'
                color="info"
                modal
                btnChildren='Skicka in'

                cancelBtnText='Avbryt'
                onConfirm={() => onsend_for_approval()}
              >
                <MDBPopconfirmMessage
                  icon={<MDBIcon className='me-2' far icon="share-square" />}

                >
                  Vill du skicka in tävlingen för godkännande?
                </MDBPopconfirmMessage>
              </MDBPopconfirm>
            </MDBAlert>
          </>
        )}

        {(data.data.status_id < 100 && data.data.sportgrenar.length === 0 && data.data.event_type_id != '-1') && (
          <>
            <MDBAlert open className='w-100' color='danger'>
              För att skicka in tävlingen för godkännande behöver ni lägga till sportgrenar!
            </MDBAlert>
          </>
        )}

        <EventTab data_arr={data} label={label} setLoading={setLoading}/>

      </MDBContainer>

    </>
  );
}


function EventTab(data_arr) {
  const [basicActive, setBasicActive] = useState('tab0');
  const [data, setProps] = useState(data_arr);
  const [next_version] = useMDBCookies('show_next_version', '');



  useEffect(() => {

    setProps(data_arr);
  }, [data_arr]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
    
  };


  return (
    <>

      <Overview data={data.data_arr.data} label={data_arr.label} />
    </>
  );
}



function Overview(overview_data) {
  // console.log('oo', overview_data);
  const [data, setProps] = useState(overview_data);
  const [sportgren_data, setSportgrenData] = useState(null);
  const [sportgrenarModal, setSportgrenarModal] = useState(false);
  const toggleSportgrenarOpen = () => setSportgrenarModal(!sportgrenarModal);

  const openModal = async (e) => {
    console.log('sdfsdf', e);
    setSportgrenData(e);
    toggleSportgrenarOpen();
  };

  useEffect(() => {
    console.log('overview_data', overview_data);
    setProps(overview_data);
  }, [overview_data]);

  const advancedData = {
    columns: [
      { label: 'Namn', field: 'full_name', sort: true },
      { label: 'Förening', field: 'forening_name', sort: true },
      { label: 'Klass', field: 'klass_name_display', sort: true },
    ],
    rows: data.deltagar_arr,
  };

  return (
    <>
      <MDBNavbar light bgColor='light'>
        <MDBContainer tag="form" fluid className='justify-content-start'>
          <NavLink
            className='btn btn-primary btn-sm me-2'
            as={Link}
            to={'/event/' + data.data.key_id + '/edit'}
          >
            <MDBIcon fas icon="edit" className='me-2' />Ändra
          </NavLink>
          {data.data.event_type_id != '-1' && (
            <NavLink
              className='btn btn-primary btn-sm me-2'
              as={Link}
              to={'/event/' + data.data.key_id + '/betalning'}
            >
              <MDBIcon fas icon="edit" className='me-2' />Betalnnig
            </NavLink>
          )}
        </MDBContainer>
      </MDBNavbar>


      <MDBRow className='g-3 py-3'>
        <MDBCol className='col-md-6 col-sm-12'>
          <MDBRow className='g-3 pe-2'>
            <MDBCard border='primary' className='p-2'>
              <MDBCardBody>
                <MDBBadge light style={mainStyles.rubriker}>{overview_data.label.rubrik}</MDBBadge>

                <MDBTable small>

                  <MDBTableBody>
                    <tr>
                      <th>{overview_data.label.rubrik}</th>
                      <td>{data.data.name}</td>
                    </tr>
                    <tr>
                      <th>Typ</th>
                      <td>{data.data.event_type}</td>
                    </tr>
                    <tr>
                      <th>Kategori</th>
                      <td>{data.data.kategori}</td>
                    </tr>
                    <tr>
                      <th>Arrangör</th>
                      <td>{data.data.arrangorer.map((object, i) => <span key={object.forening_id} className="pe-4">{object.name}</span>)}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        <MDBBadge color={data.data.status_color} pill light>
                          {data.data.status || "Utkast (ej inskickad)"}
                        </MDBBadge>
                        {/* {data.data.status || "Utkast (ej inskickad)"} */}
                      </td>
                    </tr>
                    <tr>
                      <th>Tävlingsdatum</th>
                      <td>{data.data.start_date} till {data.data.end_date}</td>
                    </tr>
                    <tr>
                      <th>Publiceringsdatum</th>
                      <td>{data.data.publicering_date || 'Vid godkänd tävling'}</td>
                    </tr>
                    <tr>
                      <th>Öppen för anmälan</th>
                      <td>{data.data.anmalan_start_date || 'Ej angivet'}</td>
                    </tr>
                    <tr>
                      <th>Sista anmälningsdagen</th>
                      <td>{data.data.anmalan_start_date || 'Ej angivet'}</td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCardBody>
            </MDBCard>
          </MDBRow>
          <MDBRow className='g-3 pe-2 pt-3'>
            <MDBCard border='primary' className='p-2'>
              <MDBCardBody>
                <MDBBadge light style={mainStyles.rubriker}>Sportgrenar</MDBBadge>

                <MDBTable>


                  {data.data.sportgrenar.map(item => (
                    <>
                      <tbody>
                        <tr>
                          <th>Gren</th>
                          <th>Klasser/Startavgift</th>
                        </tr>
                        <tr key={item.sportgren_id}>
                          <td><MDBBtn color='secondary' onClick={(i) => openModal(item)} size='sm' className='ms-3'><MDBIcon fas icon="edit" /></MDBBtn></td>
                          <td>{item.sportgren}</td>
                          <td>
                            <MDBTable>
                              {item.klasser.map(kl => (
                                <>
                                  <tr>
                                    <td className='text-nowrap'>{kl.klass_name}</td>
                                    <td className='text-nowrap'>{kl.startavgift} kr</td>
                                  </tr>
                                </>
                              ))}
                            </MDBTable>
                          </td>
                        </tr>
                      </tbody>
                    </>


                  ))}


                </MDBTable>

              </MDBCardBody>
            </MDBCard>
          </MDBRow>
        </MDBCol>
        <MDBCol className='col-md-6 col-sm-12'>
          <MDBCol className='pb-3'>
            <MDBRow className='g-3 pe-2'>
              <MDBCard border='primary' className='p-2'>
                <MDBCardBody>
                  <MDBBadge light style={mainStyles.rubriker}>Plats</MDBBadge>

                  <MDBTable small>

                    <MDBTableBody>
                      <tr>
                        <th>Plats för tävlingen</th>
                        <td><pre style={{ fontSize: 14.4 }}>{data.data.plats}</pre></td>
                      </tr>
                      <tr>
                        <th>Hemsida</th>
                        <td>{data.data.hemsida || "Ej angivet"}</td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
          </MDBCol>

          <MDBCol className='pb-3'>
            <MDBRow className='g-3 pe-2'>
              <MDBCard border='primary' className='p-2'>
                <MDBCardBody>
                  <MDBBadge light style={mainStyles.rubriker}>Kontaktuppgifter</MDBBadge>

                  <MDBTable small>

                    <MDBTableBody>
                      <tr>
                        <th>Namn</th>
                        <td>{data.data.kontakt_first_name}</td>
                      </tr>
                      <tr>
                        <th>Epost</th>
                        <td>{data.data.kontakt_email}</td>
                      </tr>
                      <tr>
                        <th>Telefon</th>
                        <td>{data.data.kontakt_phone}</td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
          </MDBCol>
          {data.data.event_type_id != '-1' && (
            <MDBCol className='pb-3'>
              <MDBRow className='g-3 pe-2'>
                <MDBCard border='primary' className='p-2'>
                  <MDBCardBody>
                    <MDBBadge light style={mainStyles.rubriker}>Betalning</MDBBadge>

                    <MDBTable small>

                      <MDBTableBody>
                        <tr>
                          <th>SwedbankPay</th>
                          {data.data.betalning_pay == 1 && (
                            <td className='text-success'>{'Aktiverad'}</td>
                          )}
                          {data.data.betalning_pay == 0 && (
                            <td className='text-danger'>{'Ej Aktiverad'}</td>
                          )}
                        </tr>
                        <tr>
                          <th>Kontonummer</th>
                          <td>{data.data.betalning_kontonr}</td>
                        </tr>
                        <tr>
                          <th>Swish nummer</th>
                          <td>{data.data.betalning_swish_nr}</td>
                        </tr>
                        <tr>
                          <th>Sista betalningsdatum</th>
                          <td>{data.data.betalning_end_date}</td>
                        </tr>
                        <tr>
                          <th>Betalningsinformation</th>
                          <td><pre style={{ fontSize: 14.4 }}>{data.data.betalning_information}</pre></td>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>
            </MDBCol>
          )}
        </MDBCol>

      </MDBRow>
      {sportgren_data && (
        <EventModalEditSportgren
          sportgrenarModal={sportgrenarModal}
          setSportgrenarModal={setSportgrenarModal}
          toggleSportgrenarOpen={toggleSportgrenarOpen}
          sportgren_data={sportgren_data}
          setSportgrenData={setSportgrenData}
         
        />

      )}

    </>
  );
}


