export default function CompareDates(sDate,eDate){

	const startDate = new Date(sDate).getTime();
  const endDate = new Date(eDate).getTime();
	const todaysDate = new Date();

	if(startDate < todaysDate && endDate > todaysDate){
		return "ACTIVE"
	} 
	if (startDate > todaysDate) {
		return "PENDING"
	} else {
		return "EXPIRED"
	}
}