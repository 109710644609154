import { fetch_post } from "../../../../services/common";

export async function AddRole(key_id, role_id, obj_id){

	const response = await fetch_post("app/user/do_submit/" + key_id, {
			action: "add_admin",
			db_role_id:role_id,
			db_forening_id: role_id >= 300 && role_id < 400 ? obj_id : 0,
			db_distrikt_id:role_id >= 400 && role_id < 500 ? obj_id : 0,
			db_gren_id:role_id >= 500 && role_id < 600 ? obj_id : 0
	
		});
	
		if (response.message !== "OK") {
			//throw new Error(
			 // `This is an HTTP error: The status is ${response.status}`
			//);
		} 
		return  response;

}