import React, { useState } from 'react';
import {    
  MDBContainer,
	MDBBtn,
	MDBIcon
 } from 'mdb-react-ui-kit';
import { extExcelExport } from '../documents/ExcelExport';
import FileUpload from '../documents/FileUpload';
import PdfExport from '../documents/PdfExport';

export default function TestDocuments() {

	const rows = [
		{ name: "Test", surname: "Testsson"},
		{ name: "Lars", surname: "Pettersson"}
	]

	const columns = ["Namn", "Efternamn"]
  
  return (
    <>
      <MDBContainer className="py-4">
				<PdfExport/>
				<MDBBtn floating onClick={() => extExcelExport(rows,columns,"namn_på_dokumentet")}><MDBIcon fas icon='table'/></MDBBtn>
				<FileUpload/>
      </MDBContainer>
    </>
  );
}