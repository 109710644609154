import React, { useEffect,useState } from 'react';
import {    
  MDBSelect,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBListGroup,
	MDBListGroupItem
 } from 'mdb-react-ui-kit';
 import { AddRole } from '../../users/utils/AddRole';
 import { RemoveRole } from '../../users/utils/RemoveRole';

export default function AssociationRolesModal(params){

	const user = params.sentUser[0];
	const association = params.association;
	const [addRoleDisabled, setAddRoleDisabled] = useState(association.role_list.filter(role => (role.user_key_id === user.user_key_id && role.role_id == "300")).length > 0)
	const [userRoles, setUserRoles] = useState(association.role_list.filter(role => role.user_key_id === user.user_key_id))
	const [roleAddedToast, setRoleAddedToast] = useState(false)
	const [roleErrorToast, setRoleErrorToast] = useState(false)
	const [roleRemovedToast, setRoleRemovedToast] = useState(false)
	const [roleRemovedErrorToast, setRoleRemovedErrorToast] = useState(false)
	const [roleVal,setRoleVal] = useState({ text: 'Föreningsadministratör', value: 300 })

	const addRole = () => {
		
		AddRole(user.user_key_id,roleVal.value,association.id).then(obj => {
			if(obj.status == "OK"){
				setRoleAddedToast((prev) => !prev);
				const nextRole = [
					...userRoles.slice(0, 0),
					{ user_key_id: user.user_key_id, 
						forening_id: association.id,
						forening_name: association.name,
						name: user.user_fullname,
						role_description: "Föreningsadministratör",
						role_id: "300",
						user_id: user.user_id,
					},
					...userRoles.slice(0)
				];
				setUserRoles(nextRole)
				setAddRoleDisabled(true)
			} else {
				setRoleErrorToast((prev) => !prev)
			}
		});
		
	}

	const removeRole = () => {
		
		RemoveRole(user.user_key_id,roleVal.value,association.id).then(obj => {
			if(obj.status == "OK"){
				setRoleRemovedToast((prev) => !prev);
				setUserRoles(association.role_list.filter(role => (role.user_key_id === user.user_key_id && role.role_id !== "300") ))
				setAddRoleDisabled(false)
			} else {
				setRoleRemovedErrorToast((prev) => !prev)
			}
		});
		
	}

	function listRoles(){
		return <MDBListGroup style={{ minWidth: '22rem' }} light small>
						{userRoles.map((role,i) => {
								return <MDBListGroupItem className='d-flex justify-content-between align-items-center' key={user.user_key_id + i}>
													{role.role_description}
													{role.role_id == 300 ? 
													<MDBPopconfirm modal btnChildren='Ta bort roll' onConfirm={() => removeRole()}>
														<MDBPopconfirmMessage>Ta bort roll <b>{roleVal.text}</b> från användare  <b>{user.first_name} {user.last_name}</b></MDBPopconfirmMessage>
													</MDBPopconfirm> : null}
													
												</MDBListGroupItem>
						})}
					</MDBListGroup>
	}

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleAddedToast}
						onClose={() => setRoleAddedToast(false)}
					>
						Roll har lagts till för {user.first_name} {user.last_name}.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleErrorToast}
						onClose={() => setRoleErrorToast(false)}
					>
						Roll kunde inte läggas till.
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleRemovedToast}
						onClose={() => setRoleRemovedToast(false)}
					>
						Roll har tagits bort för {user.first_name} {user.last_name}.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleRemovedErrorToast}
						onClose={() => setRoleRemovedErrorToast(false)}
					>
						Roll kunde inte tas bort.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>Hantera roller - {user ? user.first_name + " " + user.last_name : null}</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
								<MDBModalBody>
							<MDBRow between>
								<MDBCol md={4}>
									<MDBSelect
										label='Gren/diciplin'
										data={[
											{ text: 'Föreningsadministratör', value: 300 },
										]}
										name="role"			
										onChange={(val) => setRoleVal(val)}
									/>
								</MDBCol>
								<MDBCol md={3} className='d-flex justify-content-end'>
									<MDBPopconfirm disabled={addRoleDisabled} modal btnChildren='Lägg till roll' onConfirm={() => addRole()}>
										<MDBPopconfirmMessage>Tilldela roll <b>{roleVal.text}</b> till användare  <b>{user.first_name} {user.last_name}</b></MDBPopconfirmMessage>
									</MDBPopconfirm>
								</MDBCol>
							</MDBRow>
							{userRoles.length > 0 ? 
							<>
								<br/>
								<hr/>
								<br/>
								<h5>Nuvarande roller</h5>
								{listRoles()}
							</> : null}
							</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}