import React, { useState, useEffect ,useRef } from 'react';
import { 
  MDBRow,
  MDBCol,
  MDBAlert,
  MDBDatepicker,
  MDBValidation,
  MDBValidationItem,
  MDBSelect,
  MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog
  } from "mdb-react-ui-kit";
import { fetch_get } from '../../../services/common';
import { AdjustEndDate, CalculateDays, formatDate, GetLatestStartDate, GetLicenseAmount, GetLicensePeriod, newDate } from './utils/GetLicenseDates';
import { AddLicense } from './service/AddLicense';

export default function LicenseStepperModal(params) {
	
  const users = params.sentUser;
  const sportLoader = params.formSelects.sport;
  const eventsLoader = params.formSelects.events;
  const licensesLoader = params.formSelects.licenses;
  const [user,setUser] = useState(users[0]);
  const eventArray = (name, id, formName) => { return { text: name, value: id, name: formName}};
  const eventRows = [];
  const sportArray = (name, id, formName) => { return { text: name, value: id, name: formName}};
  const sportRows = [];
  const licenseArray = (name, id, formName, minAge, maxAge, hidden) => { return { text: name, value: id, name: formName, min_age: minAge, max_age: maxAge, hidden: hidden}};
  const licenseRows = [];
  const initialFormValues = users.map(user => {
    return  {license: null,
            sport: null,
            event: null,
            startDate: "",
            endDate: "",
            price: 0,
            age: (new Date().getFullYear() - new Date(user.birth_date).getFullYear() > 20) ? "Senior" : "Junior",
            userID: user.id,
            name: user.first_name + " " + user.last_name,
						key_id: user.key_id}
  })
  const [formValues, setFormValues] = useState(initialFormValues);
  const [showOptionalInputs, setShowOptionalInputs] = useState(false);
  const [disableStartDate, setDisableStartDate] = useState(true)
  const [disableEndDate, setDisableEndDate] = useState(true)
  const [minDate, setMinDate] = useState(null);
  const pricePerDay = Number(licensesLoader.data.find(obj => obj.id == 8).amount);
  const userAge = new Date().getFullYear() - new Date(user.birth_date).getFullYear();
  const [showAlert,setShowAlert] = useState((user.licenser != null && user.licenser.length > 0) ?
    user.licenser.map(element => {
      if(element.status !== "INACTIVE"){
        return true
      } else {
        return false
      }
    }) : false)
	const [showMissingSkyttekortAlert, setShowMissingSkyttekortAlert] = useState(false)
	const [licenseAddedToast, setLicenseAddedToast] = useState(false)
	const [licenseErrorToast, setLicenseErrorToast] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const [skyttekortExists, setSkyttekortExists] = useState(false);

  sportLoader.data.map(sport => {
    const selectSport = sportArray(sport.text, Number(sport.value), "sport");
    sportRows.push(selectSport);
  },);

  eventsLoader.data.map(event => {
    const selectEvent = eventArray(event.name, Number(event.id), "event");
    eventRows.push(selectEvent);
  },);

  licensesLoader.data.map(license => {
    const selectLicense = licenseArray(license.name, Number(license.id) ,"license", license.min_age, license.max_age, true);
    licenseRows.push(selectLicense);
  });

	useEffect(() => {
		setFormValues(initialFormValues)
		setUser(users[0])
		fetch_get("app/user/skyttekort/" + users[0].key_id).then((data) => {
			setTimeout(() => {
				if(data.data[0]){
					data.data.map(skyttekort => {
						if(skyttekort.status == 'ACTIVE'){
							setSkyttekortExists(true)
							setDisabled(false)
						}
					})
				} else {
					setShowMissingSkyttekortAlert(true)
				}
			}, 2000)
		})
	}, [users]);

  function handleSubmit(e){
    e.preventDefault();
    if(e.target.checkValidity()){
      AddLicense(formValues[0].key_id, formValues[0].startDate, formValues[0].endDate).then(obj => {
				if(obj.status == "OK"){
					setLicenseAddedToast((prev) => !prev);
					setDisabled(true)
				} else {
					setLicenseErrorToast((prev) => !prev)
				}
			});
    } 
  };

  const onStartDateChange = (e) => {
    if(formValues.find(obj => obj.userID == user.id).license){
      if(formValues.find(obj => obj.userID == user.id).license.id == 8){
        if(e !== ""){
          const date = formatDate(e);
          date.setDate(date.getDate() - 1);
          setMinDate(new Date(date));
          if(!formValues.find(obj => obj.userID == user.id).endDate){
            setFormValues(formValues.map(form => {                
              if(form.userID === user.id){
                return { ...form, 
                  ["startDate"]:e,
                  ["endDate"]: e,
                  ["price"]:pricePerDay*CalculateDays(e,e) };
              } else {
                return form;
              }
            })); 
          }
          if(formValues.find(obj => obj.userID == user.id).endDate){
            if(formatDate(e).getTime() > formatDate(formValues.find(obj => obj.userID == user.id).endDate).getTime()){
              setFormValues(formValues.map(form => {
                if(form.userID === user.id){
                  return ({ ...form, 
                    ["startDate"]:e, 
                    ["endDate"]: e,
                    ["price"]:pricePerDay*CalculateDays(e,e) });
                } else {
                  return form;
                }
              }));
            }
            else {
              setFormValues(formValues.map(form => {
                if(form.userID === user.id){
                  return ({ ...form, 
                    ["price"]:pricePerDay*CalculateDays(e,formValues.find(obj => obj.userID == user.id).endDate) });
                } else {
                  return form;
                }
              }));
            };
          }
        } else {
          setFormValues(formValues.map(form => {
            if(form.userID === user.id){
              return { ...form, 
                ["startDate"]:GetLatestStartDate(user.licenser)};
            } else {
              return form;
            }
          }));
        }
      } else {
        const newEndDate = AdjustEndDate(GetLicensePeriod(formValues.find(obj => obj.userID == user.id).license.id, licensesLoader.data),e,licensesLoader.data);
        setFormValues(formValues.map(form => {
          if(form.userID === user.id){
            return { ...form, 
              ["startDate"]: e ,["endDate"]: newEndDate };
          } else {
            return form;
          }
        }));
      } 
    }
  };

  const onEndDateChange = (e) => {
    if(formValues.find(obj => obj.userID == user.id).license){
      if(formValues.find(obj => obj.userID == user.id).license.id == 8){
        if(e !== ""){
          setFormValues(formValues.map(form => {
            if(form.userID === user.id){
              return { ...form, 
                ["endDate"]:e,
                ["price"]: pricePerDay*CalculateDays(formValues.find(obj => obj.userID == user.id).startDate,e)};
            } else {
              return form;
            }
          }));
        }
      }
    }
  };

  const onChange = (e) => {
    if(e.name === "license"){
      if(e.value == 8){
        setDisableEndDate(false);
        setDisableStartDate(false);
        setShowOptionalInputs(true);
        setFormValues(formValues.map(form => {
          if(form.userID === user.id){
            return { ...form, 
              [e.name]: {id:e.value,text:e.text},
              ["startDate"]: GetLatestStartDate(user.licenser),
              ["endDate"]: GetLatestStartDate(user.licenser),
              ["price"]: 0};
          } else {
            return form;
          }
        }))
      }
      else{
        setShowOptionalInputs(false);
        setDisableEndDate(true);
        const fixedStartDate = licensesLoader.data.find(obj => obj.id == e.value).fixed_start_date;
        if(!fixedStartDate){
          setDisableStartDate(false);
          setFormValues(formValues.map(form => {
            if(form.userID === user.id){
              return { ...form, 
                [e.name]: {id:e.value,text:e.text},
                ["startDate"]: GetLatestStartDate(user.licenser),
                ["price"]: Number(GetLicenseAmount(e.value, licensesLoader.data)),
                ["endDate"]: AdjustEndDate(e.value,GetLatestStartDate(user.licenser),licensesLoader.data)};
            } else {
              return form;
            }
          }))
        } else {
          setDisableStartDate(true);
          setFormValues(formValues.map(form => {
            if(form.userID === user.id){
              return { ...form, 
                [e.name]: {id:e.value,text:e.text},
                ["startDate"]: fixedStartDate,
                ["price"]: Number(GetLicenseAmount(e.value, licensesLoader.data)),
                ["endDate"]: AdjustEndDate(e.value,fixedStartDate,licensesLoader.data)};
            } else {
              return form;
            }
          }));
        }
      }
    } else {
      setFormValues(formValues.map(form => {
        if(form.userID === user.id){
          return { ...form, 
            [e.name]: {id:e.value,text:e.text}};
        } else {
          return form;
        }
      }));
    }
  };

	var minStartDate = new Date(GetLatestStartDate(user.licenser));
	minStartDate.setDate(minStartDate.getDate() - 1);  

  return (
    <>
			<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        <MDBModalDialog size="lg">
					<MDBValidation onSubmit={handleSubmit}>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{user.first_name + " " + user.last_name}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
							{showAlert ? <MDBAlert
								className='d-flex justify-content-between'
								defaultOpen
								autohide 
								delay={10000}
								color='warning'
								dismissBtn
								position='bottom-center'>
								<p className='mb-0'>
								Aktiv licens finns redan för {user.first_name + " " + user.last_name}.<br/>
								Startdatum justeras efter när licenserna går ut.
								</p>
							</MDBAlert> : null}
							{showMissingSkyttekortAlert ? <MDBAlert
								className='d-flex justify-content-between'
								defaultOpen
								autohide 
								delay={10000}
								color='warning'
								dismissBtn
								position='bottom-center'>
								<p className='mb-0'>
								Skyttekort saknas för {user.first_name + " " + user.last_name}.
								</p>
							</MDBAlert> : null}
							<MDBAlert
								color='primary'
								autohide
								position='top-right'
								delay={2000}
								appendToBody
								open={licenseAddedToast}
								onClosed={params.close}
								onClose={() => setLicenseAddedToast(false)}
							>
								Licens har lagts till för {user.first_name} {user.last_name}
							</MDBAlert>
							<MDBAlert
								color='danger'
								autohide
								position='top-right'
								delay={2000}
								appendToBody
								open={licenseErrorToast}
								onClose={() => setLicenseErrorToast(false)}
							>
								Licens kunde inte läggas till för {user.first_name} {user.last_name}
							</MDBAlert>
							<MDBRow>
								<MDBCol md='12'>     
									<MDBRow className='d-flex justify-content-center'>
											<MDBRow className='mb-6'>
												<MDBSelect
													className='col-md-5'
													data={licenseRows.map(license => {
														if(license.min_age <= userAge && license.max_age >= userAge){
															license.hidden = false;
														}
														return license
													})}
													label='Typ av licens'
													name="license"                              
													onValueChange={onChange}
													preventFirstSelection
													validation
													validFeedback=''
													invalidFeedback='Ange typ av licens'
												/>
												<MDBSelect
													className='col-md-4'
													label='Gren/diciplin'
													data={sportRows}
													name="sport"
													validation
													validFeedback=''
													invalidFeedback='Ange en gren'
													onValueChange={onChange}
													preventFirstSelection
												/>
											<MDBValidationItem className='col-md-3'>
												<MDBSelect
													label="Åldersklass"
													data={[
														{ text: formValues[0].age }
													]}
													disabled
												/>
											</MDBValidationItem>
											</MDBRow>
											<MDBRow className='mb-3'>
												<MDBValidationItem className='col-md-3'>
													<MDBDatepicker 
														disablePast 
														clearBtnText='' 
														format='yyyy-mm-dd' 
														disabled 
														inputToggle={disableStartDate} 
														className='bg-white text-dark' 
														onChange={onStartDateChange} 
														value={formValues[0].startDate}
														required
														min={minStartDate} 
														name="startDate" 
														label='Startdatum'/>
												</MDBValidationItem>
												<MDBValidationItem className='col-md-3'>
													<MDBDatepicker 
														disablePast 
														clearBtnText='' 
														format='yyyy-mm-dd' 
														disabled 
														inputToggle={disableEndDate} 
														className='bg-white text-dark'
														onChange={onEndDateChange} 
														min={minDate} 
														value={formValues[0].endDate}
														required
														name="endDate" 
														label='Slutdatum'/>
												</MDBValidationItem>
												<MDBValidationItem className={showOptionalInputs ? "d-block col-md-6" : "d-none"}>
													<MDBSelect
														label='Tävling'
														data={eventRows}
														name="event"
														validation={(formValues[0].license && formValues[0].license.id == 8) ? true : false}
														validFeedback=''
														invalidFeedback='Ange en gren'
														onValueChange={onChange}
														preventFirstSelection
													/>
												</MDBValidationItem>
											</MDBRow>
									</MDBRow>
								</MDBCol>
							</MDBRow>
							</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type='button' color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
							<MDBBtn type='submit' disabled={disabled}>
                Lägg till licens
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
					</MDBValidation>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}