import React, { useState, useRef, useEffect } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import {
  MDBContainer,
  MDBTableBody,
  MDBTable,
  MDBDatatable,
  MDBTabsLink,
  MDBTabs,
  MDBTabsItem,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBIcon,
  MDBInput
} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,

  Form,
  redirect,
  //useNavigation,
  //useSubmit,
} from "react-router-dom";






export const loader = async ({ params }) => {
  const res = await fetch('https://dev.svsf-ta.se/api/app/list/categories');
  const resJson = await res.json();

  return resJson;
};


export default function EventsCategories() {
  const loadObject = useLoaderData();
  const [data, setData] = useState(loadObject);

  const advancedData = {
    columns: [
      { label: 'Contact', field: 'contact', sort: false },
      { label: 'Namn', field: 'name', sort: true },
      { label: 'Status', field: 'status', sort: true },
      { label: 'Test', field: 'test', sort: false, width: 10 },
      // { label: 'Test2', field: 'test', sort: false, width: 10 },
    ],
    rows: data.data.map((row) => {
      return {
        ...row,
        contact: (
          <>
            <MDBBtn outline size='sm' floating className='call-btn' onClick={() => console.log(`call ${row.name}`)}>
            <MDBIcon far icon="edit" />
            </MDBBtn>
          </>
        ),
        test: (
          <>
            <MDBInput label='Example label' id='form1' type='text' size='sm' />
          </>
        ),        
      };
    }),
  
  };


  return (
    <>


      <MDBContainer className="py-4">

        <h1>Admin - Kategorier</h1>
        <MDBDatatable
            noFoundMessage='Inga tävlingar...'
            loaderClass='Laddar...'
            ofText='av'
            rowsText='Rader per sida:'
            data={advancedData}
            sm

          />




      </MDBContainer>

    </>
  );
}

