import React, { useState, useRef, useEffect } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBDatatable,
  MDBTable,
  MDBSwitch,
  MDBInput,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownItem,
	MDBDropdownMenu
} from 'mdb-react-ui-kit';
import { MDBTreeview, MDBTreeviewItem } from 'mdb-react-treeview';
import { fetch_get, fetch_post, fetch_delete } from "../../../../services/common";


export default function EventSettingsDiscipline({ params }) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
	const [formValue, setFormValue] = useState({
    className: '',
		value: ''
  });
	const [rowSelected, setRowSelected] = useState(true)

  useEffect(() => {

    fetch_get('app/list/sportgrenar-tree')
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

	/*const treeLoop = (row) => {
		if(row.children){
			return <div key={row.value}>
							<MDBDropdown className='d-flex flex-row-reverse'> 
								<MDBDropdownToggle size='sm' style={{background: 'transparent', boxShadow: 'none'}}><MDBIcon style={{ color: '#0065A4'}} fas icon="ellipsis-h" /></MDBDropdownToggle>
								<MDBDropdownMenu className='align-items-center' style={{padding: 0, marginTop: 0}}>
									<MDBDropdownItem link  onClick={e => console.log(row.value)}>
										<h6>Lägg till klass</h6>
									</MDBDropdownItem>
									<MDBDropdownItem link>
									<h6>Ändra namn</h6>
									</MDBDropdownItem>
									<MDBDropdownItem divider />
									<MDBDropdownItem link>
									<h6 className='text-danger'>Ta bort</h6>
									</MDBDropdownItem>
								</MDBDropdownMenu>
							</MDBDropdown>
							<MDBTreeviewItem className="w-100" subtree name={row.name} id={row.name + "-" + row.value} value={row.value}>
							
								{row.children.map(child => {
									if(child.children){
										return treeLoop(child);
									} else {
										return <div key={child.value} className="d-flex">
														<MDBTreeviewItem className="w-100" name={child.name} id={child.name + "-" + child.value} value={child.value}/>
														<MDBDropdown className='d-flex'> 
															<MDBDropdownToggle size='sm' style={{background: 'transparent', boxShadow: 'none'}}><MDBIcon style={{ color: '#0065A4'}} fas icon="ellipsis-h" /></MDBDropdownToggle>
															<MDBDropdownMenu className='align-items-center' style={{padding: 0, marginTop: 0}}>
																<MDBDropdownItem link onClick={e => console.log(child.value)}>
																	<h6>Lägg till klass</h6>
																</MDBDropdownItem>
																<MDBDropdownItem link>
																<h6>Ändra namn</h6>
																</MDBDropdownItem>
																<MDBDropdownItem divider />
																<MDBDropdownItem link>
																<h6 className='text-danger'>Ta bort</h6>
																</MDBDropdownItem>
															</MDBDropdownMenu>
														</MDBDropdown>
													</div>
									}
								})}
							</MDBTreeviewItem>
							
						</div>
		} else {
			return <div key={row.value}  className="d-flex">
							<MDBTreeviewItem className="w-100" name={row.name} id={row.name + "-" + row.value} value={row.value}/>
							<MDBDropdown> 
								<MDBDropdownToggle size='sm' style={{background: 'transparent', boxShadow: 'none'}}><MDBIcon style={{ color: '#0065A4'}} fas icon="ellipsis-h" /></MDBDropdownToggle>
								<MDBDropdownMenu className='align-items-center' style={{padding: 0, marginTop: 0}}>
									<MDBDropdownItem link onClick={e => console.log(row.value)}>
										<h6>Lägg till klass</h6>
									</MDBDropdownItem>
									<MDBDropdownItem link>
									<h6>Ändra namn</h6>
									</MDBDropdownItem>
									<MDBDropdownItem divider />
									<MDBDropdownItem link>
									<h6 className='text-danger'>Ta bort</h6>
									</MDBDropdownItem>
								</MDBDropdownMenu>
							</MDBDropdown>
						</div>
		}
	} */

	const dropDownMenu = (row) => {
		return <MDBDropdown style={{float: 'right'}}> 
						<MDBDropdownToggle size='sm' style={{background: 'transparent', boxShadow: 'none'}}><MDBIcon style={{ color: '#0065A4'}} fas icon="ellipsis-h" /></MDBDropdownToggle>
						<MDBDropdownMenu className='align-items-center' style={{padding: 0, margin: 0}}>
							<MDBDropdownItem link childTag='button' onClick={e => console.log(row.value)}>
								<h6>Lägg till klass</h6>
							</MDBDropdownItem>
							<MDBDropdownItem link childTag='button'>
							<h6>Ändra namn</h6>
							</MDBDropdownItem>
							<MDBDropdownItem divider />
							<MDBDropdownItem link childTag='button'>
							<h6 className='text-danger'>Ta bort</h6>
							</MDBDropdownItem>
						</MDBDropdownMenu>
					</MDBDropdown>
	}

	const treeLoop = (row) => {
		if(row.children){
			return <MDBTreeviewItem key={row.value} subtree name={<>{row.name}{dropDownMenu(row)}</>} id={row.name + "-" + row.value} value={row.value}>
								{row.children.map(child => {
									if(child.children){
										return treeLoop(child);
									} else {
										return	<MDBTreeviewItem key={child.value} name={<div  className='d-flex justify-content-between w-100'><div>{child.name}</div><div>{dropDownMenu(child)}</div></div>} id={child.name + "-" + child.value} value={child.value}/>
									}
								})}
							</MDBTreeviewItem>
		} else {
			return <MDBTreeviewItem key={row.value} name={<div className='d-flex justify-content-between w-100'><div>{row.name}</div><div>{dropDownMenu(row)}</div></div>} id={row.name + "-" + row.value} value={row.value}/>
		}
	}

	const addRow = (parent) => {

	}
	
	const rowClick = (e) => {
		let name = ""
		if(e.target.id.length <= 0){
			name = e.target.parentElement.id.split('-')[0]
		} else {
			name = e.target.id.split('-')[0]
		}
		if(name.length <= 0){
			setRowSelected(true)
		} else {
			setRowSelected(false)
		}
		setFormValue({ ...formValue, ["className"]: name });
  };

	const onChange = (e) => {
		setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  return (
    <>
			<MDBContainer className="py-4">
				<MDBRow className='d-flex justify-content-center'>
					<h5>Den här sidan håller på att utvecklas för tillfället.</h5>
					<MDBCol className='col-md-6 border border-top-0'>
						<MDBTreeview line openOnItemClick={false}/* onClick={rowClick} */>
							{data.map(row => {
								return treeLoop(row)
							})}
						</MDBTreeview>
					</MDBCol>
					{/* 
					<MDBCol className='col-md-5 square border border-primary rounded-6 p-4 mx-1 position-relative' style={{height:200}}>
						<BorderHeadline headline="Konfigurera klass"/>
							<div className='h-75'>
								<MDBInput
									value={formValue.className}
									name='className'
									onChange={onChange}
									required
									label='Klassnamn'
								/>
							</div>
							<div className='d-flex justify-content-end'>
								<MDBBtn disabled={rowSelected}>Spara</MDBBtn>
							</div>
					</MDBCol> */}
				</MDBRow>
      </MDBContainer >

    </>
  );
}
