import React , { useState, useRef, useEffect } from "react";
import { Navigate } from "react-router-dom";
import AuthService  from '../../services/auth.service';

const ProtectedRoute = ({ component: Component, requiredRoles }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userGrp, setUserGrp] = useState(null);
  const [loading, setLoading] = useState(true);
  const authToken = localStorage.getItem("token");

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await AuthService.is_loggedin();
      const authGrp = await AuthService.getCurrentUserGrp();
      setIsAuthenticated(authStatus);
      setUserGrp(authGrp);
      setLoading(false);
    };

    checkAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Visa en laddningsindikator medan autentisering kontrolleras
  }

  // Om användaren inte är inloggad
  if (!isAuthenticated) {
    return <Navigate to="/login-register" replace />;
  }
  // Om användaren har rollen "APP_USER", alla som är inloggade har denna roll
  if (requiredRoles && requiredRoles.includes("APP_USER")) {
    return <Component />;
  }
  // Om användaren inte har rätt behörighet
  if (requiredRoles && !requiredRoles.some(role => userGrp.func.includes(role))) {
    return <Navigate to="/unauthorized" replace />;
  }

  // Om allt är okej, rendera komponenten
  return <Component />;
};

export default ProtectedRoute;
