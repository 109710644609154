import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBTable,
  MDBAccordionItem,
  MDBAccordion,
  MDBListGroupItem,
  MDBListGroup,
  MDBBtnGroup
} from "mdb-react-ui-kit";
import { useLoaderData, Link, useParams } from "react-router-dom";
import { fetch_get } from "../../../services/common";

export const loader = async ({ params }) => {
  const res = await fetch_json('data/rank_sportgrenar');

  return [];
};

const RankingPageList = () => {
  // const data = useLoaderData();
  // const data = loadObject.data;

  //https://dev.svsf-ta.se/api/app/list/rank-list/143092198?_month=2&_klass=DAM&_count=2


  const [data, setData] = useState([]);
  const [rank_list, setRankList] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const [formValue, setFormValue] = useState({
    active_klass: '%',
    active_month: 12,
    active_count: 4,
    outline_klass: [false, true, true, true, true],
    outline_month: [false, true, true],
    outline_count: [true, true, false, true, true]
  });

  useEffect(() => {

    fetchData();

  }, [formValue]);

  const fetchData = async () => {
    await fetch_get('app/list/rank-list/' + id + '?_month=' + formValue.active_month + '&_klass=' + formValue.active_klass + '&_count=' + formValue.active_count)
      .then(data => {
        setData(data.data);
        setRankList(data.rank_list);
        if (data.rank_list.rolling_periods=='N' && loading) {
         setFormValue({ ...formValue, ['active_count']: data.rank_list.number_of_results });
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };




  console.log(data);

  const handleKlasser = async (e, k) => {
    let o = [true, true, true, true, true];
    o[e] = false;
    setFormValue({ ...formValue, ['outline_klass']: o, ['active_klass']: k });
    console.log(formValue);

  };

  const handleMonth = async (e, k) => {
    let o = [true, true, true];
    o[e] = false;
    setFormValue({ ...formValue, ['outline_month']: o, ['active_month']: k });
    console.log(formValue);

  };


  const handleCount = async (e, k) => {
    let o = [true, true, true, true, true];
    o[e] = false;
    setFormValue({ ...formValue, ['outline_count']: o, ['active_count']: k });
    console.log(formValue);

  };
  return (

    <MDBContainer className="py-4">
      <MDBRow>

        <h4>{rank_list.description}</h4>

      </MDBRow>


      <MDBRow>



        <MDBBtnGroup toolbar role='toolbar' aria-label='Toolbar with button groups'>
          <div className="d-inline-flex flex-column">
            <label className="px-3 d-inline-flex">Klasser</label>
            <MDBBtnGroup className='me-2' aria-label='First Group'>
              <MDBBtn onClick={(e, k) => handleKlasser(0, '%')} outline={formValue.outline_klass[0]}>Alla</MDBBtn>
              <MDBBtn onClick={(e, k) => handleKlasser(1, 'HERR')} outline={formValue.outline_klass[1]}>HERR</MDBBtn>
              <MDBBtn onClick={(e, k) => handleKlasser(2, 'HERR JUN')} outline={formValue.outline_klass[2]}>HERR JUN</MDBBtn>
              <MDBBtn onClick={(e, k) => handleKlasser(3, 'DAM')} outline={formValue.outline_klass[3]}>DAM</MDBBtn>
              <MDBBtn onClick={(e, k) => handleKlasser(4, 'DAM JUN')} outline={formValue.outline_klass[4]}>DAM JUN</MDBBtn>
            </MDBBtnGroup>
          </div>
          {rank_list.rolling_periods=='Y' && (
            <>
          <div className="d-inline-flex flex-column">
            <label className="px-3 d-inline-flex">Rullande månader</label>
            <MDBBtnGroup className='me-2' aria-label='Second Group'>
              <MDBBtn onClick={(e, k) => handleMonth(0, 12)} outline={formValue.outline_month[0]}>12</MDBBtn>
              <MDBBtn onClick={(e, k) => handleMonth(1, 6)} outline={formValue.outline_month[1]}>6</MDBBtn>
              <MDBBtn onClick={(e, k) => handleMonth(2, 3)} outline={formValue.outline_month[2]}>3</MDBBtn>
            </MDBBtnGroup>
          </div>
          <div className="d-inline-flex flex-column">
            <label className="px-3 d-inline-flex">Resultat att räkna</label>
            <MDBBtnGroup aria-label='Third Group'>
              <MDBBtn onClick={(e, k) => handleCount(0, 2)} outline={formValue.outline_count[0]}>2</MDBBtn>
              <MDBBtn onClick={(e, k) => handleCount(1, 3)} outline={formValue.outline_count[1]}>3</MDBBtn>
              <MDBBtn onClick={(e, k) => handleCount(2, 4)} outline={formValue.outline_count[2]}>4</MDBBtn>
              <MDBBtn onClick={(e, k) => handleCount(3, 5)} outline={formValue.outline_count[3]}>5</MDBBtn>
              <MDBBtn onClick={(e, k) => handleCount(4, 6)} outline={formValue.outline_count[4]}>6</MDBBtn>
            </MDBBtnGroup>
          </div>
          </>
          )}
        </MDBBtnGroup>

      </MDBRow>

      <MDBRow>
        <MDBAccordion className="py-4 col col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          {data.map(item => (<>



            <MDBAccordionItem
              collapseId={item.id_user}
              headerTitle={
                <>
                  <strong class="d-inline-flex bd-highlight">{item.rr}</strong>
                  <span class="d-inline-flex bd-highlight flex-column  flex-fill px-3">
                    <strong>{item.first_name} {item.last_name}</strong>
                    <span>{item.class_txt}</span>
                    <span>{item.team_txt}</span>
                    <span class="font-monospace text-muted pt-2 fst-italic" style={{ fontSize: '0.8rem'}}>{item.l_agg}</span>
                  </span>
                  <span class="d-inline-flex flex-column px-2">
                    <span class="badge rounded-pill text-bg-secondary" style={{ fontSize: '1rem'}}>{item.m_sum}</span>
                    <span class="float-end pt-2" style={{ fontSize: '0.8rem'}}>~{(item.m_sum / formValue.active_count).toFixed(2)}</span>
                    <span class="float-end" style={{ fontSize: '0.8rem'}}>~{(item.m_sum / (formValue.active_count * 60)).toFixed(2)}</span>
                    <span class="float-end" style={{ fontSize: '0.8rem'}}># {item.cnt} st</span>
                  </span>

                  {/* <strong class="d-inline-flex bd-highlight">{item.rr}</strong>
                  <span class="d-inline-flex bd-highlight flex-column  flex-fill px-3">
                    <strong>{item.first_name}</strong>
                    <span>{item.class_txt}</span>
                    <span>{item.team_txt}</span>
                    <span class="font-monospace text-muted pt-2 fst-italic" style="font-size:0.8rem;">{item.l_agg}</span>
                  </span>
                  <span class="d-inline-flex flex-column px-2">
                    <span class="badge rounded-pill text-bg-secondary" style="font-size:1rem;">{item.m_sum}</span>
                    <span class="float-end pt-2" style="font-size:0.8rem;">~10.45</span>
                    <span class="float-end" style="font-size:0.8rem;">~10.56</span>
                    <span class="float-end" style="font-size:0.8rem;"># {item.cnt} st</span>
                  </span>                   */}
                </>
              }
              key={item.id_user}

            >

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Tävling</th>
                    <th scope="col">Omg.</th>
                    <th scope="col">Res.</th>
                  </tr>
                </thead>
                <tbody>
                  {item.details.map(item_list => (
                    <>

                      <tr class={item_list.my_rank<=formValue.active_count && ('table-success')}>
                        <td scope="row"><strong>{item_list.start_date}</strong><br />
                          <span >{item_list.name}</span><br />
                          <span >{item_list.organizer}</span>
                        </td>
                        <td>{item_list.round}</td>
                        <td>{item_list.result_total}</td>
                      </tr>

                    </>


                  ))}
                </tbody>
              </table>
            </MDBAccordionItem>
          </>


          ))}
        </MDBAccordion>
      </MDBRow>

    </MDBContainer>
  )
};

export default RankingPageList;
