import React, { useRef, useState, useMemo, useEffect } from 'react';
import {
	MDBContainer,
	MDBSelect,
	MDBAlert,
	MDBBtn,
	MDBIcon,
	MDBNavbar,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBBtnGroup,
	MDBRow,
	MDBCol,
} from 'mdb-react-ui-kit';
import * as xlsx from 'xlsx';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
	MRT_Table,
	MaterialReactTable,
	useMaterialReactTable,
	MRT_ToggleFullScreenButton,
} from 'material-react-table';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useParams, useNavigate, NavLink, Link } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";



export default function EventResultTV() {

	const { id } = useParams();
	const [data, setData] = useState([]);

	// Funktion för att gruppera data
	const groupBy = (array, key) => {
		return array.reduce((result, currentValue) => {
			// Hämta värdet för den aktuella nyckeln
			const groupKey = currentValue[key];
			// Om gruppen inte finns, skapa den
			if (!result[groupKey]) {
				result[groupKey] = [];
			}
			// Lägg till den aktuella raden i gruppen
			result[groupKey].push(currentValue);
			return result;
		}, {});
	};

	useEffect(() => {
		fetch_get('app/event/resultat/' + id + '/' + 63)
			.then(data => {
				// setCompeditorsData(generateTableData(data.data));
				console.log('data', data);
				// Filtrera bort rader som saknar värde på klass_name_display
				const filteredData = data.data.filter(item => item.klass_name_display);

				// Gruppera data baserat på klass_name_display
				const groupedDataObject = groupBy(filteredData, 'klass_name_display');
				// Gruppera data baserat på klass_name_display
				//const groupedDataObject = groupBy(data.data, 'klass_name_display');

				// Konvertera det grupperade objektet till en array
				const groupedDataArray = Object.keys(groupedDataObject).map(key => ({
					klass_name_display: key,
					items: groupedDataObject[key]
				}));

				console.log('groupedDataArray', groupedDataArray);
				setData(groupedDataArray);
			})
			.catch(error => {
				console.error('Error fetching data:', error);
			});


	}, []);

	const tableColumns = useMemo(
		() => [

			// { header: 'Full Name', accessorKey: 'full_name' },
			// { header: 'Forening Name', accessorKey: 'forening_name' },
			{ header: 'Klass Name', accessorKey: 'klass_name_display' },
			// { header: 'Total Dec', accessorKey: 'total_dec' },
		]);

	const table = useMaterialReactTable({
		columns: tableColumns,
		data: data,
		enablePagination: false,
		renderDetailPanel: ({ row }) =>
			<KlassLista data={row} />,
		// enableGrouping: true,
		// // groupedColumnMode,
		// initialState: {
		//   expanded: true, //expand all groups by default
		//   grouping: ['klass_name_display'], //an array of columns to group by by default (can be multiple)
		//   pagination: { pageIndex: 0, pageSize: 20 },
		// },

		muiTableContainerProps: { sx: { maxHeight: '800px' } },

	});

	return (
		<>

			<h1>EventResultTV</h1>
			<MDBContainer>
				<MaterialReactTable
					table={table}
				/>
			</MDBContainer>

		</>
	);
}


function KlassLista(props) {

	console.log(props.data.original.items);

	// Sortera data baserat på total_dec i fallande ordning
	const sortedData = props.data.original.items.sort((a, b) => b.total_dec - a.total_dec);

	const tableColumns = useMemo(
	  () => [
		{
		  accessorKey: 'full_name', //access nested data with dot notation
		  header: 'Namn',
		  size: 200,
		  maxSize: 200,
		  enableEditing: false,
		},
		{
		  accessorKey: 'forening_name', //access nested data with dot notation
		  header: 'Förening',
		  size: 200,
		  enableEditing: false,
		},
		{
			accessorKey: 'total_dec', //access nested data with dot notation
			header: 'S:a',
			size: 50,
			enableEditing: false,
		  },
		
	  ],
	  [],
	);
  
	const table = useMaterialReactTable({
	  columns: tableColumns,
	  data: sortedData, //props.data.original.items,
	//   data: props.data.original.members,
	  enableColumnActions: false,
	  initialState: { density: 'compact' },
	  enableColumnFilters: false,
	  localization: MRT_Localization_SV,
	  layoutMode: 'grid-no-grow',
	  enableSorting: false
	});
  
	return (
	  <>
		<MRT_Table
		  table={table}
		  className='ps-5'
		/>
	  </>
	);
  }