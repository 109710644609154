import React, { useState } from 'react';
import { fetch_json,fetch_post } from "../../../../services/common";
import { useLoaderData,useNavigate } from "react-router-dom";
import { MDBContainer,
	MDBInput,
	MDBBreadcrumb,
	MDBBreadcrumbItem, 
	MDBRow,
	MDBValidation,
  MDBValidationItem,
	MDBTextArea,
	MDBSelect,
	MDBBtn,
	MDBCol,
	MDBIcon,
	MDBPopconfirm,
	MDBPopconfirmMessage
	} from "mdb-react-ui-kit";
import { MDBFileUpload } from 'mdb-react-file-upload';


export async function loader({ params }) {
	const obj = {
		new: params.facility_id === "new" ? true : false,
		sport: await fetch_json("app/list/lov-grenar" , 'GET'),
		facilities: await fetch_json("app/anlaggning/all/" + params.facility_id,'GET'),
		association: await fetch_json("app/forening/all/" + params.association_id,'GET')
	};
	return  obj;
}

export default function FacilityForm() {

	const loadObject = useLoaderData();
  const association = loadObject.association.data;
  const facility = loadObject.facilities.data;
	const sports = loadObject.sport.data;
	const newFacility = loadObject.new;
	const sportArray = (name, id, formName) => { return { text: name, value: id, name: formName}};
  const sportRows = [];
	sports.map(sport => {
    const selectSport = sportArray(sport.text, Number(sport.value), "sport");
    sportRows.push(selectSport);
  },);
	const [rangesToRemove,setRangesToRemove] = useState([]);
	const navigate = useNavigate();

	const [editFacilityForm, setEditFacilityForm] = useState({
		id: newFacility ? "" : facility.id,
    fname: newFacility ?  "" : (facility.name ? facility.name : ""),
		description: newFacility  ? "" : (facility.description ? facility.description : ""),
		lat: newFacility ? "00.00000" : (facility.lat ? facility.lat : ""),
		long: newFacility ? "00.00000" : (facility.long ? facility.long : "")
  });

	const [editFacilityRange, setEditFacilityRange] = useState(facility.banor);

	const addRange = (e) => {
		setEditFacilityRange([...editFacilityRange, {
		anlaggning_id: facility.id,
		ban_system: "Sius",
		ban_system_id: "1",
		id: "new_id" + editFacilityRange.length,
		name: "",
		tavlor: "",
		type: null}]);
	}

	const onSubmit = async (e) => {
    e.preventDefault();
		
		if (e.target.checkValidity()) {
			let resJson = null;
			const facilityObj = {
				action: "add_edit",
				db_name: editFacilityForm.fname,
				db_description: editFacilityForm.description,
				db_lat: editFacilityForm.lat,
				db_long: editFacilityForm.long,
				forening_id: association.id
			};
			if(newFacility){
				resJson = await fetch_post('app/anlaggning/do_submit/0', facilityObj);
			} else {
				resJson = await fetch_post('app/anlaggning/do_submit/' + editFacilityForm.id, facilityObj);
			}
			editFacilityRange.map(async range => {
				const rangeObj = {
					action: "bana_add_edit",
					ban_id: range.id.startsWith("new_id") ? null : range.id,
					db_ban_system_id: 1,
					db_name: range.name, 
					db_type: null, 
					db_tavlor: range.tavlor
				}
				await fetch_post('app/anlaggning/do_submit/' + resJson.msg.id, rangeObj);
			})
			rangesToRemove.map(async range_id => {
				const removeRangeObj = {
					action: "bana_delete",
					ban_id: range_id
				}
				await fetch_post('app/anlaggning/do_submit/' + editFacilityForm.id, removeRangeObj);
			})
			navigate("/team/facilities/" + association.id);
    } else {
      console.log('form not valid');
    }

  }

	const removeRange = (e) => {
		setEditFacilityRange(editFacilityRange.filter(range =>
				range.id !== e.target.id
			))
		if(!e.target.id.startsWith("new_id")){
			setRangesToRemove([...rangesToRemove, e.target.id])
		}
	}

	const onChange = (e) => {
    setEditFacilityForm({ ...editFacilityForm, [e.target.name]: e.target.value });
  };

	const onBanaChange = (e) => {
		setEditFacilityRange(editFacilityRange.map(form => {
			if(form.id === e.target.id){
				return { ...form, 
					[e.target.name]: e.target.value};
			} else {
				return form;
			}
		}))
	}

  return (
    <>
    <MDBContainer className="py-4">
			<MDBBreadcrumb>
				<MDBBreadcrumbItem>
					<a href='/team'>Föreningsinställningar</a>
				</MDBBreadcrumbItem>
				<MDBBreadcrumbItem>
				<a href={"/team/facilities/" + association.id}>Anläggningar</a>
				</MDBBreadcrumbItem>
				<MDBBreadcrumbItem active>{newFacility ? "Ny anläggning" : editFacilityForm.fname + " - Ändra"}</MDBBreadcrumbItem>
			</MDBBreadcrumb>
			<MDBValidation onSubmit={onSubmit} className='row g-3'>
				<MDBRow className='mt-4 d-flex justify-content-center'>
					<MDBValidationItem className='col-md-8'>
						<MDBInput
							value={editFacilityForm.fname}
							name='fname'
							onChange={onChange}
							required
							label='Anläggningsnamn'
							size='lg'
						/>
					</MDBValidationItem>
				</MDBRow>
				<MDBRow className='mt-4 d-flex justify-content-center'>
					<MDBValidationItem className='col-md-8'>
						<MDBTextArea
							value={editFacilityForm.description}
							name='description'
							onChange={onChange}
							label='Beskrivning'
							rows={5}
						/>
					</MDBValidationItem>
				</MDBRow>
				<h5 className="d-flex justify-content-center">Banor</h5>
				<MDBRow className='d-flex justify-content-center mb-4'>
					{editFacilityRange.map((bana,index) => {
						return 	<React.Fragment key={index}>
											<MDBRow className='mt-4 d-flex justify-content-center'>
												<MDBValidationItem className='col-md-3'>
													<MDBInput
														value={bana.name}
														id={bana.id}
														name={'name'}
														onChange={onBanaChange}
														required
														label='Namn'
													/>
												</MDBValidationItem>
												<MDBValidationItem className='col-md-2'>
													<MDBSelect
														label='Gren/diciplin'
														data={sportRows}
														name="sport"/* 
														validation
														validFeedback=''
														invalidFeedback='Ange en gren' */
														preventFirstSelection
													/>
												</MDBValidationItem>
												<MDBValidationItem className='col-md-2'>
													<MDBInput
														value={bana.tavlor}
														id={bana.id}
														name='tavlor'
														onChange={onBanaChange}
														required
														type='number'
														label='Antal tavlor'
														min={1}
														max={100}
														step={1}
													/>
												</MDBValidationItem>
												<MDBBtn tag='a' id={bana.id} color='none' className='col-md-1' onClick={removeRange} style={{ color: '#3b5998' }}>
													<MDBIcon fas id={bana.id} icon='trash-alt' />
												</MDBBtn>
											</MDBRow>
										</React.Fragment>
					})}
				</MDBRow>
				<MDBRow className='mb-2 d-flex justify-content-center'>
					<MDBCol md='8'>
						<MDBBtn type='reset' onClick={addRange}>Lägg till bana</MDBBtn>
					</MDBCol>
				</MDBRow>
				<MDBRow className='mb-2 d-flex justify-content-center mt-4'>
					<MDBCol md='4'>
						<MDBInput
							value={editFacilityForm.lat}
							name='lat'
							onChange={onChange}
							label='Latitude'
						/>
					</MDBCol>
					<MDBCol md='4'>
						<MDBInput
							value={editFacilityForm.long}
							name='long'
							onChange={onChange}
							label='Longitude'
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow className='d-flex justify-content-center mt-4'>
					<MDBCol md='1'/>
					<MDBCol md='3'>
						<MDBFileUpload defaultMessage='Ladda upp logotyp' style={{ height: '150px', width: '150px' }}/>
					</MDBCol>
					<MDBCol md='2' className='d-flex justify-content-end align-items-center'><h5>Nuvarande logo</h5></MDBCol>
					<MDBCol md='2'>
						<img style={{ height: '150px', width: '150px' }} src='https://www.getautismactive.com/wp-content/uploads/2021/01/Test-Logo-Circle-black-transparent.png'></img>
					</MDBCol>
				</MDBRow>
				<hr></hr>
				<MDBRow className='mb-2 d-flex justify-content-center mt-4'>
					<MDBBtn className='col-md-2' type='submit'>Spara ändringar</MDBBtn>
				</MDBRow>
			</MDBValidation>
    </MDBContainer>
    </>
  );
}