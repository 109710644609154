import React, { useEffect,useState } from 'react';
import {    
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBRow,
	MDBSpinner,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBTypography,
 } from 'mdb-react-ui-kit';
 import { fetch_get } from "../../../../services/common";
import { extExcelExport } from '../../../documents/ExcelExport';

export default function DistrictAssociationsModal(params){

	const ioAssociation = JSON.parse(params.association.json)
	const [associationData, setAssociationData] = useState(null)

	function getContactInfo(obj,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.ContactTypeId == typeId){
				contact = obj.ContactInfo
			}
		})
		return contact
	}

	function getParentOrgInfo(obj,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.ParentOrganisationTypeId == typeId){
				contact = obj.FullName
			}
		})
		return contact
	}

	function getAccountInfo(obj,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.AccountTypeId == typeId){
				contact = obj.AccountNumber
			}
		})
		return contact
	}

	function getPersonInfo(obj,roleId,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.Roles[0].RoleType.RoleTypeId === roleId){
				if(typeId === 99){
					contact = obj.FirstName + " " + obj.LastName
				} else {
					obj.Contacts.map(cont => {
						if(cont.ContactTypeId === typeId){
							contact = cont.ContactInfo
						}
					})
				}
			}
		})
		return contact
	}

	useEffect(() => {
		if(ioAssociation){
			setAssociationData([
				{ 
					Namn: ioAssociation.DescribingName, 
					Organisationsnummer: ioAssociation.OrganisationNumber,
					Epost: getContactInfo(ioAssociation.Contacts,1),
					FakturaEpost: getContactInfo(ioAssociation.Contacts,16),
					Hemsida: getContactInfo(ioAssociation.Contacts,4),
					Distrikt: getParentOrgInfo(ioAssociation.ParentBelongings,4),
					Anläggningar: "",
					Adresser: ioAssociation.Addresses.map(address => {
						if(address.AddressTypeId === 3){
							return address.Line1 + " " + address.Line2 + " " + address.PostalNumber + " " + address.PostalCity
						}
					}).toString(),
					Bankgiro: getAccountInfo(ioAssociation.Accounts,1),
					Plusgiro: getAccountInfo(ioAssociation.Accounts,5),
					Swish: getAccountInfo(ioAssociation.Accounts,3),
					OrdförandeNamn: getPersonInfo(ioAssociation.Persons,10,99),
					OrdförandeEpost: getPersonInfo(ioAssociation.Persons,10,1),
					OrdförandeTelefon: getPersonInfo(ioAssociation.Persons,10,5),
					SekreterareNamn: getPersonInfo(ioAssociation.Persons,12,99),
					SekreterareEpost: getPersonInfo(ioAssociation.Persons,12,1),
					SekreterareTelefon: getPersonInfo(ioAssociation.Persons,12,5),
					KassörNamn: getPersonInfo(ioAssociation.Persons,14,99),
					KassörEpost: getPersonInfo(ioAssociation.Persons,14,1),
					KassörTelefon: getPersonInfo(ioAssociation.Persons,14,5),
					}
			])
		}
	}, []);

	const exportAssociation = () => {
		if(associationData){
			extExcelExport(associationData,null,"Förening " + ioAssociation.DescribingName)
		}
		
	}

	return <>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBModalContent> 
							<MDBModalHeader>
								<MDBModalTitle>Föreningsinfo</MDBModalTitle>
								<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
							</MDBModalHeader>
							<MDBModalBody>
							<MDBRow className="justify-content-center align-items-center h-100">
								<MDBCol className="mb-4 mb-lg-0">
									<MDBCard className="mb-3" style={{ borderRadius: '.5rem' }}>
										<MDBRow className="g-0">
												<MDBCardBody className="p-4">
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Namn: </MDBTypography></MDBCol>
															<MDBCol><span className="text-muted">{ioAssociation.DescribingName}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Organisationsnummer: </MDBTypography></MDBCol>
															<MDBCol><span className="text-muted">{ioAssociation.OrganisationNumber}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">E-post:</MDBTypography></MDBCol>
															<MDBCol><span className="text-muted">{getContactInfo(ioAssociation.Contacts,1)}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Faktura e-post:</MDBTypography></MDBCol>
															<MDBCol><span className="text-muted">{getContactInfo(ioAssociation.Contacts,16)}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Hemsida:</MDBTypography></MDBCol>
															<MDBCol><span className="text-muted"><a href={getContactInfo(ioAssociation.Contacts,4)} target='_blank'>{getContactInfo(ioAssociation.Contacts,4)}</a></span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Distrikt:</MDBTypography></MDBCol>
															<MDBCol><span className="text-muted">{getParentOrgInfo(ioAssociation.ParentBelongings,4)}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Anläggningar:</MDBTypography></MDBCol>
															<MDBCol><span className="text-muted"></span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Adresser:</MDBTypography></MDBCol>
															<MDBCol>
																	{ioAssociation.Addresses.map(address => {
																		if(address.AddressTypeId === 3){
																			return <React.Fragment key={address.AddressTypeId}>
																				<div className="text-muted">
																					{address.Line1}
																				</div>
																				<div className="text-muted">
																					{address.Line2}
																				</div>
																				<div className="text-muted">
																					{address.PostalNumber}
																				</div>
																				<div className="text-muted">
																					{address.PostalCity}
																				</div>
																			</React.Fragment>
																		}
																	})}
															</MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Bankgiro:</MDBTypography></MDBCol>
															<MDBCol><span className="text-muted">{getAccountInfo(ioAssociation.Accounts,1)}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Plusgiro:</MDBTypography></MDBCol>
															<MDBCol><span className="text-muted">{getAccountInfo(ioAssociation.Accounts,5)}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Swish:</MDBTypography></MDBCol>
															<MDBCol><span className="text-muted">{getAccountInfo(ioAssociation.Accounts,3)}</span></MDBCol>
														</MDBRow>
														<MDBCol md="6">
															<MDBTypography tag="h5">Ordförande</MDBTypography>
															<hr className="mt-0 mb-1" />
															<MDBRow className="pt-1">
																<MDBRow>
																	<MDBCol md={4}><MDBTypography tag="h6">Namn:</MDBTypography></MDBCol>
																	<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,10,99)}</span></MDBCol>
																</MDBRow>
																<MDBRow>
																	<MDBCol md={4}><MDBTypography tag="h6">E-post:</MDBTypography></MDBCol>
																	<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,10,1)}</span></MDBCol>
																</MDBRow>
																<MDBRow>
																	<MDBCol md={4}><MDBTypography tag="h6">Telefon:</MDBTypography></MDBCol>
																	<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,10,5)}</span></MDBCol>
																</MDBRow>
															</MDBRow>			
														</MDBCol>
														<MDBCol md="6">
															<MDBTypography tag="h5">Sekreterare</MDBTypography>
															<hr className="mt-0 mb-1" />
															<MDBRow className="pt-1">
																<MDBRow>
																	<MDBCol md={4}><MDBTypography tag="h6">Namn:</MDBTypography></MDBCol>
																	<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,12,99)}</span></MDBCol>
																</MDBRow>
																<MDBRow>
																	<MDBCol md={4}><MDBTypography tag="h6">E-post:</MDBTypography></MDBCol>
																	<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,12,1)}</span></MDBCol>
																</MDBRow>
																<MDBRow>
																	<MDBCol md={4}><MDBTypography tag="h6">Telefon:</MDBTypography></MDBCol>
																	<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,12,5)}</span></MDBCol>
																</MDBRow>
															</MDBRow>	
														</MDBCol>
														<MDBCol md="6">
															<MDBTypography tag="h5">Kassör</MDBTypography>
															<hr className="mt-0 mb-1" />
															<MDBRow className="pt-1">
																<MDBRow>
																	<MDBCol md={4}><MDBTypography tag="h6">Namn:</MDBTypography></MDBCol>
																	<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,14,99)}</span></MDBCol>
																</MDBRow>
																<MDBRow>
																	<MDBCol md={4}><MDBTypography tag="h6">E-post:</MDBTypography></MDBCol>
																	<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,14,1)}</span></MDBCol>
																</MDBRow>
																<MDBRow>
																	<MDBCol md={4}><MDBTypography tag="h6">Telefon:</MDBTypography></MDBCol>
																	<MDBCol><span className="text-muted">{getPersonInfo(ioAssociation.Persons,14,5)}</span></MDBCol>
																</MDBRow>
															</MDBRow>		
														</MDBCol>
													</MDBRow>
												</MDBCardBody>
										</MDBRow>
									</MDBCard>
								</MDBCol>
							</MDBRow>
							</MDBModalBody>
							<MDBModalFooter>
								<MDBBtn color='primary' onClick={() => exportAssociation()}>
									Export
								</MDBBtn>
								<MDBBtn color='secondary' onClick={params.close}>
									Stäng
								</MDBBtn>
							</MDBModalFooter>
						</MDBModalContent>
						</MDBModalDialog>

					</MDBModal>
					
        </>
  
}