import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { fetch_post } from "./services/common";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Uppdatera state så nästa render visar fallback UI
    return { hasError: true, error: error };
  }

  async componentDidCatch(error, errorInfo) {
    // Du kan också logga felet till en felrapporteringstjänst
    console.error("ErrorBoundary caught an error", error, errorInfo);

    // Kontrollera om error är ett objekt eller en sträng
    let form_params;
    if (typeof error === 'object' && error !== null) {
    form_params = {
      "ref_name": "app_err",
      "msg": `${error.message} - ${JSON.stringify(error)}`,
      "errorcode": error.code || -9999,
      "ref_id": 0,
      "err_detail": errorInfo ? errorInfo.componentStack : "No component stack available"
    };
    } else {
      form_params = {
        "ref_name": "app_err",
        "msg": error.toString(),
        "errorcode": -9999,
        "ref_id": 0,
        "err_detail": errorInfo ? errorInfo.componentStack : "No component stack available"
      };
    }
  
  
    try {
        const resJson = await fetch_post('app/do_submit/error_log', form_params);
        console.log('resJson', resJson);
        this.setState({ logId: resJson.log_id });
      } catch (fetchError) {
        console.error('Error logging failed:', fetchError);
      }

  }
  render() {
    if (this.state.hasError) {
      // Vänta tills logId är satt innan omdirigering
      if (this.state.logId) {
        return <Navigate to="/error" state={{ error: this.state.error, logId: this.state.logId }} />;
      }
      // Visa en laddningsindikator eller ett meddelande medan logId sätts
      return <div>Laddar felinformation...</div>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;