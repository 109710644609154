import React, { useState, useRef, useEffect } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import { useMDBCookies } from 'mdb-react-cookies-management';
import {
  MDBContainer,
  MDBTableBody,
  MDBTable,
  MDBDatatable,
  MDBTabsLink,
  MDBTabs,
  MDBTabsItem,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBIcon,
  MDBBadge,
  MDBCardBody,
  MDBNavbar,
  MDBBtn,
  MDBAlert,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBFile,
  MDBInputGroup,
  MDBSelect,
  MDBInput
} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  useParams,
  Form,
  redirect,
  //useNavigation,
  //useSubmit,
} from "react-router-dom";
import { fetch_get, fetch_post, fetch_post_document, get_host_api } from "../../../services/common";



export const loader = async ({ params }) => {
  const resJson = await fetch_get('app/event/all/' + params.id);
  return resJson;
  //return [];
};


const mainStyles = {
  rubriker: {
    height: 20,
    position: 'absolute',
    top: '-10px',

  },
  label: {
    fontsize: '0.8rem',
    height: 30,
  }
};

export default function EventsPage() {
  const { id } = useParams();
  const loadObject = useLoaderData();
  const [data, setData] = useState(loadObject);
  //const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [basicActive, setBasicActive] = useState('tab0');
  const [label, setLabel] = useState({ rubrik: "Tävling" });

  useEffect(() => {



    fetch_get('app/event/all/' + id)
      .then(data => {
        console.log('data', data)
        setData(data);
        setLoading(false);
        console.log('data.event_type_id', data.data.event_type_id);
        if (data.data.event_type_id == '-1') {
          console.log('Evenemang');
          setLabel({ rubrik: "Evenemang" });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  // *****************************


  const onsend_for_approval = async () => {

    console.log('onsend_for_approval');
    const form_params = {
      "action": "event_change_status",
      "db_status_id": 100
    };



    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);

  };

  return (
    <>


      <MDBContainer className="py-4">

        <h1 className="fs-1">{label.rubrik} - {data.data.name} <span className="fs-4">({data.data.start_date})</span></h1>

        <EventTab data_arr={data} label={label} />

      </MDBContainer>

    </>
  );
}


function EventTab(data_arr) {
  const [basicActive, setBasicActive] = useState('tab0');
  const [data, setProps] = useState(data_arr);
  const [next_version] = useMDBCookies('show_next_version', '');

  useEffect(() => {

    setProps(data_arr);
  }, [data_arr]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>

      <Dokument doc_arr={data.data_arr.data.documents} />
    </>
  );
}





function Dokument(doc_arr) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [displayname, setDisplayName] = useState(null);
  const [file_type, setFile_type] = useState("1");
  const [file_accept, setFile_accept] = useState(null);
  const [types, setLovTypes] = useState([]);
  const { id } = useParams();

  // Ref object to reference the input element
  const inputFile = useRef(null);


  return (
    <>
      <MDBRow>
        <h6></h6>
        <a  target='_new' href={'https://dev.svsf-ta.se/api/app/event_pdf/startlista/3a02a861-802a-11ef-8355-00505692ffcc/63?_lag_per_page=3&_include_deltagare_not_in_skjutlag=1'}>
         Startlista - Skjutlag
         </a><br/>
         <a  target='_new' href={'https://dev.svsf-ta.se/api/app/event_pdf/startlista-foreningar/3a02a861-802a-11ef-8355-00505692ffcc/63'}>
         Startlista - Förening
         </a><br/>
         <a  target='_new' href={'https://dev.svsf-ta.se/api/app/event_pdf/startlista-klasser/3a02a861-802a-11ef-8355-00505692ffcc/63'}>
         Startlista - Klasser
         </a><br/>
         <a  target='_new' href={'https://dev.svsf-ta.se/api/app/event_pdf/laglista/3a02a861-802a-11ef-8355-00505692ffcc/63'}>
         Startlista - Lagtävling
         </a><br/>
         <a  target='_new' href={'https://dev.svsf-ta.se/api/app/event_pdf/betalnings-smst/3a02a861-802a-11ef-8355-00505692ffcc/63'}>
         Betalnings - Sammanställning
         </a><br/>
         <a  target='_new' href={'https://dev.svsf-ta.se/api/app/event_pdf/prispengar/3a02a861-802a-11ef-8355-00505692ffcc/63'}>
         Betalnings - Prispengar
         </a><br/>
         <a  target='_new' href={'https://dev.svsf-ta.se/api/app/event_pdf/resultatlista-klasser/3a02a861-802a-11ef-8355-00505692ffcc/63'}>
         Resultatlista - Klasser
         </a><br/>
         <a  target='_new' href={'https://dev.svsf-ta.se/api/app/event_pdf/resultatlista-lag/3a02a861-802a-11ef-8355-00505692ffcc/63'}>
         Resultatlista - Lagtävling
         </a><br/>    <br/>
         <br/>
         <br/>  
         <a  target='_new' href={'https://dev.svsf-ta.se/api/app/event_export/sius-csv/3a02a861-802a-11ef-8355-00505692ffcc/63'}>
         Export - SIUS CSV
         </a><br/>

      </MDBRow>


    </>
  );
}

