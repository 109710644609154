import React, { useRef, useState, useMemo, useEffect } from 'react';
import {
	MDBContainer,
	MDBSelect,
	MDBAlert,
	MDBBtn,
	MDBIcon,
	MDBNavbar,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBBtnGroup,
	MDBRow,
	MDBCol,
} from 'mdb-react-ui-kit';
import * as xlsx from 'xlsx';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
	MRT_Table,
	MaterialReactTable,
	useMaterialReactTable,
	MRT_ToggleFullScreenButton,
} from 'material-react-table';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useParams, useNavigate, NavLink, Link } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";
import { extExcelExport } from '../../documents/ExcelExport';
import PdfExport from '../../documents/PdfExport';
import { MyDocument } from '../../documents/MyDocument';
import EventResultImportModal from './EventResultImportModal';

export default function EventResult() {

	const { id } = useParams();
	const [lov_skjutlag, setLovSkjutlag] = useState([]);
	const [skjutlag, setSkjutlag] = useState({ value: 0 });
	const [loading, setLoading] = useState(true);
	const [default_serie, setDefaultSerie] = useState({
		s_1: false,
		s_2: false,
		s_3: false,
		s_4: false,
		s_5: false,
		s_6: false,
		s_7: false,
		s_8: false,
		s_9: false,
		s_10: false,
		s_11: false,
		s_12: false,
		s_13: false,
		s_14: false,
		s_15: false,
		total_dec: false,
		total_omgang_dec:false,
		kval_1: false,
		kval_2: false,
		cb: false,
		need_cb: false,
		sar_fore_final: false,
		sar_fore_final_resultat: false,
		final: false,
		final_resultat: false,
		sar_efter_final: false,
		sar_efter_final_resultst: false,
	});
	const [view_serie, setViewSerie] = useState( default_serie );
	const [activefilter, setAktivFilter] = useState(0);
	const [columnFilters, setcolumnFilters] = useState([]);
	const [compeditorsData, setCompeditorsData] = useState([]);
	const [showEventImportResultModal, setShowEventImportResultModal] = useState(false)
	const [showSave, setShowSave] = useState(false);
	const [omgangar_skjutlag, setOmgangarSkjutlag] = useState([]);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {

		fetch_get('app/event/lov-skjutlag/' + id)
			.then(data => {
				setLovSkjutlag(data.data);
				setLoading(false);
			})
			.catch(error => {
				console.error('Error fetching data:', error);
				setLoading(false);
			});

		fetch_get('app/event/omgangar-skjutlag/' + id)
			.then(data => {
				//data.data.unshift({ "value": "", "text": "-" });
				//console.log('team-lov', data.data);
				setOmgangarSkjutlag(data.data);
				setLoading(false);
			})
			.catch(error => {
				console.error('Error fetching data:', error);
				setLoading(false);
			});


	}, [loading]);

	useEffect(() => {
		if (skjutlag) {
			fetch_get('app/event/skjutlag-resultat/' + id + '/' + skjutlag.value)
				.then(data => {
					// setCompeditorsData(generateTableData(data.data));
					console.log('data', data);
					setCompeditorsData(data.data);
				})
				.catch(error => {
					console.error('Error fetching data:', error);
				});
		}

	}, [skjutlag, loading]);

	const onChangeSkjutlag = async (val) => {
		setSkjutlag(val);
	}

	function round(value, precision) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	const onChangeFilter = async (f, antal_serier, config_json) => {

		console.log('filter', f, antal_serier);
		setSkjutlag({ value: f });
		// let view_arr = [];
		// for (let i = 0; i < 10; i++) {
		// 	if (i < antal_serier) {
		// 		view_arr.push(true);
		// 	} else {
		// 		view_arr.push(false);
		// 	}
		// }
		// setViewSerie(view_arr);
		const j1 = default_serie;
		const j2 = config_json['columnVisibility'];
		const merged1= {...j1, ...j2};
		console.log('m1', config_json,  j2, merged1);
		setViewSerie(
			merged1
		);


		setAktivFilter(f);
		if (f === 0) {
			setcolumnFilters([]);
		} else {
			setcolumnFilters([{ id: 'event_team_config_id', value: f }]);
		}

	};

	function generateTableData(dataArr) {
		return dataArr.map(data => {
			let sum = 0
			data.serie_dec_json.map(res => {
				if (res != null) {
					sum += res;
				}
			})
			const dataRow = {
				id: data.id,
				tavla: data.tavla,
				skjutlag: data.skjutlag,
				name: data.full_name,
				forening: data.forening_name,
				class: data.klass_name_display,
				serie_json: data.serie_dec_json,
				s_1: data.serie_dec_json[0],
				s_2: data.serie_dec_json[1],
				s_3: data.serie_dec_json[2],
				s_4: data.serie_dec_json[3],
				s_5: data.serie_dec_json[4],
				tot_1: round(sum, 1),
				cb: data.extra_sort_value ? round(data.extra_sort_value, 0) : null,
				need_cb: data.need_cb,
				sar_fore_final: data.sar_fore_final,
				sar_fore_final_resultat: data.sar_fore_final_resultat,
				final: data.final,
				final_resultat: data.final_resultat,
				sar_efter_final: data.sar_efter_final,
				sar_efter_final_resultst: data.sar_efter_final_resultst,

			}
			return dataRow
		})
	}



	const updateData = async (value, row, column) => {
		value = value.replace(/,/g, '.');
		const decArr = [];
		console.log('column', column);
		let sum;
		let sum1;
		let sum_tot;
		if (column.id === 'cb' || column.id === 'sar_fore_final' || column.id === 'sar_fore_final_resultat' || column.id === 'final' || column.id === 'final_resultat' || column.id === 'sar_efter_final' || column.id === 'sar_efter_final_resultst') {
			sum = 0;
		} else {
			sum = isNaN(value) ? null : Number(value);
		}
		setCompeditorsData(compeditorsData.map(compeditor => {
			if (compeditor.id === row.original.id) {
				console.log('row', row, value, column, row._valuesCache);
				row._valuesCache[column.id] = value;
				//console.log(Number(row._valuesCache));
				decArr.push(row._valuesCache['s_1'] ? Number(row._valuesCache['s_1']) : null);
				decArr.push(row._valuesCache['s_2'] ? Number(row._valuesCache['s_2']) : null);
				decArr.push(row._valuesCache['s_3'] ? Number(row._valuesCache['s_3']) : null);
				decArr.push(row._valuesCache['s_4'] ? Number(row._valuesCache['s_4']) : null);
				decArr.push(row._valuesCache['s_5'] ? Number(row._valuesCache['s_5']) : null);
				decArr.push(row._valuesCache['s_6'] ? Number(row._valuesCache['s_6']) : null);
				decArr.push(row._valuesCache['s_7'] ? Number(row._valuesCache['s_7']) : null);
				decArr.push(row._valuesCache['s_8'] ? Number(row._valuesCache['s_8']) : null);
				decArr.push(row._valuesCache['s_9'] ? Number(row._valuesCache['s_9']) : null);
				decArr.push(row._valuesCache['s_10'] ? Number(row._valuesCache['s_10']) : null);
				decArr.push(row._valuesCache['s_11'] ? Number(row._valuesCache['s_11']) : null);
				decArr.push(row._valuesCache['s_12'] ? Number(row._valuesCache['s_12']) : null);
				decArr.push(row._valuesCache['s_13'] ? Number(row._valuesCache['s_13']) : null);
				decArr.push(row._valuesCache['s_14'] ? Number(row._valuesCache['s_14']) : null);
				decArr.push(row._valuesCache['s_15'] ? Number(row._valuesCache['s_15']) : null);
				// Object.keys(row.original).forEach(key => {
				// 	console.log('start');
				// 	if (key.startsWith('s_') && key != column.id) {
				// 		sum = sum + Number(row.original[key]);
				// 		decArr.push(row.original[key]);
				// 		console.log('push1', column.id);
				// 	}
				// 	if (key.startsWith('s_') && key == column.id) {
				// 		decArr.push(isNaN(value) || value.length == 0 ? null : Number(round(value, 1)));
				// 		console.log('push2', column.id);
				// 	}
				// 	console.log('end');
				// });
				console.log('cc', decArr);
				sum1 = decArr.reduce((accumulator, current) => accumulator + current);
				let k1 = row._valuesCache['kval_1'] ? Number(row._valuesCache['kval_1']) : 0;
				let k2 = row._valuesCache['kval_2'] ? Number(row._valuesCache['kval_2']) : 0;
				sum_tot = sum1 + k1 + k2;
				return { ...compeditor, total_omgang_dec: round(sum1, 1), total_dec: round(sum_tot, 1), serie_dec_json: decArr, [column.id]: isNaN(value) || value.length == 0 ? null : round(value, 1) }
			} else {
				return compeditor
			}
		}))
		setShowSave(true)
		const jsonObj = {
			action: "event_resultat",
			startlist_id: row.original.id,
			db_total_dec: round(sum_tot, 1) == 0 ? null : round(sum_tot, 1),
			db_total_omgang_dec: round(sum1, 1) == 0 ? null : round(sum1, 1),
			db_kval_1: column.id === 'kval_1' ? value === "" ? null : value : row.original.kval_1,
			db_kval_2: column.id === 'kval_2' ? value === "" ? null : value : row.original.kval_2,
			db_serie_dec_json: decArr,
			db_extra_sort_value: column.id === 'cb' ? value === "" ? null : value : row.original.extra_sort_value,
			db_sar_fore_final: column.id === 'sar_fore_final' ? value === "" ? null : value : row.original.sar_fore_final,
			db_sar_fore_final_resultat: column.id === 'sar_fore_final_resultat' ? value === "" ? null : value : row.original.sar_fore_final_resultat,
			db_final: column.id === 'final' ? value === "" ? null : value : row.original.final,
			db_final_resultat: column.id === 'final_resultat' ? value === "" ? null : value : row.original.final_resultat,
			db_sar_efter_final: column.id === 'sar_efter_final' ? value === "" ? null : value : row.original.sar_efter_final,
			db_sar_efter_final_resultst: column.id === 'sar_efter_final_resultst' ? value === "" ? null : value : row.original.sar_efter_final_resultst,
		}
		await fetch_post('app/event/do_submit/' + id, jsonObj);
		setShowSave(false)
		return decArr
	}


	const [validationErrors, setValidationErrors] = useState({});

	const tableColumns = useMemo(
		() => [
			{
				accessorKey: 'skjutlag', //access nested data with dot notation
				header: 'Skjutlag',
				size: 70,
				enableEditing: false,
			},
			{
				accessorKey: 'tavla', //access nested data with dot notation
				header: 'Tavla2',
				size: 70,
				enableEditing: false,
			},
			{
				accessorKey: 'full_name', //access nested data with dot notation
				header: 'Namn',
				size: 150,
				enableEditing: false,
			},
			{
				accessorKey: 'forening_name', //access nested data with dot notation
				header: 'Förening',
				size: 150,
				enableEditing: false,
			},
			{
				accessorKey: 'klass_name_display', //access nested data with dot notation
				header: 'Klass',
				size: 70,
				enableEditing: false,
			},
			{
				accessorKey: 'kval_1', //normal accessorKey
				header: 'Kval.1',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						const val = updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				accessorKey: 'kval_2', //normal accessorKey
				header: 'Kval.2',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						const val = updateData(event.currentTarget.value, row, column)
					}
				}),
			},						
			{
				accessorFn: (row) => row.serie_dec_json[0], //normal accessorKey
				header: 'S1',
				id: 's_1',
				size: 70,
				// view_serie[0];
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					// error: !!validationErrors.age, //highlight mui text field red error color
					// helperText: validationErrors.age, //show error message in helper text.
					// variant: 'outlined',
					// onChange: (event) => {
					// 	const value = event.target.value;
					// 	//validation logic
					// 	if (!value) {
					// 	  setValidationErrors((prev) => ({ ...prev, age: 'Age is required' }));
					// 	} else if (value < 18) {
					// 	  setValidationErrors({
					// 		...validationErrors,
					// 		age: 'Age must be 18 or older',
					// 	  });
					// 	} else {
					// 	  delete validationErrors.age;
					// 	  setValidationErrors({ ...validationErrors });
					// 	}
					//   },					
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				// accessorKey: 's_2', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[1],
				header: 'S2',
				id: 's_2',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						const val = updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				// accessorKey: 's_3', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[2],
				header: 'S3',
				id: 's_3',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				// accessorKey: 's_4', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[3],
				header: 'S4',
				id: 's_4',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[4],
				header: 'S5',
				id: 's_5',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[5],
				header: 'S6',
				id: 's_6',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},	
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[6],
				header: 'S7',
				id: 's_7',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[7],
				header: 'S8',
				id: 's_8',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},	
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[8],
				header: 'S9',
				id: 's_9',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},	
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[9],
				header: 'S10',
				id: 's_10',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[10],
				header: 'S11',
				id: 's_11',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},	
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[11],
				header: 'S12',
				id: 's_12',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},	
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[12],
				header: 'S13',
				id: 's_13',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},	
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[13],
				header: 'S14',
				id: 's_14',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},	
			{
				// accessorKey: 's_5', //normal accessorKey
				accessorFn: (row) => row.serie_dec_json[14],
				header: 'S15',
				id: 's_15',
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},																							
			{
				accessorKey: 'total_omgang_dec', //normal accessorKey
				header: 'Del.resultat',
				size: 70,
				enableEditing: false,
			},				
			{
				accessorKey: 'total_dec', //normal accessorKey
				header: 'Total',
				size: 70,
				enableEditing: false,
			},
		
			{
				accessorKey: 'cb', //normal accessorKey
				header: 'CB',
				enableSorting: false,
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},

			{
				accessorKey: 'sar_fore_final', //normal accessorKey
				header: 's.f.f',
				enableSorting: false,
				size: 20,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				accessorKey: 'sar_fore_final_resultat', //normal accessorKey
				header: 's.f.f.r',
				enableSorting: false,
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				accessorKey: 'final', //normal accessorKey
				header: 'f.',
				enableSorting: false,
				size: 20,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				accessorKey: 'final_resultat', //normal accessorKey
				header: 'f.r',
				enableSorting: false,
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				accessorKey: 'sar_efter_final', //normal accessorKey
				header: 's.e.f',
				enableSorting: false,
				size: 20,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
			{
				accessorKey: 'sar_efter_final_resultst', //normal accessorKey
				header: 's.e.f.r',
				enableSorting: false,
				size: 70,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'text',
					onBlur: (event) => {
						updateData(event.currentTarget.value, row, column)
					}
				}),
			},
		],
		[compeditorsData],
	);

	function setCellColor(cell, col, row, type) {
		if (type == 'border') {
			if (cell.getValue() != null && col.startsWith('s_')) {
				return '1px solid #000'
			}
			if (col.startsWith('s_')) {
				return '1px dashed #000'
			}
			if (col.startsWith('total_')) {
				return '1px solid #000'
			}
		}
		if (type == 'backgroundColor') {
			if (cell.getValue() != null && col.startsWith('s_')) {
				return '#e3f5e1'
			}
			if (col.startsWith('kval_') && checkRowComplete(row)) {
				return '#a6a6a6'
			}			
			if (col.startsWith('total_dec') && checkRowComplete(row)) {
				return '#66d9ff'
			}
			if (col.startsWith('total_o') && checkRowComplete(row)) {
				return '#ccf2ff'
			}			
			if (col.startsWith('cb') && row.need_cb == 1) {
				console.log('row', row);
				return '#ffcccc'
			}
		}
		return ''
	}

	function checkRowComplete(row) {
		let complete = true;
		Object.keys(row).forEach(key => {
			if (key.startsWith('s_') && !row[key]) {
				complete = false;
			}
		});
		return complete
	}

	const table = useMaterialReactTable({
		columns: tableColumns,
		data: compeditorsData,
		enableEditing: true,
		enableColumnActions: false,
		layoutMode: 'grid-no-grow',
		state: {
			columnVisibility: view_serie
		},
		initialState: {
			density: 'compact',
			// columnVisibility: {
			// 	s_1: false,
			// 	s_2: false,
			// 	s_3: false,
			// 	s_4: false,
			// 	s_5: false,
			// 	total_dec: false,
			// 	cb: false,
			// 	need_cb: false,
			// 	sar_fore_final: false,
			// 	sar_fore_final_resultat: false,
			// 	final: false,
			// 	final_resultat: false,
			// 	sar_efter_final: false,
			// 	sar_efter_final_resultst: false,
			// }
		},
		enableColumnFilters: false,
		editDisplayMode: 'cell',
		enablePagination: false,
		localization: MRT_Localization_SV,

		muiTableBodyCellProps: ({ cell, column, table, row }) => ({
			onClick: () => {
				setCellColor(cell, column.id, row.original)
				table.setEditingCell(cell);
				queueMicrotask(() => {
					const textField = table.refs.editInputRefs.current[column.id];
					if (textField) {
						textField.focus();
						textField.select?.();
					}
				});
			},
			onKeyUp: (event) => {
				console.log(event.key)
			},
			sx: {
				borderRadius: '0',
				border: setCellColor(cell, column.id, row.original, 'border'),
				backgroundColor: setCellColor(cell, column.id, row.original, 'backgroundColor')
			},
		}),
		// renderTopToolbarCustomActions: () => (
		// 	<div className="d-flex flex-row mb-3">

		// 	<div className="vr"></div>
		// 		<MDBBtnGroup aria-label='Basic example' size='sm' className="d-grid gap-2 d-md-block">
		// 			<MDBBtn
		// 				color='info'
		// 				href='#'
		// 				outline={activefilter != 0}
		// 				active={activefilter == 0}
		// 				onClick={(x) => onChangeFilter(0)}
		// 			>
		// 				Alla
		// 			</MDBBtn>
		// 			{lov_skjutlag.map((f, k) => (
		// 				<>
		// 					<MDBBtn
		// 						key={k} href='#'
		// 						outline={activefilter != f.value}
		// 						active={activefilter == f.value}
		// 						onClick={(x) => onChangeFilter(f.value)}
		// 						color='info'
		// 					>
		// 						{f.skjutlag}
		// 						{/* <MDBBadge className='ms-2' color='light' light pill>{f.team_count}</MDBBadge> */}
		// 					</MDBBtn>
		// 				</>
		// 			))}

		// 		</MDBBtnGroup>
		// 		<MDBBtnGroup aria-label='Basic example' size='sm' className="ms-2">

		// 			<MDBBtn
		// 				color='info'
		// 				href='#'
		// 				outline={activefilter != -1000}
		// 				active={activefilter == -1000}
		// 				onClick={(x) => onChangeFilter(-1000)}
		// 			>
		// 				CB lista
		// 			</MDBBtn>

		// 		</MDBBtnGroup>



		// 	</div>
		// ),
		renderTopToolbarCustomActions: () => (
			// <div className="d-flex flex-row mb-3">
			<>
				<MDBRow>

					{omgangar_skjutlag.map((f, k) => (
						<>
							<MDBCol size='10' className='mb-2'>
								<MDBBtnGroup aria-label='Basic example' size='sm' className="d-grid gap-2 d-md-block">
									<MDBBtn
										key={k} href='#'
										outline={activefilter != f.abs_id}
										active={activefilter == f.abs_id}
										//disabled
										onClick={(x) => onChangeFilter(f.abs_id, f.antal_serier, f.config_json)}
										color='info'
									>
										{f.name}

									</MDBBtn>


									{f.skjutlag.map((f2, k2) => (
										<>

											<MDBBtn
												key={k2} href='#'
												outline={activefilter != f2.id}
												active={activefilter == f2.id}
												onClick={(x) => onChangeFilter(f2.id, f.antal_serier, f.config_json)}
												color='info'
											>
												{f2.skjutlag}

											</MDBBtn>

										</>
									))}

								</MDBBtnGroup>
							</MDBCol>
						</>
					))}

				</MDBRow>

				{/* // </div> */}
			</>
		),
		renderToolbarInternalActions: ({ table }) => (

			<>
				<div>
					<div>
						<MDBBtn
							onClick={handleClick}
							size='sm'
							color='secondary'
						>
							Skriv ut / Export<MDBIcon fas icon="caret-down" className='ps-1' />
						</MDBBtn>
					</div>
					<Menu
						anchorEl={anchorEl}
						id="account-menu"
						open={open}
						onClose={handleClose}
						onClick={handleClose}

						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						<MenuItem dense onClick={handleClose}>
							<a href={"https://dev.svsf-ta.se/api/app/event/pdf-skjutkort/" + id} target="_blank"><MDBIcon fas icon="file-pdf" className='me-2' />Skjutkort</a>
						</MenuItem>
						<MenuItem dense onClick={handleClose}>
							<a href={"https://dev.svsf-ta.se/api/app/event/pdf-startlista/" + id} target="_blank"><MDBIcon fas icon="file-pdf" className='me-2' />Startlistalista</a>
						</MenuItem>

						<MenuItem dense onClick={handleClose}>

							<a href={"https://dev.svsf-ta.se/api/app/event/pdf-resultatlista/" + id} target="_blank"><MDBIcon fas icon="file-pdf" className='me-2' />Resultatlista</a>
						</MenuItem>
						{/* <MenuItem dense onClick={handleClose}>
						<PdfExport/>
						</MenuItem> */}
					</Menu>
				</div>
				<MRT_ToggleFullScreenButton table={table} />
				<MDBBtn
					tag='a'
					size='sm'
					// onClick={toggleOpen}
					className="rounded outline btn bg-transparent shadow-0"
				>
					<MDBIcon fas icon="info-circle" color='info' size='lg' />
				</MDBBtn>
			</>

		),
	});

	function createExportData(rawData) {

		console.log(rawData)

		return rawData
	}

	function getColumns() {
		const colArr = ["ID"]
		tableColumns.map(col => {
			colArr.push(col.header)
		})
		return colArr
	}

	function getSumAndTotColumns() {
		const keyArr = []
		Object.keys(compeditorsData[0]).forEach(key => {
			if (key.startsWith('s_')) {
				keyArr.push(key)
			}
			if (key.startsWith('tot_')) {

			}
		});
	}

	return (
		<>

			{/* <MDBNavbar light bgColor='light'>
				<MDBSelect
					style={{ width: '399px' }}
					label="Välj skjutlag"
					data={lov_skjutlag}
					preventFirstSelection
					onValueChange={(e) => onChangeSkjutlag(e)}
				/>
				<MDBDropdown>
					<MDBDropdownToggle color='secondary'>Import / Export</MDBDropdownToggle>
					<MDBDropdownMenu>
						<MDBDropdownItem header><b>Import</b></MDBDropdownItem>
						<MDBDropdownItem link onClick={() => setShowEventImportResultModal(true)}><MDBIcon fas icon="file-excel" className='me-2' />Excel/csv</MDBDropdownItem>
						<MDBDropdownItem divider />
						<MDBDropdownItem header><b>Export</b></MDBDropdownItem>
						<MDBDropdownItem disabled={skjutlag ? false : true} link color='light' onClick={() => extExcelExport(compeditorsData, getColumns(), skjutlag.text)}><MDBIcon fas icon="file-pdf" className='me-2' />Aktuellt lag (PDF)</MDBDropdownItem>
						<MDBDropdownItem link color='light'><MDBIcon fas icon="file-pdf" className='me-2' />Alla lag (PDF)</MDBDropdownItem>
						<MDBDropdownItem link>Sius: shooter.csv</MDBDropdownItem>
						<MDBDropdownItem link>Megalink: export.txt</MDBDropdownItem>
					</MDBDropdownMenu>
				</MDBDropdown>
			</MDBNavbar> */}

			<MDBContainer className="py-4">
				<MaterialReactTable
					table={table}
				/>

				<MDBAlert open={showSave} color='white' autohide position="bottom-right" delay={1000} appendToBody onClose={() => setShowSave(false)}>
					<i className="fa-solid fa-floppy-disk me-3"></i>
				</MDBAlert>
			</MDBContainer>
			{showEventImportResultModal ? <EventResultImportModal show={showEventImportResultModal} close={() => setShowEventImportResultModal(false)} /> : null}
		</>
	);
}
