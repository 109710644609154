import React, { useState } from 'react';
import {    
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBInput,
	MDBValidation,
	MDBValidationItem
 } from 'mdb-react-ui-kit';
import { AddSportgren } from '../../sportgrenar/service/AddSportgren';

export default function AddSportgrenModal(params){

	const sport = params.sport;
	const depth = params.depth;
	const [sportAddedToast, setSportAddedToast] = useState(false)
	const [sportErrorToast, setSportErrorToast] = useState(false)
	const className = sport === 0 ? "Grenkommitté" : depth === 0 ? "Gren" : depth === 1 ? "Disciplin" : depth === 2 ? "Tävlingstyp" : depth === 3 ? "Tävlingstyp" : null
	const [formValue, setFormValue] = useState({
    name: ''
  });

	const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

	function handleSubmit(e){
    e.preventDefault();
    if(e.target.checkValidity()){
			AddSportgren(sport !== 0 ? sport.value : 1,formValue.name,depth === 0 ? "10" : depth === 1 ? "20" : depth === 2 ? "30": depth === 3 ? "40" : "0").then(obj => {
				if(obj.status === "OK"){
					setSportAddedToast(true)
				} else {
					setSportErrorToast(true)
				}
			});
			
    } 
  };

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportAddedToast}
						onClose={() => setSportAddedToast(false)}
					>
						{className} har lagts till.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportErrorToast}
						onClose={() => setSportErrorToast(false)}
					>
						{className} kunde inte läggas till.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBValidation onSubmit={handleSubmit}>
								<MDBModalContent>
									<MDBModalHeader>
										<MDBModalTitle>Lägg till {className}</MDBModalTitle>
										<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
									</MDBModalHeader>
									<MDBModalBody>
										<MDBValidationItem className='col-md-4' feedback='Fyll i ett namn' invalid>
											<MDBInput
												value={formValue.name}
												name='name'
												onChange={onChange}
												required
												label={className + " namn"}
											/>
										</MDBValidationItem>
									</MDBModalBody>
							<MDBModalFooter>
								<MDBBtn color='secondary' onClick={params.close}>
									Stäng
								</MDBBtn>
								<MDBBtn color='primary' type='submit'>
									Lägg till
								</MDBBtn>
							</MDBModalFooter>
						</MDBModalContent>
					</MDBValidation>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}