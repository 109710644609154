import React from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBTable,
  MDBAlert
} from "mdb-react-ui-kit";
//import { ListDeltagare, Test } from "../modules/Events1";

function Test(props) {
  console.log(props);
  console.log(props.show1);
  return (
    <>
      <div>{props.children}</div>
      <MDBAlert {...props} />
      {props.show1 && (
        <>
        <h6>Show...........</h6>
        </>
      )}
    </>
  );
}


const TesterPage = () => (
  <MDBContainer className="py-4">
    <MDBRow>
      <h1>gfsgsgsd</h1>
      {/* <ListDeltagare /> */}
      <Test show1={true} olle pelle={false} ja nej={true} open className='w-50' color='danger'  a="1234" >
        sdfgsdsdgääää
        <h1>dfdfadfafasdf</h1>

      </Test>
    </MDBRow>




  </MDBContainer>
);

export default TesterPage;
