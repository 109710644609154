import React, { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { fetch_get,fetch_json } from "../../services/common";
import { 
  MDBDatatable,
  MDBBtn,
	MDBIcon, 
	MDBContainer,
	MDBInput,
	MDBDropdown,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdownToggle,
	MDBValidation,
	MDBValidationItem,} from "mdb-react-ui-kit";
import LicenseStepperModal from "./license/LicenseStepperModal";
import EditLicenseModal from "./license/EditLicenseModal";
import EditSkyttekortModal from "./skyttekort/EditSkyttekortModal";
import AddSkyttekortModal from "./skyttekort/AddSkyttekortModal";
import { createPortal } from "react-dom";
import { BorderCol } from "../modules/BorderCol";

export async function loader({ params }) {
	const obj = {
		sport: await fetch_json("app/list/lov-grenar" , 'GET'),
		events: await fetch_json("app/list/do_not_use?_select=id, name&_where=event_type_id=999&_table=events" , 'GET'),
		licenses: await fetch_json("app/list/licenser" , 'GET')
	};
	return  obj;
}

export default function SearchUsers() {

	const formOptions = useLoaderData();
    
  const [asyncData, setAsyncData] = useState({
    columns: [
      { label: 'Namn', field: 'name' },
			{ label: 'E-post', field: 'email' },
			{ label: 'Aktiv licens', field: 'licensstatus'},
			{ label: 'Licensnummer', field: 'licens_nr' },
			{ label: 'Förening', field: 'forening' },
			{ label: 'Distrikt', field: 'distrikt'},
			{ label: 'Verktyg', field: 'verktyg', sort: false, width: 50, fixed: 'right'}
    ],
    rows: [],
  });

  const [loading, setLoading] = useState(false);
	const [query, setQuery] = useState('');
	const [searchInput, setSearchInput] = useState('');
	const [emptyMsg, setEmptyMsg] = useState('Sök efter användare');
	const [addLicenseModal, setAddLicenseModal] = useState(false);
	const [editLicenseModal, setEditLicenseModal] = useState(false);
	const [editSkyttekortModal, setEditSkyttekortModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [addSkyttekortModal, setAddSkyttekortModal] = useState(false);

  const toggleAddLicenseModal = (user) => {
		setSelectedUser(user)
		setAddLicenseModal(!addLicenseModal)
	};

	const toggleEditLicenseModal = (user) => {
		setSelectedUser(user)
		setEditLicenseModal(!editLicenseModal)
	};

	const toggleAddSkyttekortModal = (user) => {
		setSelectedUser(user)
		setAddSkyttekortModal(!addSkyttekortModal)
	};

	const toggleEditSkyttekortModal = (user) => {
		setSelectedUser(user)
		setEditSkyttekortModal(!editSkyttekortModal)
	};

	function activeLicense(licenseList){
		let status = false
		if(licenseList){
			for (let i = 0; i < licenseList.length; i++) {
				if(licenseList[i].status == 'ACTIVE') {
					status = true
				}
			} 
		}
		return status
		
	}

	function searchQuery(query){
		const words = query.split(";")
		let fetchString = "";
		for(let i = 0; i < words.length; i++){
			fetchString = fetchString + "(CONCAT(first_name, ' ' , last_name) LIKE '%" + 
			words[i] + "%' OR email LIKE '%" + 
			words[i] + "%' OR licens_nr LIKE '" +
			words[i] + "%' OR user_name LIKE '" +
			words[i] + "%' OR distrikt LIKE '" +
			words[i] + "%' OR forening LIKE '" +
			words[i] + "%')"
			if(i < (words.length - 1)){
				fetchString = fetchString + " AND "
			}
		}
		return fetchString
	}

	useEffect(() => {
		if (query.length >= 3) {
			setLoading(true)
			fetch_get("data/users_lic/?_orderby=first_name&_filter=" + searchQuery(query))
				.then((data) =>
					setTimeout(() => {
						if(data.data.length == 0){
							setEmptyMsg("Inga träffar på sökningen: " + query);
						}
						setAsyncData({
							columns: asyncData.columns,
							rows: data.data.map((user) => ({
								...user,
								name: (
									<a href={"/user-details/" + user.key_id}>
										{user.first_name} {user.last_name}
									</a>
								),
								forening: (
									<a href={"/team/user-admin/" + user.forening_id}>
										{user.forening}
									</a>
								),
								distrikt: (
									<a href={"/district/"}>
										{user.distrikt}
									</a>
								),
								licensstatus: (
									<>
										{activeLicense(user.licenser) ? <p>Ja</p> : <p>Nej</p>}
									</>
								),
								verktyg: (
									<MDBDropdown dropleft> 
										<MDBDropdownToggle size='sm' style={{background: 'transparent', boxShadow: 'none'}}><MDBIcon style={{ color: '#0065A4'}} fas icon="ellipsis-h" /></MDBDropdownToggle>
										{createPortal(
											<MDBDropdownMenu >
												<MDBDropdownItem link childTag='button' onClick={() => toggleAddLicenseModal(user)}>
													<h6>Lägg till licens</h6>
												</MDBDropdownItem>
												<MDBDropdownItem link childTag='button' onClick={() => toggleEditLicenseModal(user)}>
													<h6>Hantera licenser</h6>
												</MDBDropdownItem>
												<MDBDropdownItem divider />
												<MDBDropdownItem link childTag='button' onClick={() => toggleAddSkyttekortModal(user)}>
												<h6>Lägg till skyttekort</h6>
												</MDBDropdownItem>
												<MDBDropdownItem link childTag='button' onClick={() => toggleEditSkyttekortModal(user)}>
												<h6>Hantera skyttekort</h6>
												</MDBDropdownItem>
											</MDBDropdownMenu>
											,document.body)}
									</MDBDropdown>
								)
							})),
						},
						setLoading(false));
					}, 3000)
				);
		}
	}, [query, asyncData.columns]);


  return (
    <MDBContainer className="py-4">
			<h1>Medlemsadministration</h1>
			<BorderCol
          headline="Sökning medlemmar"
          info_id="modal_6"   //
          className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
			<MDBValidation onSubmit={() => setQuery(searchInput)} className="row g-3">
				<MDBValidationItem feedback='' className='col-md-11'>
					<MDBInput onChange={(e) => setSearchInput(e.target.value)}></MDBInput>
				</MDBValidationItem>
				<MDBValidationItem className='col-md-1'>
					<MDBBtn type="submit" className='mb-4'>
						<MDBIcon icon='search' className='ms-2' />
					</MDBBtn>
				</MDBValidationItem>
			</MDBValidation>
			

      <MDBDatatable  data={asyncData} isLoading={loading} loadingMessage={"Söker medlemmar..."} noFoundMessage={emptyMsg}/>
			</BorderCol>
			{(selectedUser !== null && addLicenseModal) ? <LicenseStepperModal sentUser={[selectedUser]} formSelects={formOptions} show={addLicenseModal} close={() => setAddLicenseModal(false)}/> : null}
			{(selectedUser !== null && editLicenseModal) ? <EditLicenseModal sentUser={[selectedUser]} formSelects={formOptions} show={editLicenseModal} close={() => setEditLicenseModal(false)}/> : null}
			{(selectedUser !== null && addSkyttekortModal) ? <AddSkyttekortModal sentUser={[selectedUser]} formSelects={formOptions} show={addSkyttekortModal} close={() => setAddSkyttekortModal(false)}/> : null}
			{(selectedUser !== null && editSkyttekortModal) ? <EditSkyttekortModal sentUser={[selectedUser]} show={editSkyttekortModal} close={() => setEditSkyttekortModal(false)}/> : null}						
    </MDBContainer>
  );
}