import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import {
	MDBRow,
	MDBContainer,

} from 'mdb-react-ui-kit';


export default function Unauthorized() {

	return (
		<>
			<MDBContainer className="py-2 h-100 mt-4">
				<MDBRow>
					<h6>Åtkomst nekad. Du har inte rätt behörighet för denna sida.</h6>
				</MDBRow>

			</MDBContainer>
		</>
	);
}