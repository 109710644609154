import React, { useState } from 'react';
import { MDBFileUpload } from 'mdb-react-file-upload';
import {    
	MDBCard,
	MDBCardBody,
	MDBRow,
	MDBCol,
	MDBBtn
	} from 'mdb-react-ui-kit';

export default function FileUpload() {

	const [files,setFiles] = useState([]);
	const onUpload = () => {
		files.forEach(element => {
			console.log("Uploading: ", element)
		})
		//Gör någon upload
	}

  return (
		<>
    	<MDBFileUpload multiple getInputFiles={(files) => setFiles(files)}/>
			<MDBCard className="text-center rounded-4">
				<MDBCardBody>
					{files.length > 0 ? files.map((file,index) => {
						return (
							<React.Fragment key={index}>
								<MDBRow>
									<MDBCol md='3' className="text-start">{file.name}</MDBCol>
									<MDBCol md='9' className="text-end"><p className="text-muted mb-0">{file.type} {file.size}</p></MDBCol>
								</MDBRow>
								<hr className="hr" />
							</React.Fragment>
						)
					}) : <MDBRow>
								<MDBCol md='12'>Inga filer uppladdade!</MDBCol>
							</MDBRow>
					}
					<MDBBtn hidden={files.length > 0 ? false : true} onClick={onUpload}>Ladda upp</MDBBtn>
				</MDBCardBody>
			</MDBCard>
		</>
  );
}