import { useLoaderData } from "react-router-dom";
import { fetch_json } from "../../services/common";
import { Link } from "react-router-dom";
import { 
  MDBAccordion,
  MDBAccordionItem,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer } from "mdb-react-ui-kit";


export async function loader({ params }) {
  const obj = [];
  // Distrikt
  obj[0] = await fetch_json("data/distrikt/select=id,name&_orderby=name", 'POST');
  // Föreningar
  obj[1] = await fetch_json("data/foreningar/select=id,name,distrikt_id&_orderby=name", 'POST');
  return obj;
}

export default function ListDistrict() {
  const loadObject = useLoaderData();

  const districtList = loadObject[0].data.map(district => {
    return <MDBAccordionItem key={district.id} collapseId={district.id} headerTitle={district.name}>
      <MDBListGroup style={{ minWidth: '22rem' }} light>
      {loadObject[1].data.filter(association => association.distrikt_id == district.id).map(association =>
        <Link to={association.id}><MDBListGroupItem key={association.id} tag='a' href='#' action noBorders className='px-3'>{association.name}</MDBListGroupItem></Link>
      )}
      </MDBListGroup>
    </MDBAccordionItem >;
    }
  );

  return (
    <>
      <MDBContainer className="py-4">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="card mb-4">
              <div className="text-center">
                <MDBAccordion>{districtList}</MDBAccordion>
              </div>
            </div>
          </div>
        </div>
      </MDBContainer>

    </>
  );
}