import { newDate } from "../../license/utils/GetLicenseDates"
import { fetch_post } from "../../../../services/common"

export async function AddSkyttekortMulti(user_arr,sport_id){

	const arr = []

	user_arr.map(user => {
		arr.push({	db_user_id : user.id,
								db_skyttekort_nummer: user.licens_nr,
								db_sportgren_id: sport_id,
								db_def_skyttekort_id: sport_id,
								db_skyttekort_datum: newDate(new Date())
							}) 
	})
	
	const response = await fetch_post("app/user/do_submit/", {
		action: "add_skyttekort_multi",
		user_arr: arr,
	});
	
	if (response.message !== "OK") {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	} 
	return  response;
}