import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBPopconfirm,
  MDBPopconfirmMessage,
} from 'mdb-react-ui-kit';
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
  MRT_Table,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useParams, useNavigate, NavLink, } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";
import { BorderCardBody } from '../../modules/BorderCol';
import { EventRubrik } from "./Event_common";


export default function EventEditLag({ params }) {

  const { id } = useParams();
  const [event_key_id, setEventKeyID] = useState(id);
  const [data_all, setData] = useState([]);
  const [lov_types, setLovTypesData] = useState([]);
  const [lov_klasser, setLovKlasserData] = useState([]);
  const [teams_config, setTeamsConfigData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lag_checked, setLagCheckedData] = useState(false);

  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);
  const [label, setLabel] = useState({ rubrik: "Tävling" });


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [formValue, setFormValue] = useState({
    action: 'event_team_config',
    key_id: id,
    team_id: 0,
    db_def_event_team_type_id: "",
    db_name: "",
    db_number_of_team_members: "",
    db_startavgift: "",
    klasser: []
  });

  useEffect(() => {

    fetch_get('app/event/form_data/' + id)
      .then(data => {
        setData(data.data);
        setLovTypesData(data.data.lov_team_types);
        setLovKlasserData(data.data.lov_team_klasser);
        setTeamsConfigData(data.data.teams_config);
        if (data.data.lag_tavling == 1) {
          setLagCheckedData(true);
        } else {
          setLagCheckedData(false);
        }

        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [loading]);

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };


  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'team_type', //access nested data with dot notation
        header: 'Typ av lagtävling',
        size: 50,
        enableEditing: false,
        filterFn: 'equals',
      },
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Namn',
        size: 50,
        enableEditing: false,
        filterFn: 'equalsString',
      },

      {
        accessorKey: 'number_of_team_members', //access nested data with dot notation
        header: 'Antal skyttar',
        size: 150,
        enableEditing: false,
      },
      {
        accessorKey: 'startavgift', //access nested data with dot notation
        header: 'Startavgift (kr)',
        size: 150,
        enableEditing: false,
      },
      {
        accessorKey: 'klasser', //access nested data with dot notation
        header: 'Ingående klasser',
        size: 150,
        enableEditing: false,
      },

    ],
    [],
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: teams_config,
    //enableEditing: true,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: true,
    enableStickyHeader: true,
    initialState: {
      density: 'compact',
      //columnFilters:[{ id: 'event_team_config_id', value: null }],
      //columnVisibility: { event_team_config_id: false },
      //sorting: [{ id: 'team_config_name' }, { id: 'team_config_name' }],
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    enableColumnFilters: false,
    //state: { columnFilters },

    //enableGrouping: true,
    localization: MRT_Localization_SV,


    renderTopToolbarCustomActions: () => (
      <div className="d-flex flex-row mb-3">



        <MDBBtn
          onClick={onnew}
          size='sm'
          className='me-2'
        >
          <MDBIcon fas icon="plus" className='me-2' />Lägg till ny lagtävling
        </MDBBtn>


      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (

      <>
        <div>
          <div>
            <MDBBtn
              onClick={handleClick}
              size='sm'
              color='secondary'
            >
              Skriv ut / Export<MDBIcon fas icon="caret-down" className='ps-1' />
            </MDBBtn>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}

            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test1 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test2 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test3 (PDF)
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <MDBIcon fas icon="file-pdf" className='me-2' />test4 (PDF)
            </MenuItem>
          </Menu>
        </div>
        <MRT_ToggleFullScreenButton table={table} />
        <MDBBtn
          tag='a'
          size='sm'
          // onClick={toggleOpen}
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color='info' size='lg' />
        </MDBBtn>
      </>

    ),
    renderRowActions: ({ row }) => (
      <>
        <MDBBtn
          // outline 
          color='none'
          size='sm'
          tag='a'
          floating
          className='ripple ripple-surface m-1'
          onClick={() => onedit(row.original.id)}
        >
          <MDBIcon fas icon='edit' color='primary' />
        </MDBBtn>
        <MDBPopconfirm
          // outline
          tag='a'
          // size='sm'
          floating
          className='ripple ripple-surface'
          color="none"
          modal
          btnChildren={<MDBIcon far icon="trash-alt" color="danger" />}
          cancelBtnText='Avbryt'
          onConfirm={() => ondelete(row.original.id)}
        >
          <MDBPopconfirmMessage
            icon={<MDBIcon className='me-2' far icon="trash-alt" />}

          >
            Ta bort lagtävling: {row.original.name}?
          </MDBPopconfirmMessage>
        </MDBPopconfirm>
        {/* <MDBTooltip tag='a' wrapperProps={{ color: 'none', className: 'ripple ripple-surface m-1' }} title="Ändra laget">
          <MDBIcon fas icon='edit' color='primary' />
        </MDBTooltip>
        <div className="vr"></div>
        <MDBTooltip tag='a' wrapperProps={{ color: 'none', className: 'ripple ripple-surface m-1' }} title="Ta bort laget">
          <MDBIcon far icon='trash-alt' color='danger' />
        </MDBTooltip> */}
      </>
    ),
  });


  const onSubmitEvent = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      setBasicModal(false);
      const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, formValue);
      setLoading(true);
    } else {
      console.log('form not valid');
    }

  };

  const ondelete = async (e) => {

    console.log('delete', e);
    const delete_obj =
    {
      action: 'event_team_config_delete',
      key_id: id,
      team_id: e
    };

    const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, delete_obj);
    setLoading(true);

  };

  const onedit = async (e) => {

    const resJson = await fetch_get('app/event/team_data/' + e);

    console.log('edit', e);
    setFormValue(
      {
        action: 'event_team_config',
        key_id: id,
        team_id: e,
        db_def_event_team_type_id: resJson.data.def_event_team_type_id,
        db_name: resJson.data.name,
        db_number_of_team_members: resJson.data.number_of_team_members,
        db_startavgift: resJson.data.startavgift,
        klasser: resJson.data.klasser
      });

    setBasicModal(true);

  };

  const onnew = async () => {

    console.log('new');
    setFormValue(
      {
        action: 'event_team_config',
        key_id: id,
        team_id: 0,
        db_def_event_team_type_id: "",
        db_name: "",
        db_number_of_team_members: "",
        db_startavgift: "",
        klasser: []
      });

    setBasicModal(true);

  };

  return (
    <>

      <MDBContainer className="py-4">

        <EventRubrik id={id} InfoBtn={false} />
        <MDBRow className='g-3 pt-4'>

          <MaterialReactTable
            table={table}
          />

        </MDBRow>



        <MDBModal open={basicModal} staticBackdrop setopen={setBasicModal} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBValidation onSubmit={onSubmitEvent}>
                <MDBModalHeader>
                  <MDBModalTitle>Ny/Ändra Lagtävling</MDBModalTitle>
                  <MDBBtn className='btn-close' type='reset' color='none' onClick={toggleOpen}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>

                  <MDBRow>
                    <MDBValidationItem className='py-2' invalid feedback='Ange typ av lagtävling.'>
                      {basicModal && (

                        <MDBSelect
                          name='db_def_event_team_type_id'
                          id='form_db_def_event_team_type_id'
                          label='Typ av lagtävling'
                          preventFirstSelection
                          data={lov_types}
                          required
                          validation
                          value={formValue.db_def_event_team_type_id}
                          validFeedback={false}
                          invalidFeedback
                          optionHeight={50}
                          onValueChange={(e) => setFormValue({ ...formValue, 'db_def_event_team_type_id': e.value })}
                        />
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2' invalid feedback='Ange lagtävlingens namn.'>
                      {basicModal && (
                        <MDBInput
                          name='db_name'
                          id='form_db_name'
                          label={<>Lagtävlingens namn</>}
                          type='text'
                          required
                          value={formValue.db_name}
                          onChange={onChange}
                        />
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2 col-6' invalid feedback='Ange antal skyttar i laget.'>
                      {basicModal && (
                        <MDBInput
                          name='db_number_of_team_members'
                          id='form_db_number_of_team_members'
                          label={<>Antal skyttar i laget</>}
                          type='number'
                          required
                          value={formValue.db_number_of_team_members}
                          onChange={onChange}
                        />
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2 col-6' invalid feedback='Ange startavgift.'>
                      {basicModal && (
                        <MDBInput
                          name='db_startavgift'
                          id='form_db_startavgift'
                          label={<>Startavgift (kr)</>}
                          type='number'
                          required
                          value={formValue.db_startavgift}
                          onChange={onChange}
                        />
                      )}
                    </MDBValidationItem>
                    <MDBValidationItem className='py-2 col-10' invalid feedback='Ange ingående klasser.'>
                      {basicModal && (
                        <MDBSelect
                          name='klasser'
                          id='form_klasser'
                          displayedLabels={8}
                          label='Ingående klasser'
                          optionsSelectedLabel='klasser valda'
                          data={lov_klasser}
                          multiple
                          validation
                          validFeedback
                          value={formValue.klasser}
                          invalidFeedback
                          optionHeight={40}
                          selectAllLabel="Välj alla"
                          visibleOptions={6}
                          onValueChange={(e) => setFormValue({ ...formValue, 'klasser': e.map(a => a.value) })}
                        />
                      )}
                    </MDBValidationItem>
                  </MDBRow>


                </MDBModalBody>

                <MDBModalFooter>
                  <MDBBtn color='secondary' type='reset' onClick={toggleOpen}>
                    Avbryt
                  </MDBBtn>
                  <MDBBtn type='submit'>Spara</MDBBtn>
                </MDBModalFooter>
              </MDBValidation>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>



      </MDBContainer >

    </>
  );
}

