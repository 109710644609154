import React, { useState, useRef, useEffect } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch
} from 'mdb-react-ui-kit';
import { useParams, useNavigate } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";


const customWeekdaysNarrow = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];
const customMonthsFull = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December',];
const customWeekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
const customMonthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];


export default function EventEditBetalning({ params }) {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pay_checked, setPayCheckedData] = useState(false);
  const [formValue, setFormValue] = useState({
    action: 'event_edit_betalning',
    key_id: id,
    db_name: "-",
    db_betalning_pay: 0,
    db_betalning_kontonr: null,
    // db_betalning_konto_typ: null,
    db_betalning_swish_nr: null,
    db_betalning_information: "",
    db_betalning_end_date: ""
  });



  useEffect(() => {

    fetch_get('app/event/form_data/' + id)
      .then(data => {
        console.log('dddd', data);
        setData(data.data);
        setFormValue({
          action: 'event_edit_betalning',
          key_id: data.data.key_id,
          db_name: data.data.name,
          db_betalning_pay: data.data.betalning_pay,
          db_betalning_kontonr: data.data.betalning_kontonr,
          // db_betalning_konto_typ: data.data.betalning_konto_typ,
          db_betalning_swish_nr: data.data.betalning_swish_nr,
          db_betalning_information: data.data.betalning_information,
          db_betalning_end_date: data.data.betalning_end_date
        });

        if (data.data.betalning_pay == 1) {
          console.log('sätter pay');
          setPayCheckedData(true);
        } else {
          console.log('stänger av pay');
          setPayCheckedData(false);
        }


        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  console.log('data', data.name);


  // const [formValue, setFormValue] = useState({
  //   action: 'event_add_edit',
  //   key_id: data.key_id,
  //   db_name: data.name,
  //   db_event_type_id: data.event_type_id,
  //   db_betalning_pay: data.betalning_pay
  // });

  console.log('formValue', formValue);


  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    console.log(formValue);
  };

  const onSubmitEvent = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      console.log('handleSubmit', formValue);
      const resJson = await fetch_post('app/event/do_submit/' + formValue.key_id, formValue);

      navigate("/event/" + formValue.key_id);
    } else {
      console.log('form not valid');
    }

  };

  const handleCancel = async (e) => {
    e.preventDefault();

    if (formValue.key_id) {
      navigate("/event/" + formValue.key_id);
    } else {
      navigate("/events/");
    }

  };

  const mainStyles = {
    rubriker: {
      height: 20,
      position: 'absolute',
      top: '-10px',

    },
  };

  function checked(status) {
    console.log('st', status);
    if (status == 1) {
      console.log('st true');
      return true;
    } else {
      console.log('st false');
      return false;
    }
  }

  const onChange_checkbox = async (e) => {
    let value = 0;
    if (e.target.checked) {
      console.log('dd', e.target.checked);
      setPayCheckedData(true);
      value = 1;
    } else {
      console.log('dd_dd', e.target.checked);
      setPayCheckedData(false);
    }
    setFormValue({ ...formValue, 'db_betalning_pay': value });

  };

  const onChange_db_betalning_end_date = (date) => {

    setFormValue({ ...formValue, 'db_betalning_end_date': date });
    console.log('db_betalning_end_date', formValue);
  };

  return (
    <>

      {console.log('formValue_2', pay_checked)}
      <MDBContainer className="py-4">

        <MDBValidation onSubmit={onSubmitEvent}>
          <div className='col-12'>
            <MDBBtn type='submit' className='me-2'>Spara</MDBBtn>
            <MDBBtn type='submit' className='me-2' color='secondary' onClick={handleCancel}>Avbryt</MDBBtn>
          </div>
          <MDBRow className='g-3 py-3'>
            <MDBCol className='col-md-8 col-sm-12'>
              <MDBRow className='g-3'>
                <MDBCard border='primary' className='p-2'>

                  <MDBCardBody>
                    <MDBBadge light style={mainStyles.rubriker}>Betalning</MDBBadge>
                    <MDBRow className='p-3'>

                      {/* <MDBCheckbox 
                      name='flexCheck' 
                      value={formValue.db_betalning_pay} 
                      id='flexCheckDefault' 
                      label="Aktivera SwedbankPay för betalningshantering"
                      defaultChecked={{ pay_checked }}
                      />                       */}

                      <MDBSwitch
                        checked={pay_checked}
                        id="form_db_betalning_pay"
                        label="Aktivera SwedbankPay för betalningshantering"
                        onChange={onChange_checkbox}
                      // value={formValue.db_betalning_pay}
                      />
                    </MDBRow>

                    <MDBRow className='p-3'>
                      {/* <MDBRow className={pay_checked ? 'invisible' : 'visible'}> */}
                      <MDBValidationItem className='py-2 col-md-6  col-lg-4' invalid feedback='Ange kontonummer.'>
                        <MDBInput
                          name='db_betalning_kontonr'
                          id='form_betalning_kontonr'
                          label={<>Kontonummer</>}
                          type='text'
                          value={formValue.db_betalning_kontonr}
                          onChange={onChange}
                        // disabled={pay_checked}
                        />
                      </MDBValidationItem>
                      <MDBValidationItem className='py-2 col-md-6  col-lg-4' invalid feedback='Ange Swish nr.'>
                        <MDBInput
                          name='db_betalning_swish_nr'
                          id='form_db_swish_nr'
                          label={<>Swish nummer</>}
                          type='text'
                          value={formValue.db_betalning_swish_nr}
                          onChange={onChange}
                        // disabled={pay_checked}
                        />
                      </MDBValidationItem>
                      <MDBValidationItem feedback='-' invalid className='col-md-6 col-lg-4 py-2'>
                        <MDBDatepicker
                          name='db_betalning_end_date'
                          disablePast
                          //required
                          inputLabel={<>Sista betalningsdatum</>}
                          format='yyyy-mm-dd'
                          startDay={1}
                          onChange={onChange_db_betalning_end_date}
                          defaultValue={formValue.db_betalning_end_date}
                          value={formValue.db_betalning_end_date}
                          //min={setMinDate(formValue.start_date)}
                          monthsFull={customMonthsFull}
                          weekdaysShort={customWeekdaysShort}
                          monthsShort={customMonthsShort}
                          title='Välj datum'
                          cancelBtnText='Avbryt'
                          clearBtnText='Reset'
                          inline
                        // disabled={pay_checked}
                        />
                      </MDBValidationItem>
                      <MDBValidationItem className='py-2 col-md-12  col-lg-8' invalid feedback='Ange en betalningsinformation'>
                        <MDBTextArea
                          name='db_betalning_information'
                          id='form_db_betalning_information'
                          label={<>Betalningsinformation</>}
                          value={formValue.db_betalning_information || ""}
                          onChange={onChange}
                          rows={2}
                        // disabled={pay_checked}
                        />
                      </MDBValidationItem>

                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBRow className='gy-3'>


            <div className='col-12'>
              <MDBBtn type='submit' className='me-2'>Spara</MDBBtn>
              <MDBBtn type='submit' className='me-2' color='secondary' onClick={handleCancel}>Avbryt</MDBBtn>
            </div>

          </MDBRow>
        </MDBValidation>



      </MDBContainer >

    </>
  );
}





