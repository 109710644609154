
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { useCallback } from 'react';
import { utils, writeFile } from 'xlsx';

export const extExcelExport = (rows,columns,fileName = "dokument") => {

	// Namn och Efternamn bildar kolumnnamnen, skickas in i följande format
	/* const testData = [
		{ Namn: "Test Testsson", Efternamn: "då"},
		{ Namn: "Annan Test", Efternamn: "då"}
	] */

	//Exempel hur man exporterar dokument med knapptryck
	//<MDBBtn floating onClick={() => extExcelExport(data,["col1","col2"],"namn_på_dokumentet")}><MDBIcon fas icon='table'/></MDBBtn>
	const letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
	const startNum = columns ? "2" : "1";
	if(!columns){
		columns = [];
		Object.keys(rows[0]).forEach(key => {
			columns.push(key);
		});
	}
	const ws = utils.json_to_sheet(rows);
	/* for(let i = 0; i < letters.length; i++){
		if(isNaN(ws[letters[i] + startNum])){
			console.log(ws[letters[i] + startNum])
		}
	} */
	const wb = utils.book_new();
	utils.book_append_sheet(wb, ws, "Data");
	var max_width = [];
	columns.forEach((element,index) => {
		max_width[index] = element.length + 1;
	})
	rows.map(row => {
		Object.values(row).map((values,index) => {
			if(values){
				if((max_width[index] < values.toString().length) || !max_width[index]){ 
					max_width[index] = values.toString().length;
				}
			}
		})
	})
	if(!ws["!cols"]) ws["!cols"] = [];
	max_width.forEach((element,index) => {
		if(!ws["!cols"][index]) ws["!cols"][index] = {wch: element};
	})
	/* const max_width = rows.reduce((w, r) => Math.max(w, r.surname.length), 10);
  ws["!cols"] = [ { wch: max_width } ]; */
	utils.sheet_add_aoa(ws, [columns], { origin: "A1" });
	writeFile(wb, fileName + ".xlsx");
}

export default function ExcelExport(data) {

	//<ExcelExport data={excelData} fileName={"namn_på_dokument"}></ExcelExport>

	const exportFile = useCallback(() => {
		const ws = utils.json_to_sheet(data.data);
		const wb = utils.book_new();
		utils.book_append_sheet(wb, ws, "Data");
		writeFile(wb, data.fileName + ".xlsx");
	});

	return ( <>
		<MDBBtn floating onClick={exportFile}><MDBIcon fas icon='table'/></MDBBtn>
	</> );
}