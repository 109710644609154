import { useLoaderData,useLocation,useNavigate } from "react-router-dom";
import React, { useState} from 'react';
import { fetch_get } from "../../../services/common";
import {  
	MDBCol,
  MDBContainer,
	MDBListGroup,
	MDBListGroupItem,
	MDBRow,
	MDBBtn
 } from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";
import UserAssociationModal from "./UserAssociationModal";

export async function loader({ params }) {
	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	const obj = {
		user: await fetch_get("app/user/all/" + params.key_id),
		guest: decoded.kid == params.key_id ? false : true
	}
		return obj;
}

export default function UserAssociation() {

	const loadObject = useLoaderData();
	const [associationModal, setAssociationModal] = useState(false);
	const [association, setAssociation] = useState(null);
  const [user, setUser] = useState(loadObject.user.data);
	const toggleAssociationModal = (association) => {
		setAssociation(association)
		setAssociationModal(!associationModal)
	};
	const closeChangedModal = () => {
		setAssociationModal(false)
		setUser({
			...user,
			forening_id: association.forening_id
		});
	}
  
  return (
		<>
			<MDBContainer className="py-4">
				<h3>Föreningar</h3>
				<hr/>
				<MDBRow className='d-flex justify-content-center'>
					<MDBCol md="4">
						{user.foreningar.length == 0 ? "Du är inte med i någon förening." :
							<MDBListGroup style={{ minWidth: '22rem' }} light>
							{user.foreningar.map(forening => {
								return <MDBListGroupItem key={forening.id} className='d-flex justify-content-between align-items-center'>
												{forening.name} {user.forening_id == forening.forening_id ? "(Huvudförening)" : ""}
												<MDBBtn onClick={() => toggleAssociationModal(forening)} size='sm' rounded color='link'>
													Visa
												</MDBBtn>
											</MDBListGroupItem>
							})}
							</MDBListGroup>
						}
					</MDBCol>
				</MDBRow>
				{(associationModal && association) ? <UserAssociationModal association={association} user={user} show={associationModal} closeChanged={() => closeChangedModal()} close={() => setAssociationModal(false)}/> : null}
			</MDBContainer>
		</>
  );
}