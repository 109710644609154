import React from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
	MDBCardTitle,
	MDBCardText,
	MDBListGroup,
	MDBListGroupItem
} from "mdb-react-ui-kit";

const WorkInProgress = () => (
	<>
	<MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">
		Den här sidan håller på att utvecklas för tillfället.
  </MDBContainer>	
	</>
);

export default WorkInProgress;
