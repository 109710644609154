import React, { useState, useRef, useEffect } from 'react';
import {
  Outlet,
  Link,
  useParams
} from "react-router-dom";
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBRipple,
  MDBContainer,
  MDBCollapse,
  MDBListGroup,
  MDBListGroupItem,
  MDBDropdownItem,
  MDBBadge,
  MDBDropdown,
  MDBDropdownMenu,
} from "mdb-react-ui-kit";
// import Sidenav from "../../modules/Sidenav_Event";
import { fetch_get, fetch_post } from "../../../services/common";

export const loader = async ({ params }) => {

  //const res = await fetch('https://dev.svsf-ta.se/api/app/event/all/53a6c57f-377e-11ee-9385-00505692ffcc');
  const resJson = params.id;

  return resJson;
};


export default function Sidenav_Event() {
  const { id } = useParams();
  const [basicOpen, setBasicOpen] = useState(false);
  const [showOpen, setShowOpen] = useState(false);
  const [mode, setMode] = useState("side");
  const [backdrop, setBackdrop] = useState(false);
  const sidenavContent = useRef(null);
  const [container, setContainer] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // --
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuToggle, setShowMenuToggle] = useState(false);
  const url = window.location.href;
  const urlIdx = url.lastIndexOf('/');
  const urlEnding = url.substring(urlIdx + 1);
  const [active, setActive] = useState(urlEnding)
  const toggleShow = () => setShowMenu(!showMenu);


  const checkResize = () => {
    if (window.innerWidth < 992) {
      setShowMenuToggle(true)
      setShowMenu(false)
    } else {
      setShowMenuToggle(false)
      setShowMenu(true)
    }
  };

  // ------

  useEffect(() => {
    fetch_get('app/menu/sidebar_event/' + id)
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setContainer(sidenavContent.current);
    if (window.innerWidth < 992) {
      setMode("over");
      setBasicOpen(false);
      setBackdrop(true);
    } else {
      setMode("side");
      setBasicOpen(true);
      setBackdrop(false);
    }

  }, []);

  const innerWidth = useRef(null);

  // const checkResize = () => {
  //   //if (window.innerWidth === innerWidth.current) {
  //   //  return;
  //   //}
  //   innerWidth.current = window.innerWidth;

  //   if (window.innerWidth < 992) {
  //     setMode("over");
  //     setBasicOpen(false);
  //     setBackdrop(true);
  //   } else {
  //     setMode("side");
  //     setBasicOpen(true);
  //     setBackdrop(false);
  //   }
  // };



  useEffect(() => {
    checkResize();

    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);


  const list = (data) => {
    return (
      data.map(item => {
        return (
          <>
            {item.type == 'sidebar_link' &&

              <MDBRipple rippleTag='span'>
                <MDBListGroupItem tag='a' action className='border-0 border-bottom rounded' active={active === item.id} >
                  <Link to={item.to} className="sidenav-link" state={1} onClick={showMenuToggle ? () => (setShowMenu(false), setActive(item.id)) : () => setActive(item.id)}>
                    <MDBIcon fas icon={item.icon} className={item.icon_classname} />{item.label}
                  </Link>
                </MDBListGroupItem>
              </MDBRipple>
            }
            {item.type == 'item_link' &&
              <MDBNavbarItem key={item.id}>
                <NavLink className={item.classname} as={Link} to={item.to}><MDBIcon fas icon={item.icon} className={item.icon_classname} />{item.label}</NavLink>
              </MDBNavbarItem>
            }

            {item.type == 'divider' &&
              <MDBDropdownItem divider />
            }
            {item.type == 'sidebar_text' &&
              <div className='mt-3'>
                <div id='header-content2' className='ps-3'>
                  <p><strong>{item.label}</strong></p>
                </div>
                <hr className='mb-0' />

              </div>
            }
            {item.type == 'dropdown' &&
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                    <MDBIcon fas icon={item.icon} className={item.icon_classname} />{item.label}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    {item.child && item.child.length &&
                      <>
                        {
                          item.child.map(ch =>
                            <>
                              {ch.type == 'divider' &&
                                <MDBDropdownItem divider />
                              }
                              {ch.type == 'link' &&
                                <NavLink className={ch.classname} as={Link} to={ch.to}>
                                  <MDBDropdownItem><MDBIcon fas icon={ch.icon} className={ch.icon_classname} />{ch.label}</MDBDropdownItem>
                                </NavLink>
                              }
                              {ch.type == 'link_loggout' &&
                                <MDBDropdownItem link onClick={handleLogout}><MDBIcon fas icon={ch.icon} className='pe-2' />{ch.label}</MDBDropdownItem>
                              }
                            </>
                          )
                        }
                      </>
                    }
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
            }
          </>
        )
      })
    )
  }

  return (

    <>
      {showMenuToggle ? <MDBContainer fluid>
        <MDBBtn size="lg" color='tertiary' onClick={() => toggleShow()}>
          <MDBIcon fas icon='bars' />
        </MDBBtn>
      </MDBContainer> : null}


      <MDBCollapse open={showMenu} tag="nav" className={showMenuToggle ? 'd-lg-block bg-white sidebar' : 'd-lg-block bg-white sidebar float-start'} >

        <div className="position-sticky">
          <MDBListGroup className="mx-1 mt-1">
            {list(data)}
          </MDBListGroup>
        </div>
      </MDBCollapse>

      <div className='sidenav-content'>
        <Outlet />
      </div>


    </>
  );
}



/*
    <>
      <header>
        <MDBSideNav
          open={basicOpen}
          backdrop={backdrop}
          absolute
          // slim={true}
          // small={true}
          getOpenState={(e) => setBasicOpen(e)}
          mode={mode}
          style={{ top: '100px' }}
          contentRef={container}

        >
          <MDBSideNavMenu>
            <div className='mt-4'>
              <div id='header-content' className='ps-3'>
                <p>Administration - Tävlingar</p>
              </div>
              <hr className='mb-0' />

            </div>

            {list(data)}
          </MDBSideNavMenu>
        </MDBSideNav>


        <MDBBtn
          onClick={() => {
            setBasicOpen(!basicOpen);
            setShowOpen(true);
          }}
          color='none'
          className="float-start fixed-top navbar-toggler p-2"
        >
          <MDBIcon fas icon='bars' />
        </MDBBtn>


      </header>
      <div ref={sidenavContent}>
        <Outlet />
      </div>
    </>

*/
