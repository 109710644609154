import React, { useState, useRef } from 'react';
import { MDBFilters } from 'mdb-react-filters';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup
} from 'mdb-react-ui-kit';
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  Form,
  redirect,
  //useNavigation,
  //useSubmit,
} from "react-router-dom";
import Icon_Svsf from "../../../svg/svg_icon_svsf";
//import Sidenav from "../modules/Sidenav_EventOverview";




export const loader = async ({ params }) => {
  const res = await fetch('https://dev.svsf-ta.se/api/app/event/all/53a6c57f-377e-11ee-9385-00505692ffcc');
  const resJson = await res.json();

  return resJson;
};


export default function EventsHome() {
  //const loadObject = useLoaderData();
  //const [data, setData] = useState(loadObject);  

  const [basicOpen, setBasicOpen] = useState(true);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [basicCollapse1, setBasicCollapse1] = useState(true);
  const [basicCollapse2, setBasicCollapse2] = useState(true);
  const contentRef = useRef(null);


  // *****************************
  

  return (
    <>
      <header>
        {/* <Sidenav basicOpen={basicOpen} setBasicOpen={setBasicOpen} /> */}
      </header>

<MDBContainer className="py-4">
    
      <h1>Inställningar tävlingar</h1>









  </MDBContainer>

    </>
  );
}
