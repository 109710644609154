import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { detectIncognito } from "detectincognitojs";
import {    
  MDBRow, 
  MDBCol, 
  MDBContainer,

 } from 'mdb-react-ui-kit';
 import jwt_decode from "jwt-decode";
 import { fetch_post } from "../../../services/common";

export async function loader({ params }) {
		const inc_result = await detectIncognito();
		const obj = {
			user_key_id: params.user_key_id,
			user_token: params.user_token,
			sysadmin_key_id: params.sysadmin_key_id,
			sysadmin_token: params.sysadmin_token,
			inc: inc_result
		}
		
		return obj;
  }

export default function SysAdminLogin() {

	const loadObject = useLoaderData();
	const [data, setData] = useState(loadObject);
	const [text, setText] = useState('Vänta - Loggar in som: ');
	///const key_id = loadObject.user_key_id;
	const d_user = jwt_decode(data.user_token);
	const d_sysadmin = jwt_decode(data.sysadmin_token);

	console.log('data',data);
	 useEffect(() => {

		if (!data.inc.isPrivate) {
			setText('Länken måste köras i ett privat webbläsar fönster, se instruktioner där länken är skapad......');
		} else if (1===1 && data.user_key_id===d_user.kid && data.sysadmin_key_id===d_sysadmin.kid && d_sysadmin.kid===d_user.sid && d_sysadmin.sid===d_user.kid) {
			fetch_post('auth/sysadmin-login', data)
            .then((data_ret) => {
				console.log('data_ret', data_ret);
                
            });			
			setText('Du är nu inloggad som : ' + d_user.sub);
			localStorage.setItem("key_id", d_user.kid);
			localStorage.setItem("token", data.user_token);	
			sessionStorage.setItem("key_id", d_sysadmin.kid);
			sessionStorage.setItem("token", data.sysadmin_token);				

			window.location.replace("/");		
		} else {
			setText('Felaktig länk......');
		}

		console.log('d_user',d_user);
		console.log('d_sysadmin',d_sysadmin);


	   }, []);	



  
  return (
    <>
			<MDBContainer className="py-2 h-100 mt-4">
				{text}
			</MDBContainer>
    </>
  );
}