import React, { useState } from 'react';
import {    
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBInput,
	MDBValidation,
	MDBValidationItem
 } from 'mdb-react-ui-kit';
 import { EditSportgren } from '../../sportgrenar/service/EditSportgren';
 import { fetch_post } from '../../../../services/common';

export default function EditSportgrenModal(params){

	const sport = params.sport;
	const depth = params.depth;
	const [sportEditedToast, setSportEditedToast] = useState(false)
	const [sportErrorToast, setSportErrorToast] = useState(false)
	const [sportEnabledToast,setSportEnabledToast] = useState(false)
	const [sportDisabledToast,setSportDisabledToast] = useState(false)
	const className = depth === 0 ? "Grenkommitté" : depth === 1 ? "Gren" : depth === 2 ? "Disciplin" : depth === 3 ? "Tävlingstyp" : null
	const [formValue, setFormValue] = useState({
    name: sport.name,
		default_fee: sport.default_fee ? sport.default_fee : ''
  });

	const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

	function handleSubmit(e){
    e.preventDefault();
    if(e.target.checkValidity()){
			EditSportgren(sport.value,formValue).then(obj => {
				if(obj.status === "OK"){
					setSportEditedToast(true)
				} else {
					setSportErrorToast(true)
				}
			});
			
    } 
  };

	async function disableSportgren(){
		const response = await fetch_post("app/sportgren/do_submit/" + sport.value, {action: "disable_sportgren"})
		if(response.status === "OK"){
			setSportDisabledToast(true)
		} else {
			setSportErrorToast(true)
		}
	}

	async function enableSportgren(){
		const response = await fetch_post("app/sportgren/do_submit/" + sport.value, {action: "enable_sportgren"})
		if(response.status === "OK"){
			setSportEnabledToast(true)
		} else {
			setSportErrorToast(true)
		}
	}

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportEditedToast}
						onClose={() => setSportEditedToast(false)}
					>
						{className} har ändrats.
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportEnabledToast}
						onClose={() => setSportEnabledToast(false)}
					>
						{className} har återaktiverats.
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportDisabledToast}
						onClose={() => setSportDisabledToast(false)}
					>
						{className} har inaktiverats.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportErrorToast}
						onClose={() => setSportErrorToast(false)}
					>
						Något gick fel.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBValidation onSubmit={handleSubmit}>
								<MDBModalContent>
									<MDBModalHeader>
										<MDBModalTitle>Ändra {className}</MDBModalTitle>
										<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
									</MDBModalHeader>
									<MDBModalBody>
										<MDBRow className='mb-3'>
											<MDBValidationItem className='col-md-4' feedback='Fyll i ett namn' invalid>
												<MDBInput
													value={formValue.name}
													name='name'
													onChange={onChange}
													required
													label={className + " namn"}
												/>
											</MDBValidationItem>
											{depth === 0 ? 
											<MDBValidationItem className='col-md-4'>
												<MDBInput
													value={formValue.default_fee}
													name='default_fee'
													onChange={onChange}
													required
													type='number'
													label='Standardavgift'
													min={0}
													max={999}
													step={1}
												>
												<div className='form-helper'>
													Startavgift vid tävling (kr)
												</div>
												</MDBInput>
											</MDBValidationItem> : null}
											
										</MDBRow>
									</MDBModalBody>
									<MDBModalFooter className='justify-content-between'>
								<div>
									{sport.status === 'ACTIVE' ? 
									<MDBBtn outline type='button' onClick={() => disableSportgren()} className='justify-content-start' color='danger'>
										Inaktivera
									</MDBBtn> : 
									<MDBBtn outline type='button' onClick={() => enableSportgren()} className='justify-content-start' color='success'>
										Återaktivera
									</MDBBtn>}
								</div>
								<div>
									<MDBBtn className='mx-2' color='secondary' onClick={params.close}>
										Stäng
									</MDBBtn>
									<MDBBtn color='primary' type='submit'>
										Spara ändringar
									</MDBBtn>
								</div>
							</MDBModalFooter>
						</MDBModalContent>
					</MDBValidation>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}