import React, { useState } from 'react';
import { fetch_json } from "../../../../services/common";
import { useLoaderData } from "react-router-dom";
import { MDBContainer,
	MDBBreadcrumb,
	MDBBreadcrumbItem, 
	MDBTabs,
	MDBTabsItem,
	MDBTabsLink,
	MDBTabsContent,
	MDBTabsPane} from "mdb-react-ui-kit";
import FacilitiesList from './FacilitiesList';

export async function loader({ params }) {
	const obj = {
		facilities: await fetch_json("app/forening/anlaggningar/" + params.id,'GET'),
		association: await fetch_json("data/foreningar/?_top=1&_select=id,name,distrikt_name&_filter=id=" + params.id + "&_orderby=name",'POST')
	};
	return  obj;
}

export default function FacilitiesHome() {

	const loadObject = useLoaderData();
  const facilities = loadObject.facilities.data;
	const association = loadObject.association.data[0];

	const [basicActive, setBasicActive] = useState('facilitiesTab');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>
    <MDBContainer className="py-4">
			<MDBBreadcrumb>
        <MDBBreadcrumbItem>
          <a href='/team'>Föreningsinställningar</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Anläggningar</MDBBreadcrumbItem>
      </MDBBreadcrumb>
			<h4>{association.name}</h4>
      <MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('facilitiesTab')} active={basicActive === 'facilitiesTab'}>
						Anläggningar
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
      <MDBTabsContent>
        <MDBTabsPane open={basicActive === 'facilitiesTab'}><FacilitiesList facilities={facilities}/></MDBTabsPane>
      </MDBTabsContent> 
    </MDBContainer>
    </>
  );
}