import { useLoaderData } from "react-router-dom";
import { fetch_json } from "../../../../services/common";
import {
  MDBContainer,
  MDBRow,
	MDBBreadcrumb,
	MDBBreadcrumbItem,
	MDBCol
} from "mdb-react-ui-kit";

export async function loader({ params }) {
	const obj = {
		facilities: await fetch_json("app/anlaggning/all/" + params.facility_id,'GET'),
		association: await fetch_json("app/forening/all/" + params.association_id,'GET')
	};
	return  obj;
}

export default function FacilityDetails(){

	const loadObject = useLoaderData();
	const association = loadObject.association.data;
  const facility = loadObject.facilities.data;

	return (
		<>
			<MDBContainer className="py-4">
				<MDBBreadcrumb>
					<MDBBreadcrumbItem>
						<a href='/team'>Föreningsinställningar</a>
					</MDBBreadcrumbItem>
					<MDBBreadcrumbItem>
					<a href={"/team/facilities/" + association.id}>Anläggningar</a>
					</MDBBreadcrumbItem>
					<MDBBreadcrumbItem active>{facility.name}</MDBBreadcrumbItem>
				</MDBBreadcrumb>
				<MDBRow>
					<h1>{facility.name}</h1>
				</MDBRow>
				<MDBRow>
					<MDBCol md='6'>{facility.description}</MDBCol>
					
				</MDBRow>
			</MDBContainer>
	</>
	)
};
