import { useNavigate, useLoaderData } from "react-router-dom";
import React,{ useState, useMemo, useEffect } from 'react';
import {    
  MDBBtn,
	MDBSwitch,
	MDBCol,
	MDBRow,
	MDBContainer
 } from 'mdb-react-ui-kit';
 import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { fetch_json, fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";
import WorkInProgress from "../WorkInProgress";

export async function loader({ params }) {
	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	const obj = {
		user: await fetch_get("app/user/all/" + params.key_id),
		guest: decoded.kid == params.key_id ? false : true
	}
		return obj;
}

export default function UserRanking(){

  
    return (
		<>
			<MDBContainer className="py-4">
				<h3>Ranking</h3>
				<hr/>
				<WorkInProgress></WorkInProgress>
			</MDBContainer>
		</>
          );

  
}