import React, { useState } from 'react';
import {    
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBInput,
	MDBValidation,
	MDBValidationItem,
	MDBSelect,
	MDBCheckbox
 } from 'mdb-react-ui-kit';
import { EditSportclass } from '../../sportgrenar/service/EditSportclass';
import { fetch_post } from '../../../../services/common';

export default function EditSportclassModal(params){

	const sportClass = params.sportClass;
	const classTypes = params.classTypes;
	const [sportclassAddedToast, setSportclassAddedToast] = useState(false)
	const [sportclassErrorToast, setSportclassErrorToast] = useState(false)
	const [sportclassDisabledToast, setSportclassDisabledToast] = useState(false)
	const [sportclassEnabledToast, setSportclassEnabledToast] = useState(false)
	const [showResultInput, setShowResultInput] = useState(false)
	const [formValue, setFormValue] = useState({
		def_klass_typer_id: sportClass.def_klass_typer_id ? sportClass.def_klass_typer_id : '',
    name: sportClass.name ? sportClass.name : '',
		sort_order: sportClass.sort_order ? sportClass.sort_order : '',
		age_min: sportClass.age_min ? sportClass.age_min : '',
		age_max: sportClass.age_max ? sportClass.age_max : '',
		gender: sportClass.gender ? sportClass.gender : '',
		results_min: sportClass.results_min ? sportClass.results_min : '',
		results_max: sportClass.results_max ? sportClass.results_max : '',
		results_to_count: sportClass.results_to_count ? sportClass.results_to_count : '',
		include_fee: sportClass.include_fee ? sportClass.include_fee : '1'
  });
	const [checked, setChecked] = useState(true);

	const onCheckedChange = (e) => {
		setChecked(!checked)
		setFormValue({ ...formValue, ["include_fee"]: e.target.checked ? 1 : 0 });
	}

	const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

	const selectChange = (e) => {
    setFormValue({ ...formValue, ["gender"]: e.value });
  };

	const classTypeChange = (e) => {
		if(e.value === "20" || e.value === "30"){
			setShowResultInput(true)
		} else{
			setShowResultInput(false)
		}
    setFormValue({ ...formValue, ["def_klass_typer_id"]: e.value });
  };

	function handleSubmit(e){
    e.preventDefault();
    if(e.target.checkValidity()){
			if(!showResultInput){
				const ageFormValue = {
					def_klass_typer_id: formValue.def_klass_typer_id,
					sort_order: formValue.sort_order,
					name: formValue.name,
					age_min: formValue.age_min,
					age_max: formValue.age_max,
					gender: formValue.gender,
					results_min: null,
					results_max: null,
					results_to_count: null,
					include_fee: formValue.include_fee
				}
				EditSportclass(sportClass.value,ageFormValue).then(obj => {
					if(obj.status === "OK"){
						setSportclassAddedToast(true)
					} else {
						setSportclassErrorToast(true)
					}
				});
			} else {
				EditSportclass(sportClass.value,formValue).then(obj => {
					if(obj.status === "OK"){
						setSportclassAddedToast(true)
					} else {
						setSportclassErrorToast(true)
					}
				});
			}
    } 
  };

	async function disableSportclass(){
		const response = await fetch_post("app/sportclass/do_submit/" + sportClass.value, {action: "disable_sportclass"})
		if(response.status === "OK"){
			setSportclassDisabledToast(true)
		} else {
			setSportclassErrorToast(true)
		}
	}

	async function enableSportlass(){
		const response = await fetch_post("app/sportclass/do_submit/" + sportClass.value, {action: "enable_sportclass"})
		if(response.status === "OK"){
			setSportclassEnabledToast(true)
		} else {
			setSportclassEnabledToast(true)
		}
	}

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportclassAddedToast}
						onClose={() => setSportclassAddedToast(false)}
					>
						Klass har ändrats för {sportClass.name}.
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportclassDisabledToast}
						onClose={() => setSportclassDisabledToast(false)}
					>
						Klass {sportClass.name} har inaktiverats.
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportclassEnabledToast}
						onClose={() => setSportclassEnabledToast(false)}
					>
						Klass {sportClass.name} har återaktiverats.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportclassErrorToast}
						onClose={() => setSportclassErrorToast(false)}
					>
						Klass kunde inte ändras.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBValidation onSubmit={handleSubmit}>
								<MDBModalContent>
									<MDBModalHeader>
										<MDBModalTitle>Ändra klass</MDBModalTitle>
										<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
									</MDBModalHeader>
									<MDBModalBody>
										<MDBRow className='mb-5'>
											<MDBValidationItem className='col-md-4' feedback='Fyll i ett namn' invalid>
												<MDBInput
													value={formValue.name}
													name='name'
													onChange={onChange}
													required
													label='Klassnamn'
												>
													<div className='form-helper'>
														Namn på klass
													</div>
												</MDBInput>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-4' feedback='Fyll i ett namn' invalid>
												<MDBSelect
														value={formValue.def_klass_typer_id}
														label='Typ av klass'
														data={classTypes}
														name='def_klass_typer_id'
														onChange={classTypeChange}			
														required											
													>
												</MDBSelect>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.sort_order}
													name='sort_order'
													onChange={onChange}
													required
													type='number'
													label='Ordning'
													min={0}
													max={100}
													step={1}
												>
												<div className='form-helper'>
													Välj mellan 1-100
												</div>
												</MDBInput>
											</MDBValidationItem>
										</MDBRow>
										<MDBRow className='mb-5'>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.age_min}
													name='age_min'
													onChange={onChange}
													required
													type='number'
													label='Min ålder'
													min={0}
													max={100}
													step={1}
												>
												<div className='form-helper'>
													Välj mellan 1-100
												</div>
												</MDBInput>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.age_max}
													name='age_max'
													onChange={onChange}
													required
													type='number'
													label='Max ålder'
													min={0}
													max={100}
													step={1}
												>
												<div className='form-helper'>
													Välj mellan 1-100
												</div>
												</MDBInput>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-3'>
												<MDBSelect
														value={formValue.gender}
														label='Kön'
														data={[
															{ text: 'Kvinna/Man', value: "MK" },
															{ text: 'Kvinna', value: "K" },
															{ text: 'Man', value: "M" },
														]}
														name='gender'
														onChange={selectChange}			
														required											
													>
												</MDBSelect>
											</MDBValidationItem>
										</MDBRow>
										<MDBRow className={showResultInput ? 'mb-2' : 'd-none'}>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.results_min}
													name='results_min'
													onChange={onChange}
													required={showResultInput ? true : false}
													type='number'
													label='Min resultat'
													min={0}
													max={9999}
													step={1}
												>
												<div className='form-helper'>
													Lägsta resultat
												</div>
												</MDBInput>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.results_max}
													name='results_max'
													onChange={onChange}
													required={showResultInput ? true : false}
													type='number'
													label='Max resultat'
													min={0}
													max={9999}
													step={1}
												>
												<div className='form-helper'>
													Högsta resultat
												</div>
												</MDBInput>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.results_to_count}
													name='results_to_count'
													onChange={onChange}
													required={showResultInput ? true : false}
													type='number'
													label='Antal resultat'
													min={1}
													max={100}
													step={1}
												>
												<div className='form-helper'>
													Antal resultat som räknas
												</div>
												</MDBInput>
											</MDBValidationItem>
										</MDBRow>
										<MDBRow>
											<MDBValidationItem>
											<MDBCheckbox
												name='include_fee'
												label='Avgift'
												checked={checked}
												onChange={onCheckedChange}
											/>
											</MDBValidationItem>
										</MDBRow>
									</MDBModalBody>
							<MDBModalFooter className='justify-content-between'>
								<div>
									{sportClass.status === 'ACTIVE' ? 
									<MDBBtn outline type='button' onClick={() => disableSportclass()} className='justify-content-start' color='danger'>
										Inaktivera
									</MDBBtn> : 
									<MDBBtn outline type='button' onClick={() => enableSportlass()} className='justify-content-start' color='success'>
										Återaktivera
									</MDBBtn>}
								</div>
								<div>
									<MDBBtn className='mx-2' color='secondary' onClick={params.close}>
										Stäng
									</MDBBtn>
									<MDBBtn color='primary' type='submit'>
										Spara ändringar
									</MDBBtn>
								</div>
							</MDBModalFooter>
						</MDBModalContent>
					</MDBValidation>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}