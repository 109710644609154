import React,{ useRef, useState, useMemo, useEffect } from 'react';
import {
  MDBContainer,
	MDBBtn,
	MDBInput
} from 'mdb-react-ui-kit';
import * as xlsx from 'xlsx';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

const tableData = [
	{
		name: 'Test Tesatsson1',
		s1: 22,
		tot: 0
	}
]

export default function ResultInputTable() {

	const [compeditorsData, setCompeditorsData] = useState([
		{	id: 1,
			name: 'Test Tesatsson1',
			s1: 22,
			s2: 55,
			tot: 0
		},
		{	id: 2,
			name: 'asd asdssson',
			s1: 55,
			s2: 111,
			tot: 0
		}])

	const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Namn',
        size: 150,
				enableEditing: false,
      },
      {
        accessorKey: 's1', //normal accessorKey
        header: 'Omgång1',
        size: 200,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'number',
					onBlur: (event) => {
						setCompeditorsData(compeditorsData.map(compeditor => {
							let sum = Number(event.currentTarget.value);
							if(compeditor.id === row.original.id){
								Object.keys(row.original).forEach(key => {
									if(key.startsWith('s') && key != column.id){
										sum = sum + Number(row.original[key])
									}
								});
								return {...compeditor, tot: sum, [column.id]: event.currentTarget.value}
							} else {
								return compeditor
							}
						}))
					}
				}),
      },
			{
        accessorKey: 's2', //normal accessorKey
        header: 'Omgång2',
        size: 200,
				muiEditTextFieldProps: ({ row, column }) => ({
					required: true,
					type: 'number',
					onBlur: (event) => {
						setCompeditorsData(compeditorsData.map(compeditor => {
							let sum = Number(event.currentTarget.value);
							if(compeditor.id === row.original.id){
								Object.keys(row.original).forEach(key => {
									if(key.startsWith('s') && key != column.id){
										sum = sum + Number(row.original[key])
									}
								});
								return {...compeditor, tot: sum, [column.id]: event.currentTarget.value}
							} else {
								return compeditor
							}
						}))
					}
				}),
      },
			{
        accessorKey: 'tot', //normal accessorKey
        header: 'Total',
        size: 200,
				enableEditing: false,
      },
    ],
    [compeditorsData],
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: compeditorsData,
		enableEditing: true,
		editDisplayMode: 'cell',
		localization:MRT_Localization_SV,/* 
		muiTableBodyCellProps: ({ cell, column, table, row }) => ({
			onClick: () => {
				table.setEditingCell(cell);
				console.log(row.getAllCells())
				queueMicrotask(() => {
					const textField = table.refs.editInputRefs.current[column.id];
					if (textField) {
						textField.focus();
						textField.select?.();
					}
				});
			},
		}) */
  });

  return (
    <>

      <MDBContainer className="py-4 bg-light bg-gradient rounded-5">   
				<MaterialReactTable 
					table={table}
					/>
      </MDBContainer>

    </>
  );
}
