import { useLoaderData } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import {
  Outlet,
  Link
} from "react-router-dom";
import { fetch_get,fetch_json } from "../../../services/common";
import {    
  MDBContainer,
  MDBIcon,
  MDBCollapse,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem,
	MDBBtn
 } from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";

export async function loader({ params }) {

  const data = localStorage.getItem("token");
  var decoded = "";
  if (data) {
    decoded = jwt_decode(data);
  }
	const user = await fetch_get("app/user/all/" + decoded.kid)
	const district = user.data.roles.find(({id}) => id === "400")

	const obj = {
		user: user,
		district: await fetch_get("app/distrikt/all/" + district.distrikt_id),
		associations: await fetch_json("data/distrikt_foreningar/?_filter=id=" + district.distrikt_id),
	}
	return obj;
}

export default function AssociationRoot() {

  const loadObject = useLoaderData();
	const user = loadObject.user.data;
	const district = loadObject.district.data;
	if(!user.roles.find(({ id, distrikt_id }) => id === "400" && distrikt_id === district.id)){
		return <NotFound404></NotFound404>
	}
	const [showMenu, setShowMenu] = useState(false);
	const [showMenuToggle, setShowMenuToggle] = useState(false);
  const toggleShow = () => setShowMenu(!showMenu);
	const url = window.location.href;
	const urlIdx = url.lastIndexOf('/');
	const urlEnding = url.substring(urlIdx + 1);
	const [active,setActive] = useState(urlEnding)

	const checkResize = () => {
		if(window.innerWidth < 992){
			setShowMenuToggle(true)
			setShowMenu(false)
		} else {
			setShowMenuToggle(false)
			setShowMenu(true)
		}
  };

	useEffect(() => {
    checkResize();
    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
		
  }, []);

  return (
		<>
			{showMenuToggle ? <MDBContainer fluid>
						<MDBBtn size="lg" color='tertiary' onClick={() => toggleShow()}>
							<MDBIcon fas icon='bars' />
						</MDBBtn>
			</MDBContainer> : null}
			
		
	<MDBCollapse open={showMenu} tag="nav" className={showMenuToggle ? 'd-lg-block bg-white sidebar' : 'd-lg-block bg-white sidebar float-start'} style={{minWidth: '220px'}} >
		<div className="position-sticky">
		<h5 className='bg-primary border-3 text-white border-white px-4 p-2 mb-1 border'>Distrikt</h5>
			<MDBListGroup className="mx-1 mt-0">
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "associations"} >
						<Link to={"associations"} className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("associations")) : () => setActive("associations")}>
							<MDBIcon fas icon="users" className="fa-fw me-3" />
							Föreningar
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "roles"} >
						<Link to="roles" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("roles")) : () => setActive("roles")}>
							<MDBIcon fas icon="id-card" className="fa-fw me-3" />
							Roller
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
			</MDBListGroup>
		</div>
	</MDBCollapse>	
	<Outlet />
</>
);
}